<template>
    <nav class="flex" aria-label="Breadcrumb">
        <ol class="inline-flex items-center ">
            <li v-for="crumb,crumbIndex in routesArray" :key="crumbIndex">
                <div class="flex items-center">
                    <div>
                        <a :class="crumbIndex == (routesArray.length-1)?'disabled pointer-events-none':''" href="#" @click="navigate(crumb)" class="crumbSize font-medium text-gray-700 hover:text-blue-600">{{crumb.name}}</a>
                    </div>
                    <div v-if="crumbIndex !== (routesArray.length-1)">
                        <img src="@/assets/rightArrowFilled.svg" class="w-2.5 h-2.5 mx-1.5 mt-1 text-gray-400" />
                    </div>
                </div>
            </li>
            <div v-if="navIndexNums">
                <img src="@/assets/rightArrowFilled.svg" class="w-2.5 h-2.5 mx-1.5 mt-1 text-gray-400" />
            </div>
            <div v-if="navIndexNums">
                <a class='crumbSize font-medium disabled pointer-events-none' href="#">{{navIndexNums.name}}</a>
            </div>
        </ol>
    </nav>   
</template>
<style scoped>
.crumbSize {
    font-size: 12px;
    line-height: 16px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter } from '@/main';

export default defineComponent({
    props: ['routesArray','navIndexNums'],
    data(): any {
        return {
            navIndexes:{},
        };
    },
    
    methods:{
        navigate(crumb:any){
             console.log("crumbObj",Object.keys(crumb).includes('id'))
            if(Object.keys(crumb).includes('id')){
                console.log("crumb.id",crumb.id)
                this.$store.dispatch('selectedRoute', crumb.name);
                if(crumb.name == 'System-Information'){
                    this.$router.push({ name: crumb.routeName, params: { id: crumb.id } });
                }else if(crumb.name == 'Process-Information'){
                    this.$router.push({ name: 'processInformation', params: { processId: crumb.id } });
                }else if(crumb.name == 'Organization-Information'){
                    this.$router.push({ name: 'organizationInformation', params: { orgId: crumb.id } });
                }else if(crumb.name == 'Tier-Risks'){
                    let typeName:any;
                    if(Object.keys(this.$route.params).includes('systemId') || this.$route.params.type == 'system' || this.$route.params.type == 'systems'){
                        typeName = 'systemRisks'
                    }else if(Object.keys(this.$route.params).includes('processId') || this.$route.params.type == 'process'){
                        typeName = 'processRisks'
                    }else if(Object.keys(this.$route.params).includes('orgId') || this.$route.params.type == 'org' || this.$route.params.type == 'organization'){
                        typeName = 'orgRisks'
                    }
                    this.$router.push({ name: 'tierRisks', params: { riskId: crumb.id , type:typeName } });
                }else{
                    this.$router.push({ name: crumb.routeName, params: { riskId: crumb.id } });
                }
            }else{
                this.$store.dispatch('selectedRoute', crumb.name);
                this.$router.push({ name: crumb.routeName });
            }
        }
    }
})
</script>
