<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="row w-full justify-between flex">
            <div class="title flex items-center">
                <h1>Tasks</h1>
            </div>
            <div class="flex items-center">
                <button class="ml-3 flex" @click="filterOpen" :class="tableRow.length == 0?'btndisabled pointer-events-none':'filterButton'">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-3 text-xs">
            <div>
                This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
                to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
            </div>
        </div>
        
            <newTable :tableRowData="tableRow" :headersData="headers1" :openPopup="false" :navigate="true"></newTable>

    </div>

</template>
<style scoped>
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { emitter, toast } from '@/main';
import newTable from '@/components/newTable.vue'

export default defineComponent({
    data(): any {
        return {
            filterDummy:{
                statusCode:''
            },
            v$: useVuelidate(),
            showFilters: false,
            dummyList: [],
            systems: [],
            tableRow:[],
            headers1:['entityId','taskId','controlName','tier','target','createdAt','taskStatus'],        
            myTasks:[],
            businessProcessList:[],
            businessEntitiesList:[],

        };
    },
    components:{
      newTable
    },   
    computed: {
        ...mapGetters({ userInfo: 'userInfo' }),
    },
    async mounted() {
        //this.$store.dispatch('updateDotsLoading', true);
        await this.getSystems();
        await this.getBusinessProcess();
        await this.getBusinessEntities();
        await this.getAllTasks();
    },
    methods: {
        async getSystems() {
			let entityId='20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = result.data;
				this.dummySystems = result.data
            });
		},
        async getBusinessProcess(){
            this.businessProcessList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                this.businessProcessList = result.data;
            })
        },
        async getBusinessEntities(){
            this.businessEntitiesList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                this.businessEntitiesList = result.data;
            })
        },
        formatTaskId(taskId:any) {
            if(taskId == undefined){
                return ''
            }else{
                return taskId.toString().padStart(4, '0');
            }
        },
       async getAllTasks(){
             await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tasks/getall`).then((result: any) => {
                this.myTasks = result.data;
                this.myTasks.map((task:any)=>{
                    if(!Object.keys(task).includes('createdAt')){
                        task.createdAt = ''
                    }
                    task.taskId = this.formatTaskId(task.taskId)
                })
                let mitigateTasks = this.myTasks.filter((task:any)=>{return task.action == 10405 && (!Object.keys(task).includes('mappedTaskId')) }); 

                if(this.userInfo.currentRole == 10207 ){
                    this.myTasks = mitigateTasks;
                }else {
                    let filterArrayOfTasks = mitigateTasks.filter((task:any)=>{
                        return (task.createdBy == this.userInfo._id || task.riskOwner == this.userInfo._id);
                    })
                    this.myTasks = filterArrayOfTasks;
                }

                this.myTasks.map((taskObj:any)=>{

                    let targetName;
                    if(Object.keys(taskObj).includes('systemId') && taskObj.tier == 3){
                        let findObj = this.systems.find((obj:any)=>{return obj._id == taskObj.systemId;})
                        if(findObj!=undefined){
                            targetName = findObj.systemName;
                        }

                    }else if(Object.keys(taskObj).includes('processId') && taskObj.tier == 2){
                        let findObj = this.businessProcessList.find((obj:any)=>{return obj._id == taskObj.processId;})
                        if(findObj!=undefined){
                            targetName = findObj.businessProcessName;
                        }

                    }else if(Object.keys(taskObj).includes('orgId') && taskObj.tier == 1){
                        let findObj = this.businessEntitiesList.find((obj:any)=>{return obj._id == taskObj.orgId;})
                         if(findObj!=undefined){
                            targetName = findObj.entityName;
                        }

                    }

                    let row = { entityId:20012 , _id:taskObj._id, taskId:taskObj.taskId, controlName:taskObj.controlNumber, tier:taskObj.tier, target:targetName, createdAt:taskObj.createdAt, taskStatus:taskObj.statusCode };
                    this.tableRow.push(row);
                })
            // this.$store.dispatch('updateDotsLoading', false);
            });
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            this.filterDummy={};
            if (this.showFilters == true) {
                this.dummyList = this.myTasks;
            } else {
                this.myTasks = this.dummyList;
            }
            emitter.emit('visible',this.showFilters)
        }
    }
});
       
</script>
