<template >
    <div>
        <div class="flexcontainer pt-3 pl-5 pr-5">
            <div class="row w-full justify-between flex">
                <div class="title flex items-center">
                    <h1>Risk Master</h1>
                </div>
                <div class="flex items-center">
                    <button class="btnprimary ml-3" @click="importDoc()">Import</button>
                    <input type="file" class="hidden" accept=".xls,.xlsx" ref="file" @change="fileReader($event)" />
                    <button class="btnprimary ml-3 flex items-center" @click="showPopupByAdd">Add</button>
                    <button class="ml-3 flex" @click="filterOpen" :class="displayListInTable.length == 0?'btndisabled pointer-events-none':'filterButton'">
                        Filters
                        <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                    </button>
                </div>
            </div>
            <div class="mt-3 text-sm">
                Risk Master has been developed to help risk managers, claim adjusters, attorneys and safety managers to process, manage and analyze critical claims data. This data includes: general liability, workers' compensation, automotive liability, first reports of injury, reporting, policies, actuarial reporting and integrated disability management.This course helps you to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
            </div>
            <div class="row">
                <newTable :tableRowData="displayListInTable" :headersData="headers" :openPopup="true"></newTable>
            </div>

            <popup v-if="showRiskMasterPopup" :popUp="showRiskMasterPopup">
                <template v-slot:header>
                    <div class="flex items-center justify-between popupheadingcontainer rounded">
                        <h1>Risk Master</h1>

                        <button class="float-right text-3xl" @click="onCrossbtn()">
                            <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                        </button>
                    </div>
                </template>

                <div class="popup-business">
                    <div>
                        <div>
                            <div>
                                <div class="statuscontainer popupbodycontainer">
                                    <div class="formcontainer flex flex-col">
                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="w-full">
                                                <label for="Risk" class="controllabel">Risk</label> 

                                                <textarea
                                                    class="inputboxstyling"
                                                    placeholder="Risk"
                                                    v-model="riskThreatData.risk"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.risk.$error
                                                    }"
                                                />

                                                <div v-if="v$.riskThreatData.risk.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.riskThreatData.risk.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="w-full">
                                                <label for="Risk" class="controllabel">Threat</label> 

                                                <textarea
                                                    class="inputboxstyling"
                                                    placeholder="Threat"
                                                    v-model="riskThreatData.threatEvent"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.threatEvent.$error
                                                    }"
                                                />

                                                <div v-if="v$.riskThreatData.threatEvent.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.riskThreatData.threatEvent.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="w-full">
                                                <div>
                                                    <label class="controllabel">Risk Category</label>

                                                    <dropdown :dataprops="selectedCategory" :inputValue="riskThreatData.riskCategory" :placeholderValue="'Risk Category'" @name="getName" :error="v$.riskThreatData.riskCategory.$error"></dropdown>

                                                    <div v-if="v$.riskThreatData.riskCategory.$error" class="text-red-500">
                                                        <div class="error-text" v-if="v$.riskThreatData.riskCategory.required.$invalid">Required</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="w-3/6">
                                                <div>
                                                    <label class="controllabel">Threat Category</label>
                                                    <dropdown :dataprops="selectedCategory" :inputValue="riskThreatData.threatCategory" :placeholderValue="'Threat Category'" @name="getName" ></dropdown>
                                                </div>
                                            </div>
                                            <div class="w-3/6 leftspacing">
                                                <div>
                                                    <label class="controllabel">Threat Source</label>
                                                    <dropdown :dataprops="selectedCategory" :inputValue="riskThreatData.threatSource" :placeholderValue="'Threat Source'" @name="getName"></dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="w-full">
                                                <label for="Risk" class="controllabel">Vulnerability</label> 

                                                <textarea
                                                    class="inputboxstyling"
                                                    placeholder="Vulnerability"
                                                    v-model="riskThreatData.vulnerability"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.vulnerability.$error
                                                    }"
                                                />

                                                <div v-if="v$.riskThreatData.vulnerability.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.riskThreatData.vulnerability.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <h1 class="upperspacing">Impact Analysis</h1>
                                        <div class="border-b pt-1 border-solid border-lightgrey"></div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="col-md-6 w-6/12">
                                                <label class="controllabel">Business</label> 

                                                <!-- <input type="text" class="inputboxstyling" placeholder="Business" v-model="riskThreatData.Business" /> -->

                                                <select
                                                    class="inputboxstyling"
                                                    v-model="riskThreatData.business"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.business.$error
                                                    }"
                                                >
                                                    <option value='' selected disabled hidden>Please select one</option>

                                                    <option v-for="option in dbBussiness" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.business.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.riskThreatData.business.required.$invalid">Required</div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 w-6/12 leftspacing">
                                                <label class="controllabel">Organization</label> 

                                                <!-- <input type="text" class="inputboxstyling" placeholder="Organization" v-model="riskThreatData.Organization" /> -->

                                                <select
                                                    class="inputboxstyling"
                                                    v-model="riskThreatData.organization"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.organization.$error
                                                    }"
                                                >
                                                    <option value="" selected disabled hidden>Please select one</option>

                                                    <option v-for="option in dbOperations" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.organization.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.riskThreatData.organization.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            <div class="col-md-6 w-6/12">
                                                <label class="controllabel">Operations</label> 

                                                <!-- <input type="text" class="inputboxstyling" placeholder="Operations" v-model="riskThreatData.Operations" /> -->

                                                <select
                                                    class="inputboxstyling"
                                                    v-model="riskThreatData.operations"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.operations.$error
                                                    }"
                                                >
                                                    <option value="" selected disabled hidden>Please select one</option>

                                                    <option v-for="option in dbOperations" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.operations.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.riskThreatData.operations.required.$invalid">Required</div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 w-6/12 leftspacing">
                                                <label class="controllabel">Legal</label> 

                                                <select
                                                    class="inputboxstyling"
                                                    v-model="riskThreatData.legal"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.legal.$error
                                                    }"
                                                >
                                                    <option value="" selected disabled hidden>Please select one</option>

                                                    <option v-for="option in dbLegal" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.legal.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.riskThreatData.legal.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row flex mb-4 flex-row w-full upperspacing">
                                            <div class="col-md-6 w-6/12">
                                                <label class="controllabel">Financial</label> 

                                                <select
                                                    class="inputboxstyling"
                                                    v-model="riskThreatData.financial"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.financial.$error
                                                    }"
                                                >
                                                    <option value="" selected disabled hidden>Please select one</option>

                                                    <option v-for="option in dbFinancial" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.financial.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.riskThreatData.financial.required.$invalid">Required</div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 w-6/12 leftspacing">
                                                <label class="controllabel">Technical</label> 

                                                <select
                                                    class="inputboxstyling"
                                                    v-model="riskThreatData.technical"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.technical.$error
                                                    }"
                                                >
                                                    <option value="" selected disabled hidden>Please select one</option>

                                                    <option v-for="option in dbTechnical" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.technical.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.riskThreatData.technical.required.$invalid">Required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <h1 class="upperspacing">Risk Analysis</h1>
                                        <div class="border-b pt-1 border-solid border-lightgrey"></div>

                                        <div class="row flex flex-row w-full upperspacing">
                                            
                                                <div class="w-6/12">
                                                <label class="controllabel">Likelihood</label> 

                                                <select
                                                    class="inputboxstyling"
                                                    v-model="riskThreatData.likelihood"
                                                    :class="{
                                                        'is-invalid': v$.riskThreatData.likelihood.$error
                                                    }"
                                                >
                                                    <option value="" selected disabled hidden>Please select one</option>

                                                    <option v-for="option in dbLikelihood" :key="option" :value="option.value">
                                                        {{ option.key }}
                                                    </option>
                                                </select>

                                                <div v-if="v$.riskThreatData.likelihood.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.riskThreatData.likelihood.required.$invalid">Required</div>
                                                </div>
                                                </div>

                                                <div class="w-6/12 leftspacing">
                                                <label class="controllabel">Overall Impact</label>
                                                <input class="disabledinputboxstyling" type="text" disabled placeholder="Overall Impact">
                                                </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                            <button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn()">Cancel</button>
                            <button v-if="riskThreatData._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdatethreat(riskThreatData)">Update</button>
                            <button v-else :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdatethreat(riskThreatData)">Save</button>
                        </div>
                    </div>
                </div>
            </popup>
        </div>
    </div>
</template>

<style scoped>
.popup-business {
    overflow-y: scroll;
}
::-webkit-scrollbar {
    width: 0px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import store from '../../store';
import { emitter, toast } from '@/main';
import popup from '@/components/popup.vue';
import dropdown from '@/components/dropdown.vue';
import newTable from '@/components/newTable.vue'

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            mandatoryKeys: ['risk', 'threatCategory', 'threatSource', 'threatEvent', 'vulnerability', 'organization', 'business', 'operations', 'technical', 'legal', 'financial', 'likelihood', 'riskCategory'],
            fullKeys: ['risk', 'threatCategory', 'threatSource', 'threatEvent', 'vulnerability', 'organization', 'business', 'operations', 'technical', 'legal', 'financial', 'likelihood', 'riskCategory','riskOwner'],
            editpop: false,
            showFilters: false,
            dummyList: [],
            riskList: [],
            optionsUserArray: [],
            orgUsersList:[],
            displayListInTable: [],
            scalesInfo: [],
            likelihoodMapping: [],
            severityMapping: [],
            impactMapping: [],
            showRiskMasterPopup: false,
            byAdd: false,
            byRow: false,
            selectedCategory: [],
            autoSuggestion: false,
            riskCopy: null,
            optionsList:[],
            headers:[ "entityId","_id","severity","risk","category","impact","likelihood"],
            riskThreatData: {
                risk: '',
                threatCategory: '',
                threatSource: '',
                threatEvent: '',
                vulnerability: '',
                organization: '',
                business: '',
                operations: '',
                technical: '',
                legal: '',
                financial: '',
                likelihood: '',
                riskCategory: '',
                riskOwner:'',
            },
            notImportedRiskList:[],
        };
    },
    validations() {
        return {
            riskThreatData: {
                risk: { required },
                riskCategory: { required },
                threatEvent: { required },
                vulnerability: { required },
                organization: { required },
                business: { required },
                operations: { required },
                technical: { required },
                legal: { required },
                financial: { required },
                likelihood: { required },
            }
        };
    },
    components: {
        popup,
        dropdown,
        newTable
    },
    computed: {
        ...mapGetters({dbSeverity:'dbSeverity',dbLikelihood:'dbLikelihood',dbBussiness:'dbBussiness',
            dbOrganization:'dbOrganization',dbOperations:'dbOperations',dbLegal:'dbLegal',
            dbFinancial:'dbFinancial',dbTechnical:'dbTechnical'
        }),
        
        disableStatus: function (): any {
            let result;
            if (this.editpop) {
                let objCopy = Object.assign({}, this.riskThreatData);

                if (_.isEqual(objCopy, this.riskCopy)) {
                    result = false;
                } else {
                    result = true;
                }
            } else {
                let valueArray: any = [];
                Object.values(this.riskThreatData).forEach((value: any) => {
                    valueArray.push(value);
                });
                for (let value of valueArray) {
                    if (value != '') {
                        result = true;
                    }
                }
            }
            return result;
        },
        e: function (): any {
            return false;
        }
    },

    async mounted() {
        this.scalesInfo = store.getters.scalesInfo;

        if (this.scalesInfo.length == 0) {
            await store.dispatch('getScalesInfo').then(async (res: any) => {
                this.scalesInfo = store.getters.scalesInfo;
                this.likelihoodMapping = this.scalesInfo.filter((scale: any) => {
                    return scale.entity == 'Likelihood';
                });
                this.severityMapping = this.scalesInfo.filter((scale: any) => {
                    return scale.entity == 'Likelihood';
                });
                this.impactMapping = this.scalesInfo.filter((scale: any) => {
                    return scale.entity == 'Impact';
                });
                await this.getRisksList();
            });
        } else {
            this.scalesInfo = store.getters.scalesInfo;
            this.likelihoodMapping = this.scalesInfo.filter((scale: any) => {
                return scale.entity == 'Likelihood';
            });
            this.severityMapping = this.scalesInfo.filter((scale: any) => {
                return scale.entity == 'Likelihood';
            });
            this.impactMapping = this.scalesInfo.filter((scale: any) => {
                return scale.entity == 'Impact';
            });
            await this.getRisksList();
        }
        emitter.off('showData123');
        emitter.on('showData123', (riskData: any) => {
            let comingId = riskData.entityData._id;
            let showObj = this.riskList.find((obj:any)=>{
                return (obj._id === comingId)
            });
            this.showEditPopup(showObj);
        });
    },

    methods: {
        showEditPopup(riskData: any) {
            this.showRiskMasterPopup = true;
            this.editpop = true
            this.byRow = true;
            this.byAdd = false;
            this.riskThreatData = { ...riskData };
            this.riskCopy = { ...riskData };
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.riskList;
            } else {
                this.riskList = this.dummyList;
            }
            emitter.emit('visible',this.showFilters)
        },
        fileReader(oEvent: any) {
            var oFile = oEvent.target.files[0];
            var reader = new FileReader();
            reader.onload = async (e: any) => {
                var data = e.target.result;
                data = new Uint8Array(data);
                var workbook = XLSX.read(data, {
                    type: 'array'
                });
                var first_sheet_name = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[first_sheet_name];
                var jsonArray:any = XLSX.utils.sheet_to_json(worksheet, {
                    raw: true,
                    header: this.fullKeys,
                    range: 1
                });

                let total = jsonArray.length;
                this.riskList = [];

                jsonArray.forEach((jsonObj:any)=>{  
                    let mandatoryCondition = this.mandatoryKeys.every((key: any) => {
                        return jsonObj[key]!='';
                    });
 
                    if (mandatoryCondition) {
                        let findObj = this.notImportedRiskList.find((obj:any)=> {return (jsonObj.risk == obj.risk &&  jsonObj.riskCategory == obj.riskCategory)})
                        if(findObj==undefined){
                            this.riskList.push(jsonObj);
                            this.dummyList.push(jsonObj);
                        }
                    }
                })

                if (this.riskList.length > 0) {
                    toast.info(`Imported ${total} rows, Valid rows: ${this.riskList.length}, Ignored rows: ${total - this.riskList.length}`, {
                        timeout: 3000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });

                    this.riskList.map((riskObj:any)=>{
                        let valuesArray = [ {value:1, label:'very low'}, {value:2, label:'low'}, {value:3, label:'moderate'}, {value:3, label:'medium'}, {value:4, label:'high'}, {value:5, label:'very high'},{value:5, label:'critical'}];

                        let mainbusiness:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.business).toLowerCase()})
                        riskObj.business = mainbusiness.value

                        let mainfinancial:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.financial).toLowerCase()})
                        riskObj.financial = mainfinancial.value

                        let mainlegal:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.legal).toLowerCase()})
                        riskObj.legal = mainlegal.value

                        let mainlikelihood:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.likelihood).toLowerCase()})
                        riskObj.likelihood = mainlikelihood.value

                        let mainoperations:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.operations).toLowerCase()})
                        riskObj.operations = mainoperations.value

                        let mainorganization:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.organization).toLowerCase()})
                        riskObj.organization = mainorganization.value

                        let maintechnical:any = valuesArray.find((obj:any)=>{return obj.label == (riskObj.technical).toLowerCase()})
                        riskObj.technical = maintechnical.value
                    })
                    await this.addRiskList();
                } else {
                    toast.error('No Rows Imported', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
                
            };
            reader.readAsArrayBuffer(oFile);
        },
        importDoc() {
            this.$refs.file.click();
        },
        showPopupByAdd() {
            this.showRiskMasterPopup = true;
            for (const key in this.riskThreatData) {
                this.riskThreatData[key] = '';
            }
            this.byAdd = true;
            this.byRow = false;
        },
        onCancelbtn() {
            if (this.byAdd) {
                for (const key in this.riskThreatData) {
                    this.riskThreatData[key] = '';
                }
            } else if (this.byRow) {
                this.riskThreatData = { ...this.riskCopy };
            }
            this.v$.$reset();
        },
        onCrossbtn() {
            this.showRiskMasterPopup = false;
             Object.keys(this.riskThreatData).forEach((prop) => {
                this.riskThreatData[prop] = '';
            });       
            this.v$.$reset();
            this.editpop = false;
        },
        filteredCategories(filterColumn: any, status: any) {
            this.selectedCategory = [
                ...new Set(
                    Object.values(this.optionsList).map((x: any) => {
                        x[status];

                        return x[status];
                    })
                )
            ].filter((category: any) => {
                return category.toLowerCase().includes(filterColumn.toLowerCase());
            });
        },
        getName(options: any, status: any) {
            let OptionWords = status.charAt(0).toLowerCase() + status.replace(/\s+/g, '').substring(1);
            this.filteredCategories(options, OptionWords);
            this.setState(options, OptionWords);
        },
        setState(options: any, status: any) {
            this.riskThreatData[status] = options;
            this.autoSuggestion = false;
        },
        async addOrUpdatethreat(riskThreatdata: any) {
            this.riskList = [];
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.riskList.push(riskThreatdata);

                if (riskThreatdata._id) {
                    await this.updateRiskList();
                } else {
                    await this.addRiskList();
                }
                this.v$.$reset();
            } else {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
       
        groupBy(arr: any, key: any) {
            return arr.reduce((storage: any, item: any) => {
                if (_.every(this.mandatoryKeys, _.partial(_.has, item))) {
                    var group = item[key];
                    storage[group] = storage[group] || [];
                    storage[group].push(item);
                }
                return storage;
            }, {});
        },

        async addRiskList() {
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/orgrisks/create`, this.riskList).then((result: any) => {
                    toast.info(`Uploaded succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
            } catch (e) {
                toast.info('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
            
            this.showRiskMasterPopup = false;
            this.displayListInTable = [];
            await this.getRisksList();
        },

        async updateRiskList() {
            this.showRiskMasterPopup = false;
            try {
                let riskId = this.riskThreatData._id;
                delete this.riskThreatData._id;
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/orgrisks/${riskId}/update`, this.riskThreatData).then((result: any) => {
                    toast.info(`Uploaded succesfully`, {
                        closeOnClick: true,
                        closeButton: 'button'
                    });
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }

            this.displayListInTable = [];
            await this.getRisksList();
        },
       riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        average(arr: any) {
            return arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
        },
        async getallTeamMembers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.orgUsersList = res.data;
			this.orgUsersList=this.orgUsersList.filter((user:any) => {
					return user.isActive==true
				});
				for (var i = 0; i < this.orgUsersList.length; i++) {
				this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
			}
			});
			console.log("this.orgUsersList",this.orgUsersList)
		},
        async getRisksList() {
            this.riskList = [];
            this.optionsList = [];
            const result = await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/orgrisks/getall`);
            
            result.data.forEach((risk: any) => {
                this.notImportedRiskList.push({...risk});
                risk.entityId = 20008;
                if (risk.organization) {
                    let averageImpact = Math.round(this.average([risk.organization, risk.business, risk.operations, risk.technical, risk.legal, risk.financial]));
                    risk.overallImpact = averageImpact;
                    risk.severity = this.riskSeverity(risk.overallImpact * risk.likelihood);
                    this.riskList.push(risk); 
                    this.optionsList.push(risk);
                }
            });
            console.log("assasasa",this.riskList)
            
            const resultArray = this.notImportedRiskList.map((obj:any) => {
                const { _id: removedProperty, ...newObj } = obj;
                return newObj;
            });
            this.notImportedRiskList = [...resultArray];

            this.riskList.forEach((riskRowData: any) => {
                this.displayListInTable.push({
                    entityId: riskRowData.entityId,
                    _id: riskRowData._id,
                    severity: riskRowData.severity,
                    risk: riskRowData.risk,
                    category: riskRowData.riskCategory,
                    impact: riskRowData.overallImpact,
                    likelihood: riskRowData.likelihood
                });
            });
        },
    },

    created() {
        this.getallTeamMembers()
        window.addEventListener('click', (e) => {
            this.autoSuggestion = false;
        });
    }
});
</script>
