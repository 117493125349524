<template>
    <div>
        <div v-if="columnName == 'severity'" :class="colorRiskSeverity(tipText)" class="rounded flex justify-center items-center bg-white positiontooltip w-full absolute group-hover:opacity-100 border-lightgrey border pointer-events-none" :style="styling">
            <img src="@/assets/tooltip.svg" alt="" class="absolute text-black bottom-full left-0 -mb-1 h-3.5 w-3" />
            <span class="text-black text-center text-xs"></span>
            <div class="px-2">
                {{
                    dbSeverity.find((l) => {
                        return l.value == tipText;
                    }).key
                }}
            </div>
        </div>
        <div v-else class="bg-white absolute z-50 group-hover:opacity-100 rounded texttip border-lightgrey border pointer-events-none" :style="styling">
            <img src="@/assets/tooltip.svg" alt="" class="absolute text-black bottom-full left-0 -mb-1 h-3.5 w-3" />
            <span class="text-black text-center text-xs"></span>
            <div class="px-2">
                {{ tipText }}
            </div>
        </div>
    </div>
</template>
<style scoped>
.tooltipwidth{
    width: 180px;
}
.texttip{
    min-height: 25px;
    width: 170px;
}
@media (max-width: 500px) {
  .texttip{
    min-height: 25px;
    width: 270px;
}
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter } from '@/main';
import { mapGetters } from 'vuex';

export default defineComponent({
    props: ['columnName','tipText', 'entityId','styling'],
    data(): any {
        return {
            stylingObj: { left: '', bottom: '' },
        }
    },
    computed:{
        ...mapGetters({ dbSeverity:'dbSeverity'}),
    },
    methods: {
        colorRiskSeverity(tipText: any) {
            if (tipText == 1) {
                return ['font-semibold text-lightgreen'];
            } else if (tipText == 2) {
                return ['font-semibold text-green'];
            } else if (tipText == 3) {
                return ['font-semibold text-yellow'];
            } else if (tipText == 4) {
                return ['font-semibold text-error'];
            } else if (tipText == 5) {
                return ['font-semibold text-darkred'];
            }
        },
    },

})
</script>
