<template>
   <div class="maincontainer pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="row w-full mb-3 justify-between flex" style="align-items: center">
            <h1 class="title">Risk History - {{mainTierRiskName}}</h1>
        </div>
        <div class="mt-3 text-xs">
            Risk history is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
        </div>
        <div class="bg-white border-lightgrey border rounded-t mt-4 w-full">
            <div class="flex p-3 items-center justify-between">
                <h1 class="title ml-5">Risk History</h1>
                <div class=" flex justify-center items-center">
                    <div class=" flex justify-center items-center">
                        <button class="btnprimary w-24 flex taskEditButtons" v-if="oldComments" @click="sortWorkLog()">
                            <img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
                            Newest
                        </button>
                        <button class="btnprimary w-24 flex taskEditButtons" v-if="!oldComments" @click="sortWorkLog()">
                            <img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
                            Oldest
                        </button> 
                    </div>
                </div>
            </div>
        </div>
        <div v-for="work in workLogObject" :key="work" class="bg-white p-4 border-lightgrey border w-full">
            <div class="flex ml-4">
                <div v-if="(Object.keys(work).includes('createdBy'))" class="initials cursor-pointer">
                    {{  
                        extractInitials(getPersonName(work.createdBy)) 
                    }}
                </div>
                <div class="logContainer">
                    <div class="flex">
                        <p v-if="(Object.keys(work).includes('createdBy'))" class="font-semibold cursor-pointer text-xs">
                            {{  
                                getPersonName(work.createdBy)
                            }}
                        </p>
                        
                        <p v-if="(!Object.keys(work).includes('previousStatus') && work.actionEntity == 10301)" class="mx-2 text-xs">{{"Created the risk status on"}}</p>
                        <p v-else-if="work.actionEntity == 10302 && (!Object.keys(work).includes('previousStatus'))" class="mx-2 text-xs">{{"Updated the Risk on"}}</p>
                        
                        <p v-else-if="work.statusCode == 0 && work.action==10407" class="mx-2 text-xs">{{'Rejected the Reset Request Request on'}}</p>
                        <p v-else-if="work.statusCode == 0 && work.action==10403" class="mx-2 text-xs">{{'Rejected the Accept Request Request on'}}</p>
                        <p v-else-if="work.statusCode == 0 && work.action==10405" class="mx-2 text-xs">{{'Rejected the Mitigation Task Request on'}}</p>
                        <p v-else-if="work.statusCode == 0 && work.action==10404" class="mx-2 text-xs">{{'Rejected the Delete Request Request on'}}</p>

                        <p v-else-if="(work.previousStatus == work.updatedStatus) && work.action==10403" class="mx-2 text-xs">{{'Created the Accept Request to the risk on'}}</p>
                        <p v-else-if="(work.previousStatus == work.updatedStatus) && work.action==10405" class="mx-2 text-xs">{{'Created the Mitigate Tasks to the risk on'}}</p>
                        <p v-else-if="(work.previousStatus == work.updatedStatus) && work.action==10407" class="mx-2 text-xs">{{'Created the Reset Request to the risk on'}}</p>
                        <p v-else-if="(work.previousStatus == work.updatedStatus) && work.action==10404" class="mx-2 text-xs">{{'Created the Delete Request to the risk on'}}</p>
                        
                        <p  v-else class="mx-2 text-xs">{{"Changed the risk status on"}}</p>

                        <p v-if="(Object.keys(work).includes('createdAt'))" class="font-semibold cursor-pointer text-xs">{{formatTime(work.createdAt)}}</p>
                    </div>
                    <div v-if="work.actionEntity == 10302 && (!Object.keys(work).includes('previousStatus'))"  class="mt-4 ml">
                        <!-- {{Object.keys(work.additionalInfo.risk.oldRisk[0])}}    -->

                        <div v-for="objKey in Object.keys(work.additionalInfo.risk.oldRisk[0])" :key="objKey">
                            <div v-if="objKey == 'riskOwner'" class="flex pt-1 text-xs">

                                <p class="capitalize font-semibold text-xs">{{objKey}}</p>
                                <p class="px-2 text-xs"> is changed from </p>

                                <p class="capitalize font-semibold text-xs">
                                    {{
                                        getPersonName(work.additionalInfo.risk.oldRisk[0][objKey])                                        
                                    }}
                                </p>

                                <div class="px-3 flex items-center"><img src="@/assets/arrowRight.svg"/></div>

                                <p class="capitalize font-semibold text-xs">
                                    {{
                                        getPersonName(work.additionalInfo.risk.newRisk[0][objKey])
                                    }}
                                </p>

                            </div>
                            <div v-else-if="objKey == 'priority'" class="flex pt-1 text-xs">

                                <p class="capitalize font-semibold text-xs">{{objKey}}</p>
                                <p class="px-2 text-xs"> is changed from </p>

                                <div v-if="!isNaN(work.additionalInfo.risk.oldRisk[0][objKey])" class="flex items-center justify-center rounded-full ml-2 w-6 h-6" :class="colorPriorityBg(work.additionalInfo.risk.oldRisk[0][objKey])">
                                    <div :class="colorPriorityText(work.additionalInfo.risk.oldRisk[0][objKey])">
                                        {{getPriorityLabel(work.additionalInfo.risk.oldRisk[0][objKey])}}
                                    </div>
                                </div>

                                <div class="px-3 flex items-center"><img src="@/assets/arrowRight.svg"/></div>

                                <div v-if="!isNaN(work.additionalInfo.risk.newRisk[0][objKey])" class="flex items-center justify-center rounded-full ml-2 w-6 h-6" :class="colorPriorityBg(work.additionalInfo.risk.newRisk[0][objKey])">
                                    <div :class="colorPriorityText(work.additionalInfo.risk.newRisk[0][objKey])">
                                        {{getPriorityLabel(work.additionalInfo.risk.newRisk[0][objKey])}}
                                    </div>
                                </div>

                            </div>
                            <div v-else class="flex py-1">

                                <p class="capitalize font-semibold text-xs">{{objKey}}</p>
                                <p class="px-2 text-xs"> is changed from </p>
                                
                                <div v-if="!isNaN(work.additionalInfo.risk.oldRisk[0][objKey])" class="flex items-center cursor-pointer relative rounded w-28 regular text-xs h-5" :class="colorRiskParameterText(work.additionalInfo.risk.oldRisk[0][objKey])">
                                    <div class="w-full h-full absolute rounded" :class="colorRiskSeverity(work.additionalInfo.risk.oldRisk[0][objKey])"></div>
                                    <div class="flex rounded w-28 items-center regular text-xs">
                                        <div >
                                            <div class="flex w-28 items-center justify-center regular pb-px">
                                                {{getRiskValue(work.additionalInfo.risk.oldRisk[0][objKey],objKey)}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div v-else>
                                    <div><p class="font-semibold text-xs">{{work.additionalInfo.risk.oldRisk[0][objKey]}}</p></div>
                                </div>

                                <div class="px-3 flex items-center"><img src="@/assets/arrowRight.svg"/></div>

                                <div v-if="!isNaN(work.additionalInfo.risk.newRisk[0][objKey])" class="flex items-center cursor-pointer relative rounded w-28 regular text-xs h-5" :class="colorRiskParameterText(work.additionalInfo.risk.newRisk[0][objKey])">
                                    <div class="w-full h-full absolute rounded" :class="colorRiskSeverity(work.additionalInfo.risk.newRisk[0][objKey])"></div>
                                    <div class="flex rounded w-28 items-center regular text-xs">
                                        <div>
                                            <div class="flex w-28 items-center justify-center regular pb-px">
                                                {{getRiskValue(work.additionalInfo.risk.newRisk[0][objKey],objKey)}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <p class="font-semibold text-xs">{{work.additionalInfo.risk.newRisk[0][objKey]}}</p>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div v-if="Object.keys(work).includes('insertedIds')">
                        <div v-for="task in work.insertedIds" :key="task" class="flex pt-2">
                            <p class="text-xs">TaskId:</p>
                            <p class="font-semibold px-2 hover:underline cursor-pointer text-xs" @click="openTaskEdit(task)">{{getTaskInfo(task)}}</p>
                            <p class="text-xs">{{"is Created"}}</p>
                        </div>
                    </div>
                    <div v-if="Object.keys(work).includes('taskId')">
                        <div class="flex pt-2">
                            <p class="text-xs">TaskId:</p>
                            <p class="font-semibold px-2 hover:underline cursor-pointer text-xs" @click="openTaskEdit(work.taskId)">{{getTaskInfo(work.taskId)}}</p>

                            <p class="text-xs" v-if="work.statusCode == 1">{{"is closed by Accepting"}}</p>
                            <p class="text-xs" v-else-if="work.statusCode == 0">{{"is closed by Rejecting"}}</p>
                        </div>
                    </div>
                    <div class="flex mt-4">
                        <div v-if="(Object.keys(work).includes('previousStatus') && (work.previousStatus != work.updatedStatus))" class="relative" @mouseout="doFalse()">
                            <div class="flex items-center cursor-pointer relative rounded w-28 regular text-xs h-5" :class="colorRiskText(work.previousStatus)">
                                <div class="w-full h-full absolute rounded" :class="colorRiskBg(work.previousStatus)"></div>
                                <div class="flex rounded w-28 items-center regular text-xs">
                                    <div v-if="!isNaN(work.previousStatus)">
                                        <div class="flex w-28 items-center justify-center regular pb-px">
                                            <!-- {{risksStatusValues.find((l) => {
                                                return l.id == work.previousStatus;
                                            }).value}} -->
                                            {{ getRiskStatusLabel(work.previousStatus) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="(Object.keys(work).includes('previousStatus') && (work.previousStatus != work.updatedStatus))" class="mx-4 flex items-center"><img src="@/assets/arrowRight.svg"/></div>
                        <div v-if="(work.previousStatus != work.updatedStatus)" class="relative" @mouseout="doFalse()">
                            <div class="flex items-center cursor-pointer relative rounded w-28 regular text-xs h-5" :class="colorRiskText(work.updatedStatus)">
                                <div class="w-full h-full absolute rounded" :class="colorRiskBg(work.updatedStatus)"></div>
                                <div class="flex rounded w-28 items-center regular text-xs">
                                    <div v-if="!isNaN(work.updatedStatus)">
                                        <div class="flex w-28 items-center justify-center regular pb-px">
                                            <!-- {{risksStatusValues.find((l) => {
                                                return l.id == work.updatedStatus;
                                            }).value}} -->
                                            {{getRiskStatusLabel(work.updatedStatus)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.initials {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: #152a43;
  color: white;
  border-radius: 50%;
  margin-right: 14px;
}
.logContainer{
    padding-top: 5px;
}
.fontSizingForPriority{
    font-size: 10px !important;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import breadCrumb from '@/components/breadCrumb.vue';
import moment from 'moment';
import store from '@/store';
import { mapGetters } from 'vuex';

export default defineComponent({
	data(): any {
        return{
            oldComments:true,
            workLogObject:[],
            mainRiskId:'',
            routesArray:[],
            tasksData:[],
            risksPriorityValue:[
                {
                    id:5,
                    value:'5',
                    label:'P1'
                },
                {
                    id:4,
                    value:'4',
                    label:'P2'
                },
                {
                    id:3,
                    value:'3',
                    label:'P3'
                },
                {
                    id:2,
                    value:'2',
                    label:'P4'
                },
                {
                    id:1,
                    value:'1',
                    label:'P5'
                },
            ],
            risksSeverityValue: [
                {
                    id: 1,
                    value: 'Very Low'
                },
                {
                    id: 2,
                    value: 'Low'
                },
                {
                    id: 3,
                    value: 'Moderate'
                },
                {
                    id: 4,
                    value: 'High'
                },
                {
                    id: 5,
                    value: 'Very High'
                }
            ],
            risksStatusValues: [
                
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10403,
                    value: 'Accepted'
                },
                {
                    id: 10405,
                    value: 'Mitigated'
                },
                {
                    id: 10406,
                    value: 'Partial'
                },
                {
                    id:10407,
                    value:'Reset'
                },
                {
                    id:10409,
                    value:'On Hold'
                },
                {
                    id:10408,
                    value:'Approval'
                },
            ],
            orgUsersList:[],
            optionsUserArray:[],
    	    allSystems:[],
            tierRiskId:'',
            mainTierRiskName:'',
            businessProcessList:[],
            businessEntitiesList:[],
            mainRiskObject:{},
            allRiskArray:[]

        }
    },
    components: {
        breadCrumb,
    },
    computed:{
        ...mapGetters({ userInfo: 'userInfo',dbSeverity:'dbSeverity',dbLikelihood:'dbLikelihood',dbBussiness:'dbBussiness',
            dbOrganization:'dbOrganization',dbOperations:'dbOperations',dbLegal:'dbLegal',
            dbFinancial:'dbFinancial',dbTechnical:'dbTechnical',dbProcessCriticality:'dbProcessCriticality',
            dbAvailability:'dbAvailability',dbConfidentiality:'dbConfidentiality',dbIntegrity:'dbIntegrity',
            dbInformationClassification:'dbInformationClassification',dbSecurityCategory:'dbSecurityCategory',dbPriority:'dbPriority' }),
    },
    methods: {
        sortWorkLog(){
			if(this.oldComments){
				this.workLogObject.sort().reverse();
				this.oldComments = false;
			}else{
				this.workLogObject.sort().reverse();
				this.oldComments = true;
			}
		},
        openTaskEdit(taskId:any){
            let mainTask = this.tasksData.find((taskObj:any)=>{
                return taskObj._id == taskId;
            })
            console.log('kljhgf',mainTask)
            if(mainTask.action == 10405){
                this.$router.push({ name: 'taskEditPage', params: { taskId: taskId } });
            }else{
                this.$router.push({ name: 'taskEditPage', params: { taskId: taskId }, query:{ approvals:true }});
            }
            
        },
        extractInitials(name:any) {
            const names = name.split(' ');
            return names.map((name:any) => name[0]).join('').toUpperCase();
        },
        doFalse() {
            this.pop = false;
        },
        formatTime(time:any){
            let now = moment(time);
			return now.format('DD-MM-YYYY, hh:mm:ss');
        },
        getTaskInfo(taskIdNumber:any){
            let mainTask = this.tasksData.find((taskObj:any)=>{
                return taskObj._id == taskIdNumber;
            })
            if(mainTask != undefined){
                let formattedId = this.formatTaskId(mainTask.taskId);
                return formattedId;
            }else{
                return ''
            }
        },
        formatTaskId(taskId:any) {
            return taskId.toString().padStart(4, '0');
        },
        colorPriorityText(value: any) {
            if (value == 5) {
                return ['text-lightgreen fontSizingForPriority font-semibold'];
            } else if (value == 4) {
                return ['text-green fontSizingForPriority font-semibold'];
            } else if (value == 3) {
                return ['text-yellow fontSizingForPriority font-semibold'];
            } else if (value == 2) {
                return ['text-error fontSizingForPriority font-semibold'];
            } else if (value == 1) {
                return ['text-darkred fontSizingForPriority font-semibold'];
            }
        },
        colorPriorityBg(value: any) {
            if (value == 5) {
                return ['border-solid border-4 border-lightgreen'];
            } else if (value == 4) {
                return ['border-solid border-4 border-green'];
            } else if (value == 3) {
                return ['border-solid border-4 border-yellow'];
            } else if (value == 2) {
                return ['border-solid border-4 border-error'];
            } else if (value == 1) {
                return ['border-solid border-4 border-darkred'];
            }
        },
        colorRiskText(Status: any) {
            if (Status == 10401) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (Status == 10402) {
                return ['text-electricGreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-electricGreen'];
            } else if (Status == 10403) {
                return ['text-primary', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-primary'];
            } else if (Status == 10405) {
                return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
            } else if (Status == 10406) {
                return ['text-yellow', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-yellow'];
            } else if (Status == 10407) {
                return ['text-resetBlue', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-resetBlue'];
            }else if (Status == 10409) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (Status == 10408) {
                return ['text-primary', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-primary'];
            }
        },
         colorRiskBg(Status: any) {
            if (Status == 10401) {
                return ['bg-darkred', 'opacity-10'];
            } else if (Status == 10402) {
                return ['bg-electricGreen', 'opacity-10'];
            } else if (Status == 10403) {
                return ['bg-primary', 'opacity-10'];
            } else if (Status == 10405) {
                return ['bg-lightgreen', 'opacity-10'];
            } else if (Status == 10406) {
                return ['bg-yellow', 'opacity-10'];
            } else if (Status == 10407) {
                return ['bg-resetBlue', 'opacity-10'];
            } else if (Status == 10409) {
                return ['bg-darkred', 'opacity-10'];
            } else if (Status == 10408) {
                return ['bg-primary', 'opacity-10'];
            }
        },
        colorRiskSeverity(status: any) {
            if (status == 1) {
                return ['bg-lightgreen opacity-10'];
            } else if (status == 2) {
                return ['bg-green opacity-10'];
            } else if (status == 3) {
                return ['bg-yellow opacity-10'];
            } else if (status == 4) {
                return ['bg-error opacity-10'];
            } else if (status == 5) {
                return ['bg-darkred opacity-10'];
            }
        },
        colorRiskParameterText(status: any) {
            if (status == 1) {
                return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-lightgreen'];
            } else if (status == 2) {
                return ['text-green', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-green'];
            } else if (status == 3) {
                return ['text-yellow', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-yellow'];
            } else if (status == 4) {
                return ['text-error', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-error'];
            } else if (status == 5) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            }
        },
        toolTipColorRiskText(Status: any) {
            if (Status == 10401) {
                return ['text-darkred'];
            } else if (Status == 10402) {
                return ['text-electricGreen'];
            } else if (Status == 10403) {
                return ['text-primary'];
            } else if (Status == 10405) {
                return ['text-lightgreen'];
            } else if (Status == 10406) {
                return ['text-yellow'];
            } else if (Status == 10407) {
                return ['text-resetBlue'];
            } else if (Status == 10409) {
                return ['text-darkred'];
            } else if (Status == 10408) {
                return ['text-primary'];
            }
        },
         async getallUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.orgUsersList = res.data;
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }
            });
            console.log('this.orgUsersList', this.orgUsersList);
        },
        getPersonName(person:any){
            let personObj = this.orgUsersList.find((user:any)=>{
                                return user._id == person
                            })
            if(personObj == undefined){
                return ''
            }else{
                return personObj.name;
            }
        },
        async getWorkLog(){
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/riskhistory/getall`).then((result: any) => {
                if(result.data.length > 0){
                    let mainObject:any = result.data.find((obj:any)=> {return obj.linkedRiskId == this.mainRiskId});
                    this.workLogObject = [...mainObject.riskHistory];
                    console.log("workhossss",this.workLogObject);
                }
            });
        },
        getRiskStatusLabel(id:any){
            let name = this.risksStatusValues.find((l:any) => {return l.id == id;})
            if(name == undefined){
                return ''
            }else{
                return name.value;
            }
        },
        getPriorityLabel(cameId:any){
            let labelObj = this.dbPriority.find((l:any) => {return l.value == cameId;})
            if(labelObj == undefined){
                return ''
            }else{
                return labelObj.key;
            }                               
        },
        getRiskValue(idNum:any,key:any){
            let valueObj;

            if(key == 'operations'){
                valueObj = this.dbOperations.find((l:any) => {return l.value == idNum;})
            }else if(key=='organization'){
                valueObj = this.dbOrganization.find((l:any) => {return l.value == idNum;})
            }else if(key=='business'){
                valueObj = this.dbBussiness.find((l:any) => {return l.value == idNum;})
            }else if(key=='technical'){
                valueObj = this.dbTechnical.find((l:any) => {return l.value == idNum;})
            }else if(key=='legal'){
                valueObj = this.dbLegal.find((l:any) => {return l.value == idNum;})
            }else if(key=='financial'){
                valueObj = this.dbFinancial.find((l:any) => {return l.value == idNum;})
            }else if(key=='likelihood'){
                valueObj = this.dbLikelihood.find((l:any) => {return l.value == idNum;})
            }

            if(valueObj == undefined){
                return ''
            }else{
                return valueObj.key;
            }                                 
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.allSystems = result.data.map((sys: any) => {
                    console.log('sysyems', sys);
                    return { value: sys._id, label: sys.systemName };
                });
            });
        },
        async getBusinessProcess(){
            this.businessProcessList = [];
            this.tableRow = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                result.data.forEach((entity: any) => {
                    this.businessProcessList.push(entity)
                });
            })
        },
        async getAllOrganizations() {
            this.businessEntitiesList = []
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                result.data.map((object: any)=>{
                    this.businessEntitiesList.push(object)
                })
            });
        },
        async getAllTasks(){
             await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tasks/getall`).then((result: any) => {
                this.tasksData = result.data;
            });
        },
        async getAllRisks(){
            this.allRiskArray = []
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risks/getall`).then((result: any) => {
                this.allRiskArray = result.data
            })
        }
        },
        async mounted(){
            await this.getAllRisks();

            if(Object.keys(this.$route.query).includes('systems') || this.$route.path.includes('/worklog/system')){
                await this.getSystems();
                this.tierRiskId = this.$route.params.systemId;
                let systemDetails = this.allSystems.find((obj:any)=>{return obj.value == this.tierRiskId});
                this.mainTierRiskName = systemDetails.label
            }else if(Object.keys(this.$route.query).includes('process') || this.$route.path.includes('/worklog/process')){
                await this.getBusinessProcess();
                this.tierRiskId = this.$route.params.processId;
                let processDetails = this.businessProcessList.find((obj:any)=>{return obj._id == this.tierRiskId});
                this.mainTierRiskName = processDetails.businessProcessName
            }else if(Object.keys(this.$route.query).includes('organization') || this.$route.path.includes('/worklog/org')){
                await this.getAllOrganizations();
                this.tierRiskId = this.$route.params.orgId;
                let orgDetails = this.businessEntitiesList.find((obj:any)=>{return obj._id == this.tierRiskId});
                this.mainTierRiskName = orgDetails.entityName
            }
            
            this.mainRiskId = this.$route.params.riskId;

            let findRisk = this.allRiskArray.find((obj:any)=>{return obj._id == this.$route.params.riskId; })
            this.mainRiskObject = {...findRisk};

            await this.getAllTasks();


            if(this.$route.path.includes('/worklog') && Object.keys(this.$route.query).includes('Risks')){
                this.routesArray = [{name:'Risks',routeName:'risks'},{name:'Tier-Risks',routeName:'tierRisks',id: this.mainRiskObject.mappedRisk},{name:'Risk History',routeName:'worklog'}]

            }else if(Object.keys(this.$route.params).includes('processId')){
                this.routesArray = [{name:'Risks',routeName:'risks'},{ name: 'Process-Information', routeName: 'processInformation', id: `${ this.$route.params.processId}` },{name:'Risk History',routeName:'worklog'}]

            }else if(Object.keys(this.$route.params).includes('orgId')){
                this.routesArray = [{name:'Risks',routeName:'risks'},{ name: 'Organization-Information', routeName: 'organizationInformation', id: `${ this.$route.params.orgId}` },{name:'Risk History',routeName:'worklog'}]

            }else{
                this.routesArray = [
                    { name: 'Systems', routeName: 'systems' },
                    { name: 'System-Information', routeName: 'edit-system', id: this.$route.params.systemId},
                    {name:'Risk History',routeName:'worklog'}
                ];
            }
            await this.getallUsers()
            await this.getWorkLog();
        },

});
</script>
