<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Risk Clone</h1>
            <div class="flex items-center">
                <button @click="cloneRisk()" :class="(JSON.stringify(defaultSelectedIds) == JSON.stringify(selectedTierObjectIds)) ? 'btndisabled pointer-events-none':'btnprimary'">
                    Clone
                </button>
                <button class="ml-2 filterButton flex" @click="filterOpen" >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>

        <div class="mt-3 text-sm">
            The Global Risks Report 2022, 17th Edition, is published by the World Economic Forum. The information in this report, or on which this report is based, has been obtained from sources that the authors believe to be reliable and accurate. However, it has not been independently verified and no representation or warranty, express or implied, is made as to the accuracy or completeness of any information obtained from third parties.
        </div>
        <div class="tableCornerRadius bg-white">
            <div class="overflow-x-auto">
            <table>
                <thead>
                    <th class="w-4"></th>
                    <th scope="col" v-for="column in headersData" @click="sort(column)" :key="column" class="bg-white capitalize w-1/2"> 
                        <div class="flex items-center" :class="{'w-28':column == 'entityType'}">
                            <div>{{ column }}</div>
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                        </div>
                    </th>
                    <tr v-if="showFilters">
                        <td class="w-4"></td>
                        <td v-for="(name,index) in headersData" class="bg-white" :key="index">
                            <select class="inputboxStylingForFilter filter-font-size w-28" v-model="filtersObject[name]" v-if="name == 'entityType'">
                                <option :value="filtersObject.name" disabled selected hidden>Select</option>
                                <option v-for="(value, index) in orgType" :key="index" :value="value.id">{{ value.value }}</option>
                            </select>
                            <select class="inputboxStylingForFilter" v-model="filtersObject[name]" v-else-if="name == 'criticality'">
                                <option :value="filtersObject.name" selected hidden disabled>Select</option>
                                <option v-for="pair in dbProcessCriticality" :key="pair" :value="pair.value">{{ pair.key }}</option>
                            </select>
                            <select class="inputboxStylingForFilter" v-model="filtersObject[name]" v-else-if="name === 'securityCategory'">
                                <option :value="filtersObject.name" disabled selected hidden>Select</option>
                                <option v-for="pair in dbSecurityCategory" :key="pair" :value="pair.value">{{ pair.key }}</option>
                            </select>
                            <input v-else type="text" class="inputboxStylingForFilter filter-font-size w-full" placeholder="Search" v-model="filtersObject[name]" />
                        </td>
                    </tr>
                </thead>
                <tbody v-if="sortedData.length==0">
                    <tr class="cursor-pointer rowHeight">
                        <td class="relative h-10" :colspan="headersData.length + 1">
                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                        </td>
                    </tr>
                </tbody>
                <tbody class="nonEmptyTable" v-else>
                    <tr v-for="tierObj,indexx in sortedData" :key="tierObj" class="cursor-pointer rowHeight" >
                        <td class="w-4">
                            <input class="checkbox ml-4" type="checkbox" v-model="selectedTierObjectIds" :value="tierObj._id" :disabled="defaultSelectedIds.includes(tierObj._id)" />
                        </td>
                        <td v-for="column in headersData" @mouseout="pop = false" :key="column" class="cursor-pointer relative">  
                            <div v-if="column == 'entityType'">
                                <div>
                                    {{ getOwnerType(tierObj[column]) }}
                                </div>
                            </div>
                            <div v-else-if="column == 'criticality' || column == 'securityCategory'">
                                <div class="flex items-center justify-center rounded h-5 w-24" :class="colorRiskBg(tierObj[column])">
                                    <div :class="colorRiskText(tierObj[column])" >
                                        <div v-if="column =='criticality'">
                                            {{
                                                dbProcessCriticality.find((l) => {
                                                    return l.value == tierObj[column];
                                                }).key
                                            }}
                                        </div>
                                        <div v-else-if="column == 'securityCategory' ">
                                            {{getSecurityCategory(tierObj[column])}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else @mousemove="toolTip(column, $event, indexx, tierObj, 'text')">
                                <div class="table-ellipsis">
                                    <div @mousemove="toolTip(column, $event, indexx, tierObj, 'text')">
                                        {{ tierObj[column] }}
                                    </div>
                                </div>
                                <div v-if="pop == true && sortedData[tipIndex][column] == tipText && tipColumn == column && tipIndex == indexx">
                                    <toolTip :columnName="column" :tipText="tipText" :styling="styling"></toolTip>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
            <div v-if="sortedData.length > 0">
                <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination> 
            </div>
        </div>
    </div>
         
</template>
<style scoped>
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { emitter, toast } from '@/main';
import { mapGetters } from 'vuex';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import moment from 'moment';
import { clone } from 'lodash';
import breadCrumb from '@/components/breadCrumb.vue';

export default defineComponent({
    data(): any {
        return {
            showFilters: false,
            orgUsersList:[],
            optionsUserArray:[],
            tierObjects:[],
            businessEntityOrgList:[],
            allSystems:[],
            businessProcessList:[],
            headersData:[],
            selectedTierObjectIds:[],
            defaultSelectedIds:[],
            routesArray:[],
            

            pop: false,
            styling: { left: '', bottom: '' },
            tipText: '',
            tipColumn: '',
            tipIndex: '',

            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',

            pageSize: 20,
            currentPage: 1,
            dynamicPagination:'',
            filtersObject:{},
            presentRiskDataObj:{},
            orgType:[
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Subsidiary'
                },
                {
                    id: 3,
                    value: 'Branch'
                },
            ],
        };
    },
    components: {
        toolTip,
        pagination,
        breadCrumb
    },

    computed: {
         ...mapGetters({ userInfo: 'userInfo',dbProcessCriticality:'dbProcessCriticality',dbSecurityCategory:'dbSecurityCategory'}),

        sortedData: function (): any {
            return [...this.tierObjects]
                .filter((row: any) => {
                    for (const key in this.filtersObject) {
                        const inputValue = this.filtersObject[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index+1
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
    },
    methods: {
        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
            if(this.showFilters == false){
                this.filtersObject = {}
            }
        },
        getOwnerType(input:any){
            let type = this.orgType.find((l:any)=>{ return l.id == input});
            if(type==undefined){
                return input;
            }else{
                return type.value;
            }
        },
        colorRiskText(value: any) {
            if (value == 1) {
                return ['text-lightgreen font-semibold'];
            } else if (value == 2) {
                return ['text-green font-semibold'];
            } else if (value == 3) {
                return ['text-yellow font-semibold'];
            } else if (value == 4) {
                return ['text-error font-semibold'];
            } else if (value == 5) {
                return ['text-darkred font-semibold'];
            }else{
                return ''
            }
        },
        colorRiskBg(value: any) {
            if (value == 1) {
                return ['bg-veryLowColor border-solid border border-lightgreen'];
            } else if (value == 2) {
                return ['bg-lowColor border-solid border border-green'];
            } else if (value == 3) {
                return ['bg-moderateColor border-solid border border-yellow'];
            } else if (value == 4) {
                return ['bg-highColor border-solid border border-error'];
            } else if (value == 5) {
                return ['bg-veryHighColor border-solid border border-darkred'];
            }else{
                return ''
            }
        },
         colorInformationText(value: any) {
            if (value == 3) {
                return ['text-darkred font-semibold'];
            } else if (value == 2) {
                return ['text-yellow font-semibold'];
            } else if (value == 1) {
                return ['text-lightgreen font-semibold'];
            }
        },
        colorInformationBg(value: any) {
            if (value == 1) {
                return ['bg-lowColor border-solid border border-lightgreen'];
            } else if (value == 2) {
                return ['bg-moderateColor border-solid border border-yellow'];
            } else if (value == 3) {
                return ['bg-highColor border-solid border border-darkred'];
            }
        },
        getSecurityCategory(id:any){
            let findObj = this.dbSecurityCategory.find((obj:any)=>{return obj.value == id})
            if(findObj == undefined || findObj == null){
                return 'None'
            }else{
                return findObj.key
            }
        },
        paginationUpdations(currentPage:any,pageSize:any){
            this.currentPage=currentPage;
            this.pageSize=pageSize;
        },
        sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            this.tipText = row[column];
            this.tipColumn = column;
            this.tipIndex = index;
      
            switch (status) {
                case 'text':
                    this.handleTextTooltip(e);
                    break;
                case 'circleButton':
                    if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
                        this.pop = true;
                        this.styling.left = e.offsetX + e.target.offsetLeft + 13 + 'px';
                        this.styling.bottom = -18 + 'px';
                    }
            }
        },
        handleTextTooltip(e:any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                this.pop = true;
                this.styling.left = e.offsetX + 38 + 'px';
                this.styling.bottom = -75 + 'px';
            } else {
                this.pop = false; 
            }
        },
        isTextOverflowed(element:any) {
            return element.scrollHeight > element.clientHeight;
        },
        async getAllSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.allSystems = result.data;
            });
        },
        async getCiaInformation() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/allsystems/cia`).then((result: any) => {
                let cia = result.data
                for (let j = 0; j < this.allSystems.length; j++) {
                    let findobj = cia.find((obj:any)=>{return obj.id == this.allSystems[j]._id});
                    if(findobj.cia == null){
                        this.allSystems[j].securityCategory = 0
                    }else{
                        this.allSystems[j].securityCategory = findobj.cia;
                    }
                }
            });
        },
        async getAllBusinessProcesses() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                this.businessProcessList = result.data;
            });
        },
        async getBusinessEntities(){
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                this.businessEntityOrgList = result.data;
            })
        },
        async getMappedTiersForRisk(){
            this.selectedTierObjectIds = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risk/${this.$route.params.mappedRisk}/systems`).then((result: any) => {

                let filter:any = result.data.filter((risk: any) => {
                    return risk.tier == this.$route.query.tier;
                });

                let mappedTiers:any = filter;

                if(this.$route.params.type == 'systems'){
                    mappedTiers.map((obj:any)=>{this.selectedTierObjectIds.push(obj.systemId)})
                }else if(this.$route.params.type == 'process'){
                    mappedTiers.map((obj:any)=>{this.selectedTierObjectIds.push(obj.processId)})
                }else if(this.$route.params.type == 'organization'){
                    mappedTiers.map((obj:any)=>{this.selectedTierObjectIds.push(obj.orgId)})
                }
                this.defaultSelectedIds = [...this.selectedTierObjectIds]
            })
        },
        async getPresentRisk(){
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risks/getall?active=true`).then((result: any) => {
                let allRisks:any  = result.data;
                let present = allRisks.find((obj:any)=>{return obj._id == this.$route.params.riskId;});
                
                let mandatoryKeys = ['risk',"threatCategory","threatSource","vulnerability","threatEvent","organization","business","operations","technical","legal","financial","likelihood","riskCategory", "riskOwner","mappedRisk", "tier"];
                mandatoryKeys.map((key:any)=>{ this.presentRiskDataObj[key] = present[key]})
            })
        },
        async cloneRisk(){
            let filterTierIds = this.selectedTierObjectIds.filter((id:any)=>{return !this.defaultSelectedIds.includes(id)});

            let payload:any = [];

            filterTierIds.map((Id:any)=>{
                let addObj:any = {}
                addObj.statusCode = 10401;
                let now = moment();
                addObj.createdBy = this.userInfo._id;
                addObj.createdAt = now.format('DD-MM-YYYY, HH:mm:ss');
                addObj.riskId = [this.$route.params.mappedRisk];

                if(this.$route.params.type == 'systems'){
                    addObj.systemId = Id;
                    addObj.tier = 3
                }else if(this.$route.params.type == 'process'){
                    addObj.processId = Id;
                    addObj.tier = 2
                }else if(this.$route.params.type == 'organization'){
                    addObj.orgId = Id;
                    addObj.tier = 1
                }
                payload.push(addObj)
            })

            await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/create/risks`, payload).then((result: any) => {
                toast.info(`Cloned Succesfully`, {
                    closeOnClick: true,
                    closeButton: 'button'
                });
            })
            await this.getMappedTiersForRisk();
        }
    },
    async created(){
        await this.getMappedTiersForRisk();
        await this.getPresentRisk();
        console.log("path",this.$route.path)
        console.log("business process list", this.presentRiskDataObj.mappedRisk)
        
        console.log("Checking",Object.keys(this.$route.query))
        if(Object.keys(this.$route.query).includes('Risks')){
            this.routesArray = [{name:'Risks',routeName:'risks'},{name:'Clone',routeName:'cloner'}];
        } else if(Object.keys(this.$route.query).includes('process')){
            this.routesArray = [
                { name: 'Business Process', routeName: 'businessProcess' },
                { name: 'Process-Information' , routeName: 'processInformation', id : `${ this.businessProcessList[0]._id }` },
                { name: 'Clone', routeName:'cloner'}
            ];
        } else if(Object.keys(this.$route.query).includes('tierRisks')){
            this.routesArray = [{name:'Risks',routeName:'risks'},{ name:'Tier-Risks',routeName:'systemRisk', id: this.presentRiskDataObj.mappedRisk },{name:'Clone',routeName:'cloner'}];
            
        } else if(Object.keys(this.$route.query).includes('systems')){
            this.routesArray = [
                { name: 'Systems', routeName: 'systems' },
                { name: 'System-Information', routeName: 'edit-system', id: this.allSystems[0]._id},
                { name:'Clone',routeName:'cloner'}
            ];
        } else if(Object.keys(this.$route.query).includes('organization')){
            this.routesArray = [
                { name: 'Organization', routeName: 'organization' },
                { name: 'Organization-Information', routeName: 'worklogFromOrganization', id: `${ this.businessEntityOrgList[0]._id }` },
                { name:'Clone',routeName:'cloner'}
            ];
        }
    },
    async mounted() {
        if(this.$route.params.type == 'systems'){
            await this.getAllSystems();
            await this.getCiaInformation()
            this.headersData = ['systemName','securityCategory'];
            this.tierObjects = this.allSystems

        }else if(this.$route.params.type == 'process'){
            await this.getAllBusinessProcesses();
            this.headersData = ['businessProcessName','criticality']
            this.tierObjects = this.businessProcessList;

        }else if(this.$route.params.type == 'organization'){
            await this.getBusinessEntities();
            this.headersData = ['entityDisplayName','entityType']
            this.tierObjects = this.businessEntityOrgList;

        }
    },  
});
</script>

