import { createStore, mapGetters } from "vuex";
import axios from 'axios'

export default createStore({
    state: {
        toggle:true,
        status: '',
        user: {},
        isLoading:false,
        val:'',
        scalesStatus: '',
        scalesInfo:[],
        role:[],
        systemsForMitigate:[],

        labelsStatus:'',
        dbLabelsObject:{},

        dbAvailability:[],
        dbBussiness:[],
        dbConfidentiality:[],
        dbFinancial:[],
        dbInformationClassification:[],
        dbIntegrity:[],
        dbLegal:[],
        dbLikelihood:[],
        dbOperations:[],
        dbOrganization:[],
        dbPriority:[],
        dbProcessCriticality:[],
        dbSecurityCategory:[],
        dbSeverity:[],
        dbTechnical:[],
        dbOverAllImpact:[],
    },
    mutations: {
        auth_request(state) {
        
            state.status = 'loading'
        },
        auth_success(state, user) {
            state.status = 'success'
            state.user = user
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
        },
        scales_request(state) {
            state.scalesStatus = 'loading'
        },
        scales_success(state, scales) {
            state.scalesStatus = 'success'
            state.scalesInfo = scales
        },
        scales_error(state) {
            state.scalesStatus = 'error'
        },
        roles_success(state, role) {
          state.scalesStatus = 'success'
          state.role = role
        },
        labels_request(state){
            state.labelsStatus = 'loading'
        },
        labels_success(state,labelsData){
            state.labelsStatus = 'success'
            state.dbLabelsObject = labelsData
            
            labelsData.map((item:any)=>{
                switch(item.entityId){
                    case 20401:
                        state.dbConfidentiality = item.values;
                        break;
                    case 20402:
                        state.dbIntegrity = item.values;
                        break;
                    case 20403:
                        state.dbAvailability = item.values;
                        break;
                    case 20404:
                        state.dbSecurityCategory = item.values;
                        break;
                    case 20405:
                        state.dbLikelihood = item.values;
                        break;
                    case 20406:
                        state.dbOperations = item.values;
                        break;
                    case 20407:
                        state.dbOrganization = item.values;
                        break;
                    case 20408:
                        state.dbBussiness = item.values;
                        break;
                    case 20409:
                        state.dbTechnical = item.values;
                        break;
                    case 20410:
                        state.dbFinancial = item.values;
                        break;
                    case 20411:
                        state.dbLegal = item.values;
                        break;
                    case 20412:
                        state.dbSeverity = item.values;
                        break;
                    case 20413:
                        state.dbPriority = item.values;
                        break;
                    case 20414:
                        state.dbInformationClassification = item.values;
                        break;
                    case 20415:
                        state.dbProcessCriticality = item.values;
                        break;
                    case 20416:
                        state.dbOverAllImpact = item.values;
                        break;
                }   
            })
        },
        labels_error(state){
            state.labelsStatus = 'error'
        }
        // systemsForMitigateUpdate(state,selectedData){
        //    state.systemsForMitigate = selectedData;
        // }
    },
    actions: {
        async getUserInfo({commit,getters}) {
            commit('auth_request')
            return await new Promise((resolve, reject) => {
                axios({ 
                url: `${process.env.VUE_APP_AUTH_API_URL}/userInfo/get`,
                method: 'GET',
                withCredentials: true,
                headers:{
                    Authorization : 'Bearer ' + localStorage.getItem('AUTH_ID')
                } })
                .then( async (resp) => {
                    await  this.dispatch('getRolesInfo')
                    console.log("getters",getters)

                    const allRolesData=getters.rolesInfo.data
                    console.log("allRolsd1233ffghes",allRolesData)
                  
                    console.log('response in store',resp);
                    const user = resp.data[0];
                    user.currentRole = user.roles.find((role:any) => { return role.appId == 10003 }).roleId
                    user.permissions=allRolesData.filter((role:any)=>{
                      return role.roleId==user.currentRole
                    })
                    console.log('user',user);
                    commit('auth_success', user)

                    //after first authentication dispatching labels info
                    await this.dispatch('getLabelsInfo')
                    //resolving authentication promise
                    resolve(resp)
                })
                .catch(err => {
                    console.log('error in state',err);
                    commit('auth_error');
                    reject(err);
                    localStorage.removeItem('AUTH_ID');
                    // window.location.href = window.location.origin+'/onboarding'
                })
            })
        },
        logout({ commit }) {
            return new Promise<void>((resolve) => {
                commit('logout');
                resolve();
            })
        },
        getRolesInfo({commit}){
          commit('roles_request')
            return  new Promise((resolve, reject) => {
                axios({ 
                url: `${process.env.VUE_APP_RISK_API_URL}/api/root/roles/master`,
                method: 'GET',
                headers:{
                    Authorization : 'Bearer ' + localStorage.getItem('AUTH_ID')
                }
            })
            .then(resp => {
               commit('roles_success', resp);
                console.log('response in store',resp);
                // commit('auth_success', user)
                    resolve(resp)
                
                // return true
            })
            .catch(err => {
                console.log('error in state',err);
            })
        })
        },
        getScalesInfo({commit}) {
            commit('scales_request')
                return new Promise((resolve, reject) => {
                    axios({ 
                    url: `${process.env.VUE_APP_RISK_API_URL}/api/org/root/scales/master`,
                    method: 'GET',
                    headers:{
                        Authorization : 'Bearer ' + localStorage.getItem('AUTH_ID')
                    }
                })
                .then(resp => {
                    console.log('responssdfgasgasdfgASDFGJe in store',resp);
                    commit('scales_success', resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    console.log('error in state',err);
                    commit('scales_error');
                    reject(err);
                })
            })
        },

        getLabelsInfo({commit}){
            commit('labels_request')

            return  new Promise((resolve, reject) => {
                axios({ 
                url: `${process.env.VUE_APP_ORG_API_URL}/api/org/labels/getall`,
                method: 'GET',
                headers:{
                    Authorization : 'Bearer ' + localStorage.getItem('AUTH_ID')
                }
            })
            .then(resp => {
                    console.log('response in storeresponse in storeresponse in store',resp.data);
                    commit('labels_success', resp.data);
                    resolve(resp)
                })
            .catch(err => {
                    console.log('error in state',err);
                    commit('labels_error')
                    reject(err);
                })
            })
        },
        
        selectedRoute(context, payload) {
         context.state.val=payload
        },
        selectedToggle(context, payload) {
            console.log("Ssss",payload)
            context.state.toggle=payload;
        },
        systemsForMitigateUpdate(context,payload){
            context.state.systemsForMitigate = payload;
        }
        // selectedRoles(context, payload) {
        //   context.state.roles=payload
        //  }
    
    },
    getters: {
        toggle:state=>state.toggle,
        authStatus: state => state.status,
        userInfo: state => state.user,
        loading:state=>state.isLoading,
        val: state => state.val,
        scalesInfo: state => state.scalesInfo,
        rolesInfo:state=>state.role,
        systemsSelected: state=>state.systemsForMitigate,
        
        dbLabelsObject:state => state.dbLabelsObject,
        
        dbAvailability:state => state.dbAvailability,
        dbBussiness:state => state.dbBussiness,
        dbConfidentiality:state => state.dbConfidentiality,
        dbFinancial:state => state.dbFinancial,
        dbInformationClassification:state => state.dbInformationClassification,
        dbIntegrity:state => state.dbIntegrity,
        dbLegal:state => state.dbLegal,
        dbLikelihood:state => state.dbLikelihood,
        dbOperations:state => state.dbOperations,
        dbOrganization:state => state.dbOrganization,
        dbPriority:state => state.dbPriority,
        dbProcessCriticality:state => state.dbProcessCriticality,
        dbSecurityCategory:state => state.dbSecurityCategory,
        dbSeverity:state => state.dbSeverity,
        dbTechnical:state => state.dbTechnical,
        dbOverAllImpact:state => state.dbOverAllImpact
    },
})
// async function getRolesInfo(){
//   return new Promise((resolve, reject) => {
//     axios({ 
//     url: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RISK_API_PATH}/api/root/roles/master`,
//     method: 'GET',
//  })
//     .then(resp => {
//       dispatch('selectedRoles', resp);
//         console.log('response in store',resp);
//     })
//     .catch(err => {
//         console.log('error in state',err);
//     })
// })
// }

