<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="row w-full flex justify-between">
            <h1 class="pt-1">Label Definitions</h1>
            <div class="flex items-center">
                <button class="ml-3 flex" :class="labelDefinitions.length == 0 && selectedTabObj.id != 20406 ? 'btndisabled pointer-events-none' : 'filterButton'" @click="filterOpen()">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-3 text-xs">
            <div>
                Data organization is the practice of categorizing and classifying data to make it more usable. Similar to a file folder, where we keep important documents,you'll need to arrange your data in the most logical and orderly fashion,so you -- and anyone else who accesses it --can easily
                find what they're looking for.Data organization is the practice of categorizing and classifyingdata to make it more usable. Similar to a file folder, where we keep important documents ,you'll need to arrange your data in the most logical and orderly fashion.
            </div>
        </div>
        <div>
            <div class="mt-5 px-5 pb-5 bg-white w-full border-lightgrey relative pt-5 rounded">
                <div class="group-buttons" v-if="isSmallScreen">
                    <!-- Group navigation buttons for smaller screens -->
                    <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
                        <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                    </div>
                    <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === tabHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                        <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                    </div>
                </div>
                <!-- <div v-else>
                    Scroll buttons for larger screens
                    <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': !canScrollLeft && !isSmallScreen }">
                        <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                    </div>
                    <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': !canScrollRight && !isSmallScreen }">
                        <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                    </div>
                </div> -->
                <div class="button-adjust flex justify-between items-center w-full">
                    <div class="tab-container flex items-center justify-start gap-1" ref="tabContainer" @scroll="handleScroll">
                        <div class="pb-1 adjust text-xs font-semibold" v-for="(tab, index) in visibleTabs" :key="index" @click="toggleTabs(tab)">
                            <div>
                                <div ref="actualIndex" class="cursor-pointer tab-name p-0.75">{{ tab.displayName }}</div>
                                <div :class="{ progress: selectedTabIndex === tab.id }" class="h-1 p-0.75 relative rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
               <div class="progressbarB rounded h-1"></div>
                <div>
                        
                        <div v-if="selectedTabObj.id == 20406">
                            
                            <div class="font-semibold mt-3">{{ 'Operations' }}</div>
                            <div class="mb-7">
                                <newTable :tableRowData="operationsData" :headersData="opsHeaders"></newTable>
                            </div>

                            <div class="font-semibold mt-3">{{ 'Organisation' }}</div>
                            <div class="mb-7">
                                <newTable :tableRowData="organizationData" :headersData="orgHeaders"></newTable>
                            </div>

                            <div class="font-semibold mt-3">{{ 'Business' }}</div>
                            <div class="mb-7">
                                <newTable :tableRowData="businessData" :headersData="businessHeaders"></newTable>
                            </div>

                            <div class="font-semibold mt-3">{{ 'Techincal' }}</div>
                            <div class="mb-7">
                                <newTable :tableRowData="technicalData" :headersData="technicalHeaders"></newTable>
                            </div>

                            <div class="font-semibold mt-3">{{ 'Financial' }}</div>
                            <div class="mb-7">
                                <newTable :tableRowData="financialData" :headersData="financialHeaders"></newTable>
                            </div>

                            <div class="font-semibold mt-3">{{ 'Legal' }}</div>
                            <div class="mb-7">
                                <newTable :tableRowData="legalData" :headersData="LegalHeaders"></newTable>
                            </div>

                            <div class="font-semibold mt-3">{{ 'OverAll Impact' }}</div>
                            <div class="mb-7">
                                <newTable :tableRowData="overAllData" :headersData="overAllHeaders"></newTable>
                            </div>

                        </div>
                        <div v-else>
                            <newTable :tableRowData="labelDefinitions" :headersData="Headers"></newTable>
                        </div>
                        
                    </div>
                <popup v-if="showPopUp" :popUp="showPopUp">
                    <template v-slot:header>
                        <div class="flex items-center justify-between popupheadingcontainer rounded">
                            <h1 v-if="selectedTabObj.content == 'Impact'">{{ selectedTabObj.content }} - {{ dummylabelobject.value }}</h1>
                            <h1 v-else>{{ selectedTabObj.content }}</h1>
                            <button class="float-right text-3xl" @click="onCancelButtonPopUp('close')">
                                <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                            </button>
                        </div>
                    </template>
                    <fieldset>
                        <div>
                            <div class="popupbodycontainer">
                                <div class="statuscontainer">
                                    <div class="w-6/12">
                                        <div>
                                            <label class="controllabel text-sm">Label</label>
                                            <input
                                                type="text"
                                                class="inputboxstyling"
                                                v-model="labelobject.label"
                                                placeholder="Input field"
                                                disabled
                                                :class="{
                                                    'is-invalid borderRed': v$.labelobject.label.$error
                                                }"
                                            />
                                            <div v-if="v$.labelobject.label.$error" class="text-red-500">
                                                <div class="error-text" v-if="v$.labelobject.label.required.$invalid">Required</div>
                                                <div v-else-if="v$.labelobject.label.required && v$.labelobject.label.validation2.$invalid" class="invalid-feedback">2logic</div>
                                                <div v-else-if="v$.labelobject.label.required && v$.labelobject.label.validation20.$invalid" class="invalid-feedback">20logic</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing">
                                    <div>
                                        <label class="controllabel text-sm">Description</label>
                                        <textarea disabled class="inputboxstyling" v-model="labelobject.description" placeholder="Input field to explain about this label" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="buttonposition flex justify-center items-center mt-2 mb-4 mx-0">
                            <button class="mr-1.5" :class="JSON.stringify(labelobject) == JSON.stringify(dummylabelobject) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="resetlabeldata()">Reset</button>
                            <button :class="JSON.stringify(labelobject) == JSON.stringify(dummylabelobject) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="savelabeldata()">Save</button>
                        </div> -->
                    </fieldset>
                </popup>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import newTable from '@/components/newTable.vue';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { emitter, toast } from '@/main';
import popup from '@/components/popup.vue';
import store from '@/store';
import { required, maxLength, minLength, email, helpers, requiredIf } from '@vuelidate/validators';
const validation2 = helpers.regex(/^[a-zA-Z0-9\s]{1,2}$/);
const validation20 = helpers.regex(/^[a-zA-Z0-9\s]{1,20}$/);

export default defineComponent({
    data(): any {
        return {
            showFilters: false,
            Headers: [],
            selectedTabObj: { displayName: 'Confidentiality', id: 20401, content: 'Confidentiality' },
            selectedTabIndex: 1,
            v$: useVuelidate(),
            labelobject: {
                label: '',
                description: ''
            },
            dummylabelobject: {},
            filteredInformations: [],
            labelDefinitions: [],
            showPopUp: false,
            openPopup: '',
            opsHeaders: ['opsLabel', 'opsValue', 'description'],
            orgHeaders: ['orgLabel', 'orgValue', 'description'],
            businessHeaders: ['businessLabel', 'businessValue', 'description'],
            financialHeaders: ['finLabel', 'finValue', 'description'],
            LegalHeaders: ['legalLabel', 'legalValue', 'description'],
            technicalHeaders: ['techLabel', 'techValue', 'description'],
             overAllHeaders:['overAllLabel', 'overAllValue', 'description'],
            headersArray: [
                {
                    id: 20401,
                    headers: ['confLabel', 'confValue', 'description']
                },
                {
                    id: 20402,
                    headers: ['intLabel', 'intValue', 'description']
                },
                {
                    id: 20403,
                    headers: ['avaiLabel', 'avaiValue', 'description']
                },
                {
                    id: 20404,
                    headers: ['scLabel', 'scValue', 'description']
                },
                {
                    id: 20405,
                    headers: ['likeLabel', 'likeValue', 'description']
                },
                {
                    id: 20406,
                    headers: ['opsLabel', 'opsValue', 'description']
                },
                {
                    id: 20407,
                    headers: ['orgLabel', 'orgValue', 'description']
                },
                {
                    id: 20408,
                    headers: ['businessLabel', 'businessValue', 'description']
                },
                {
                    id: 20409,
                    headers: ['techLabel', 'techValue', 'description']
                },
                {
                    id: 20410,
                    headers: ['finLabel', 'finValue', 'description']
                },
                {
                    id: 20411,
                    headers: ['legalLabel', 'legalValue', 'description']
                },
                {
                    id: 20412,
                    headers: ['sevLabel', 'severitypreview', 'description']
                },
                {
                    id: 20413,
                    headers: ['prioLabel', 'prioritypreview', 'description']
                },
                {
                    id: 20414,
                    headers: ['infoClassLabel', 'infoClassValue', 'description']
                },
                {
                    id: 20415,
                    headers: ['proCLabel', 'proCValue', 'description']
                },
                {
                    id: 20416,
                    headers: ['overAllLabel', 'overAllValue', 'description']
                }
            ],
            tabHead: [
                { displayName: 'Confidentiality', id: 20401, content: 'Confidentiality' },

                { displayName: 'Integrity', id: 20402, content: 'Integrity' },

                { displayName: 'Availability', id: 20403, content: 'Availability' },

                { displayName: 'Security Category', id: 20404, content: 'Security Category' },

                { displayName: 'Likelihood', id: 20405, content: 'Likelihood' },

                { displayName: 'Impact', id: 20406, content: 'Impact' },

                { displayName: 'Severity', id: 20412, content: 'Severity' },

                { displayName: 'Priority', id: 20413, content: 'Priority' },

                { displayName: 'Information Classification', id: 20414, content: 'Information Classification' },

                { displayName: 'Process Criticality', id: 20415, content: 'Process Criticality' },

                // { displayName: 'Overall Impact', id: 20416, content: 'OverAll Impact' }
            ],
            operationsData: [],
            organizationData: [],
            businessData: [],
            technicalData: [],
            legalData: [],
            financialData: [],
            overAllData:[],
            visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0 // Total number of groups for small screens
        };
    },
    validations() {
        return {
            labelobject: {
                label: {
                    required,
                    validation2: requiredIf(() => {
                        return this.selectedTabIndex == 20413;
                    }),
                    validation20: requiredIf(() => {
                        return this.selectedTabIndex != 20413;
                    })
                },
                description: {
                    required
                }
            }
        };
    },
    components: {
        newTable,
        popup
    },
    methods: {
        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
        async toggleTabs(obj: any) {
            this.selectedTabIndex = obj.id;
            this.selectedTabObj = obj;

            if (this.selectedTabIndex == 20406) {
                await this.getLabelEntityData(20406);
                await this.getLabelEntityData(20407);
                await this.getLabelEntityData(20408);
                await this.getLabelEntityData(20409);
                await this.getLabelEntityData(20410);
                await this.getLabelEntityData(20411);
                await this.getLabelEntityData(20416);
            } else {
                await this.getLabelEntityData(this.selectedTabIndex);
            }

            this.showFilters = false;
            emitter.emit('visible', this.showFilters);
        },
        onCancelButtonPopUp() {
            this.showPopUp = false;
            this.labelobject = {};
        },
        resetlabeldata() {
            this.labelobject = { ...this.dummylabelobject };
        },
        async savelabeldata() {
            this.v$.$touch();
            if (!this.v$.$invalid) {
                let payload: any = {
                    key: this.labelobject.label,
                    value: this.labelobject.value,
                    description: this.labelobject.description
                };

                try {
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/label/${this.labelobject.labelTypeId}/update`, payload).then(async (result: any) => {
                        toast.info('Updated', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                        await this.getLabelEntityData(this.labelobject.labelTypeId);
                        //updating store after updating the labels
                        await store.dispatch('getLabelsInfo');
                        this.showPopUp = false;
                        this.labelobject = {};
                    });
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            } else {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        onclickTableRowData(Data: any){
            let findHeaders:any = (this.headersArray.find((obj:any)=>{return obj.id == Data.labelTypeId})).headers;
            let key1 = findHeaders[0];
            let key2 = findHeaders[1];

            let obj = {
                label: Data[key1],
                value: Data[key2],
                description: Data.description,
                labelTypeId: Data.labelTypeId
            };

            this.labelobject = { ...obj };
            this.dummylabelobject = { ...obj };
            this.showPopUp = true;
        },
        async getLabelEntityData(labelEntityId: any) {
            let finalArray: any = [];
            this.labelDefinitions = [];

            let findHeaders: any = this.headersArray.find((obj: any) => {
                return obj.id == labelEntityId;
            });
            console.log('iudciuennonciosn', findHeaders);
            this.Headers = findHeaders.headers;

            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/label/${labelEntityId}/getall`).then((result: any) => {
                console.log('res.data!!!!!!!!!!!', result.data);
                result.data[0].values.map((dbObj: any) => {
                    let sendObj: any = {};

                    sendObj = {
                        _id: dbObj._id,
                        [findHeaders.headers[0]]: dbObj.key,
                        [findHeaders.headers[1]]: dbObj.value,
                        description: dbObj.description,
                        labelTypeId: labelEntityId
                    };

                    finalArray.push(sendObj);
                });
            });

            if (labelEntityId == 20406) {
                this.operationsData = [...finalArray];
            } else if (labelEntityId == 20407) {
                this.organizationData = [...finalArray];
            } else if (labelEntityId == 20408) {
                this.businessData = [...finalArray];
            } else if (labelEntityId == 20409) {
                this.technicalData = [...finalArray];
            } else if (labelEntityId == 20410) {
                this.financialData = [...finalArray];
            } else if (labelEntityId == 20411) {
                this.legalData = [...finalArray];
            } else if(labelEntityId==20416){
                this.overAllData=[...finalArray];
            } 
            else {
                this.labelDefinitions = [...finalArray];
            }
            
        },
        updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
        handleResize() {
            this.isSmallScreen = window.innerWidth <= 768;
            if (this.isSmallScreen) {
                this.calculateTabsPerGroup();
                if (this.currentGroupIndex > this.totalGroups - 1) {
                    this.scrollTabs('left');
                }
            } else {
                this.tabsPerGroup = this.calculateTabsForLargeScreen();
                this.updateVisibleTabs();
            }
            this.updateButtonStates();
        },
        calculateTabsPerGroup() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            let sumWidth = 0;
            let count = 0;
            const tabs = this.tabHead;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            this.tabsPerGroup = count;
            this.updateVisibleTabs();
        },
        calculateTabsForLargeScreen() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            const tabs = this.tabHead;
            let sumWidth = 0;
            let count = 0;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            return count;
        },
        getTabWidth(tab: any) {
            // Simulate tab width calculation; replace with your actual logic
            const dummyDiv = document.createElement('div');
            dummyDiv.innerHTML = tab.displayName;
            dummyDiv.style.display = 'inline-block';
            dummyDiv.style.visibility = 'hidden';
            document.body.appendChild(dummyDiv);
            const width = dummyDiv.clientWidth;
            document.body.removeChild(dummyDiv);
            return width + 4; // Adjust padding or margin as needed
        },
        updateVisibleTabs() {
            if (!this.isSmallScreen) {
                this.visibleTabs = [...this.tabHead];
            } else {
                const startIdx = this.currentGroupIndex * this.tabsPerGroup;
                const endIdx = startIdx + this.tabsPerGroup;
                this.visibleTabs = this.tabHead.slice(startIdx, endIdx);
                if(this.visibleTabs[0])
                {
                    this.selectedTabIndex = this.visibleTabs[0].id;
                    this.toggleTabs(this.visibleTabs[0]);
                }

                this.totalGroups = Math.ceil(this.tabHead.length / this.tabsPerGroup);
            }
            this.updateButtonStates();
        },
        scrollTabs(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    const scrollAmount = this.calculateScrollAmount(direction);
                    if (direction === 'left') {
                        tabContainer.scrollLeft -= scrollAmount;
                    } else if (direction === 'right') {
                        tabContainer.scrollLeft += scrollAmount;
                    }
                    this.updateButtonStates();
                } else {
                    if (direction === 'left' && this.currentGroupIndex > 0) {
                        this.currentGroupIndex--;
                    } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                        this.currentGroupIndex++;
                    }
                    this.updateVisibleTabs();
                    this.updateButtonStates(); // Update button states after changing currentGroupIndex
                }
            }
        },
        calculateScrollAmount(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            const visibleTabs = this.visibleTabs;
            let sumWidth = 0;
            let scrollAmount = 0;

            if (direction === 'left') {
                for (let i = visibleTabs.length - 1; i >= 0; i--) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            } else if (direction === 'right') {
                for (let i = 0; i < visibleTabs.length; i++) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            }
            return scrollAmount;
        },
        scrollGroups(direction: any) {
            if (direction === 'left' && this.currentGroupIndex > 0) {
                this.currentGroupIndex--;
            } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                this.currentGroupIndex++;
            }
            this.updateVisibleTabs();
            this.updateButtonStates(); // Update button states after changing currentGroupIndex
        },
        handleScroll() {
            this.updateButtonStates();
        }
    },
    async created() {
        await this.toggleTabs({ displayName: 'Confidentiality', id: 20401, content: 'Confidentiality' });
    },
    async mounted() {
        this.visibleTabs = [...this.tabHead];
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); // Initial setup
        this.updateButtonStates(); // Initial update to button states
        emitter.off('rowData');
        emitter.on('rowData', (data: any) => {
            this.onclickTableRowData(data);
            console.log('HI ABHIRAJM',data)
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
});
</script>
 <style scoped>
.progress {
    background: #152a43;
    top: 2px;
}
.progressbarB {
    border: 2px solid #e9e9e9;
    background: #e9e9e9;
    position: relative;
    top: -6px;
}
</style>
 


 