

import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import store from "@/store";
import defaultLayout from "@/layouts/defaultLayout.vue";
import emptyLayout from "@/layouts/emptyLayout.vue";
import adminlayout from "@/layouts/adminLayout.vue";
import information from "@/pages/root-admin/information.vue";
import threat from "@/pages/root-admin/threat.vue";
// import client from "@/pages/client.vue";
import bussiness from "@/pages/client-admin/bussiness.vue";

import tvmapping from "@/pages/root-admin/riskThreatMaster.vue";
// import assesments from "@/pages/client-admin/assesment.vue";
import risks from "@/pages/client-admin/risks.vue";
import riskAcceptance from "@/pages/client-admin/riskAcceptance.vue";
// import mapping from "@/pages/client-admin/mapping.vue";
import VulnerabilitiesListing from "@/pages/root-admin/vulnerabilities.vue";
import system from "@/pages/client-admin/systems.vue"

import { securityOfficerNavigationGuard } from "./navGuard"
import { RiskAndSystemOwnersNavigationGuard } from "./navGuard"
import systemInformation from "@/pages/client-admin/systemInformation.vue";
import myTasks from "@/pages/client-admin/myTasks.vue";
import approvals from "@/pages/client-admin/approvals.vue";
import reports from "@/pages/client-admin/reports.vue";
import taskEditPage from "@/pages/client-admin/taskEdit.vue";

import tierRisks from "@/pages/client-admin/tierRisks.vue";
import worklog from "@/pages/client-admin/workLog.vue";
import systemsControlsMap from "@/pages/client-admin/systemsControlsMap.vue";
import dashboard from "@/pages/client-admin/dashboard.vue";
import residualRisk from '@/pages/client-admin/residualRisk.vue';
import inActiveRisks from '@/pages/client-admin/inActiveRisks.vue';
import reportCenter from '@/pages/client-admin/reportCenter.vue';
import businessProcess from '../pages/client-admin/businessProcess.vue';
import organization from '../pages/client-admin/organization.vue';
import processInformation from '../pages/client-admin/processInformation.vue';
import organizationInformation from '../pages/client-admin/organizationInformation.vue';
import orgRiskMaster from '../pages/root-admin/orgRiskMaster.vue';
import processRisksRoot from '../pages/root-admin/masterProcessRisks.vue';
import informationRisks from '../pages/client-admin/informationRisks.vue';
import informationMapping from '../pages/client-admin/informationMapping.vue';
import cloner from '../pages/client-admin/cloner.vue';
import infoClassifications from '../pages/client-admin/infoClassifications.vue';
import infoClassificationProperties from '../pages/client-admin/infoClassificationProperties.vue';
import infoClassificationMap from '../pages/client-admin/infoClassificationMap.vue';
import labelDefinitions from '../pages/client-admin/labelDefinitions.vue'




const adminRoutes: Array<RouteRecordRaw> = [
    {
        path: "/root/threat",
        name: "threat",
        meta: {
            title: "Threat",
            layout: adminlayout,
        },
        component: threat,


    },

    {
        path: "/tvmapping",
        name: "tvmapping",
        meta: {
            title: "Risk-Master",
            layout: adminlayout,
        },
        component: tvmapping,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   },

    },

    {
        path: "/information",
        name: "information",
        meta: {
            title: "Information",
            layout: adminlayout,
        },
        component: information,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   },

    },
    {
        path: "/root/vulnerabilities",
        name: "vulnerabilities",
        meta: {
            title: "Vulnerabilities",
            layout: adminlayout,
        },
        component: VulnerabilitiesListing,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   },

    },
    {
        path: "/processRisks",
        name: "processRisksRoot",
        meta: {
            title: "processRisksRoot",
            layout: adminlayout,
        },
        component: processRisksRoot,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   },

    },
    {
        path: "/orgRiskMaster",
        name: "orgRiskMaster",
        meta: {
            title: "Entity Risk Master",
            layout: adminlayout,
        },
        component: orgRiskMaster,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   },

    },

]
const orgroutes: Array<RouteRecordRaw> = [
    {
        path: "/org/systems",
        name: "systems",
        meta: {
            title: "Systems",
            layout: defaultLayout,
        },
        component: system,

        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // }
    },
    {
        path: "/org/risk/:riskId/mappedRisk/:mappedRisk/worklog/:systemId",
        name: "worklogFromRisk",
        meta: {
            title: "Worklog",
            layout: defaultLayout,
        },
        component: worklog,

        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/risk/:riskId/worklog/org/:orgId",
        name: "worklogFromOrganization",
        meta: {
            title: "Worklog",
            layout: defaultLayout,
        },
        component: worklog,

        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/risk/:riskId/worklog/process/:processId",
        name: "worklogFromProcess",
        meta: {
            title: "Worklog",
            layout: defaultLayout,
        },
        component: worklog,

        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/risk/:riskId/worklog/system/:systemId",
        name: "worklogFromSystem",
        meta: {
            title: "Worklog",
            layout: defaultLayout,
        },
        component: worklog,

        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/labelDefinitions",
        name: "labelDefinitions",
        meta: {
            title: "labelDefinitions",
            layout: defaultLayout,
        },
        component: labelDefinitions,

        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },


    {
        path: "/org/myTasks",
        name: "myTasks",
        meta: {
            title: "My-Tasks",
            layout: defaultLayout,
        },
        component: myTasks,

        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/approvals",
        name: "approvals",
        meta: {
            title: "Approvals",
            layout: defaultLayout,
        },
        component: approvals,

        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/reportCenter",
        name: "reportCenter",
        meta: {
            title: "reportCenter",
            layout: defaultLayout,
        },
        component: reportCenter,
        beforeEnter: (to, from, next) => {
            securityOfficerNavigationGuard(to, from, next)
        },
        // beforeEnter: (to, from, next) => {
        // adminNavigationGuard(to, from, next)
        //   },

    }, {
        path: "/org/reports",
        name: "reports",
        meta: {
            title: "Reports",
            layout: defaultLayout,
        },
        component: reports,
        beforeEnter: (to, from, next) => {
            securityOfficerNavigationGuard(to, from, next)
        },
        // beforeEnter: (to, from, next) => {
        // adminNavigationGuard(to, from, next)
        //   },

    },
    {
        path: "/org/task/:taskId/edit",
        name: "taskEditPage",
        meta: {
            title: "Risk-Name",
            layout: defaultLayout,
        },
        component: taskEditPage,

        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },

    {
        path: "/org/system/:id?/systemInformation",
        name: "edit-system",
        meta: {
            title: "Edit-System",
            layout: defaultLayout,
        },
        component: systemInformation,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/information",
        name: "orginformation",
        meta: {
            title: "Information",
            layout: defaultLayout,
        },
        component: information,

    },
    {
        path: "/org/threat",
        name: "orgthreat",
        meta: {
            title: "Threat",
            layout: defaultLayout,
        },
        component: threat,


    },
    {
        path: "/org/vulnerabilities",
        name: "orgvulnerabilities",
        meta: {
            title: "Vulnerabilities",
            layout: defaultLayout,
        },
        component: VulnerabilitiesListing,
    },
    {
        path: "/org/tvmapping",
        name: "orgtvmapping",
        meta: {
            title: "tvmapping",
            layout: defaultLayout,
        },
        component: tvmapping,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/business",
        name: "business",
        meta: {
            title: "Business",
            layout: defaultLayout,
        },
        component: bussiness,

    },
    {
        path: "/org/businessProcess",
        name: "businessProcess",
        meta: {
            title: "BusinessProcess",
            layout: defaultLayout,
        },
        component: businessProcess,

    },
    {
        path: "/org/process/:processId/processInformation",
        name: "processInformation",
        meta: {
            title: "processInformation",
            layout: defaultLayout,
        },
        component: processInformation,
    },
    {
        path: "/org/informations/classification/:classificationType/map",
        name: "infoClassificationMap",
        meta: {
            title: "infoClassificationMap",
            layout: defaultLayout,
        },
        component: infoClassificationMap,

    },
    {
        path: "/org/risks",
        name: "risks",
        meta: {
            title: "risks",
            layout: defaultLayout,
        },
        component: risks,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },


    },
    {
        path: "/org/organization",
        name: "organization",
        meta: {
            title: "organization",
            layout: defaultLayout,
        },
        component: organization,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },
    },
    {
        path: "/org/organization/:orgId/organizationInformation",
        name: "organizationInformation",
        meta: {
            title: "organizationInformation",
            layout: defaultLayout,
        },
        component: organizationInformation,
    },
    {
        path: "/org/classifications",
        name: "infoClassifications",
        meta: {
            title: "infoClassifications",
            layout: defaultLayout,
        },
        component: infoClassifications,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/informations/classification/:classificationType",
        name: "infoClassificationProperties",
        meta: {
            title: "infoClassificationProperties",
            layout: defaultLayout,
        },
        component: infoClassificationProperties,

    },
    {
        path: "/org/dashboard",
        name: "dashboard",
        meta: {
            title: "dashboard",
            layout: defaultLayout,
        },
        component: dashboard,
        beforeEnter: (to, from, next) => {
            securityOfficerNavigationGuard(to, from, next)
        },

    },
    {
        path: "/org/risk/:riskId/Acceptance",
        name: "riskAcceptance",
        meta: {
            title: "Acceptance",
            layout: defaultLayout,
        },
        component: riskAcceptance,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/risk/:riskId/ControlsMap/risks",
        name: "systemsControlsMap",
        meta: {
            title: "SystemsControlsMap",
            layout: defaultLayout,
        },
        component: systemsControlsMap,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },
    },
    {
        path: "/org/risk/:riskId/:type/:id/ControlsMap",
        name: "systemsControlsMapWithId",
        meta: {
            title: "SystemsControlsMap",
            layout: defaultLayout,
        },
        component: systemsControlsMap,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },
    },
    {
        path: "/org/risk/:riskId/:type/:id/ControlsMap/residualRisk",
        name: "residualRisk",
        meta: {
            title: "residualRisk",
            layout: defaultLayout,
        },
        component: residualRisk,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },
    },
    {
        path: "/org/risk/:riskId/reset",
        name: "riskReset",
        meta: {
            title: "Reset",
            layout: defaultLayout,
        },
        component: riskAcceptance,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },
    },
    {
        path: "/org/risk/:riskId/mitigate",
        name: "riskMitigate",
        meta: {
            title: "Mitigate",
            layout: defaultLayout,
        },
        component: riskAcceptance,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/risk/:riskId/priority",
        name: "riskPriority",
        meta: {
            title: "Priority",
            layout: defaultLayout,
        },
        component: riskAcceptance,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/risk/:riskId/delete",
        name: "riskDelete",
        meta: {
            title: "Delete",
            layout: defaultLayout,
        },
        component: riskAcceptance,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/risks/inActive",
        name: "inActiveRisks",
        meta: {
            title: "InActiveRisks",
            layout: defaultLayout,
        },
        component: inActiveRisks,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/risk/:riskId/:type",
        name: "tierRisks",
        meta: {
            title: "Tier-Risks",
            layout: defaultLayout,
        },
        component: tierRisks,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/information/:informationId/risks",
        name: "informationRisks",
        meta: {
            title: "Info-Risks",
            layout: defaultLayout,
        },
        component: informationRisks,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/information/mapping",
        name: "informationMapping",
        meta: {
            title: "Info-Map",
            layout: defaultLayout,
        },
        component: informationMapping,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },
    {
        path: "/org/risk/:mappedRisk/:riskId/clone/:type",
        name: "cloner",
        meta: {
            title: "Risk-Clone",
            layout: defaultLayout,
        },
        component: cloner,
        // beforeEnter: (to, from, next) => {
        //     RiskAndSystemOwnersNavigationGuard(to, from, next)
        // },

    },

]

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        meta: {
            title: "Dashboard",
            layout: defaultLayout,
        },
        redirect: { name: 'dashboard' }
    },
    {
        path: '/admin',
        meta: {
            title: "Information",
            layout: adminlayout,
        },
        redirect: { name: 'information' }
    },
    ...adminRoutes,
    ...orgroutes

]
const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach(async (to, from, next) => {
    store.state.isLoading = true;
    document.title = `Risk - ${to.meta.title}`
    console.log('Router check triggered', to)
    if (to.path == '/admin' || to.path == '/' || to.path == '/org/business' || to.path == '/org/dashboard') {
        console.log('Nav to default route with', to.query.session)
        if (to.query.session) {
            localStorage.setItem('AUTH_ID', to.query.session.toString());
            await store.dispatch('getUserInfo').then((res) => {
                console.log('response', res.data[0]);
                if (!res.data[0]) window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_RISK_WEBAPP_URL}`;
                else {
                    next();
                }
            })
        }
        await normalAuthenticationCheck(next);
    } else {
        await normalAuthenticationCheck(next);
    }
    // next();
});

router.afterEach(async (to, from, next) => {
    store.state.isLoading = false
});


async function normalAuthenticationCheck(next: any) {
    console.log('Inside normal check')
    if (!localStorage.getItem('AUTH_ID')) {
        console.log('AUTH ID NOT found')
        window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_RISK_WEBAPP_URL}`;
    } else {
        const userInfo = store.getters.userInfo;
        console.log('USER INFO', userInfo)
        if (!userInfo.roles) {
            await store.dispatch('getUserInfo').then((res) => {
                next();
            });
        }
        else {
            next();
        }
    }
}



export default router;
