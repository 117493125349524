<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Deleted Risks</h1>
            <div class="cardbody">
                <div class="flex flex-row-reverse items-center">
                    <div class="flex flex-row-reverse items-center">
                        <button class="flex" @click="filterOpen" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 text-xs">
            <div>
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
            </div>
        </div>
        <newTable :tableRowData="tableRow" :headersData="riskHeaders"></newTable>
        <div v-if="openPopup">
            <popup>
                <template v-slot:header>
                    <div class="flex items-center justify-between py-4 px-2 rounded">
                        <h1 class="pl-2">Risk Information</h1>
                        <button class="float-right text-3xl" @click="onCrossbtn()">
                            <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                        </button>
                    </div>
                </template>
                <fieldset>
                    <div class="popup-business">
                        <div class="statuscontainer p-2">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full">
                                    <div class="p-2 w-full">
                                        <label for="Risk" class="controllabel">Risk</label>
                                        <div class="text-sm">{{riskThreatData.risk}}</div>
                                        <!-- <textarea class="inputboxstyling" placeholder="Risk" v-model="riskThreatData.risk" /> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row flex flex-row w-full">
                                <div class="w-3/6 p-2">
                                    <!-- {{riskCategoryList}} -->
                                    <div>
                                        <!-- {{riskCategoryList}} -->
                                        <label class="controllabel">Risk Category</label>
                                        <div class="text-sm">{{riskThreatData.riskCategory}}</div>
                                        <!-- <input type="text" class="inputboxstyling" placeholder="Risk Category"  v-model="riskThreatData.riskCategory"
                                            :options="riskCategoryList"> -->
                                        <!-- <Multiselect
                                            v-model="riskThreatData.riskCategory"
                                            :options="riskCategoryList"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Risk Category"
                                        ></Multiselect> -->
                                    </div>
                                </div>
                                <div class="w-3/6 p-2">
                                    <div>
                                        <label class="controllabel">Risk Owner</label>
                                        <div class="text-sm">{{ optionsUserArray.find(option => option.value === riskThreatData.riskOwner)?.label || "-" }}</div>
                                        <!-- <input class="inputboxstyling" :value="riskThreatData.riskOwner"/> -->
                                         <!-- <select class="inputboxstyling" v-model="riskThreatData.riskOwner">
                                            <option value="" selected hidden>Please select one</option>
                                            <option v-for="option in optionsUserArray" :key="option.value" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </select> -->


                                        <!-- <Multiselect
                                            v-model="riskThreatData.riskOwner"
                                            :options="optionsUserArray"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Risk owner"
                                        ></Multiselect> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row flex flex-row w-full">
                                <div class="w-full p-2">
                                    <label for="Risk" class="controllabel">Threat</label>
                                    <div class="text-sm">{{riskThreatData.threatEvent}}</div>
                                    <!-- <textarea class="inputboxstyling" placeholder="Threat" v-model="riskThreatData.threatEvent" /> -->
                                </div>
                            </div>
                            <div class="row flex flex-row w-full">
                                <div class="w-3/6 p-2">
                                    <div>
                                        <label class="controllabel">Threat Source</label>
                                        <div class="text-sm">{{riskThreatData.threatSource}}</div>
                                         <!-- <input type="text" class="inputboxstyling" placeholder="Threat Source"  v-model="riskThreatData.threatSource"
                                            :options="threatSourceList"> -->
                                        <!-- <Multiselect
                                            v-model="riskThreatData.threatSource"
                                            :options="threatSourceList"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Threat Source"
                                        ></Multiselect> -->
                                    </div>
                                </div>
                                <div class="w-3/6 p-2">
                                    <div>
                                        <label class="controllabel">Threat Category</label>
                                        <div class="text-sm">{{riskThreatData.threatCategory}}</div>
                                        <!-- <input type="text" class="inputboxstyling" placeholder="Threat Category"  v-model="riskThreatData.threatCategory"
                                            :options="threatCategoryList"> -->
                                        <!-- <Multiselect
                                            v-model="riskThreatData.threatCategory"
                                            :options="threatCategoryList"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Threat category"
                                        ></Multiselect> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row flex flex-row w-full">
                                <div class="w-full p-2">
                                    <label for="Risk" class="controllabel">Vulnerability</label>
                                    <div class="text-sm">{{riskThreatData.vulnerability}}</div>
                                    <!-- <textarea class="inputboxstyling" placeholder="Vulnerability" v-model="riskThreatData.vulnerability" /> -->
                                </div>
                            </div>
                            <h1 class="pl-2 mt-3">Impact Analysis</h1>
                            <div class="border-b mt-4 mb-3 border-solid border-lightgrey"></div>
                            <div class="row flex flex-row w-full">
                                <div class="w-6/12 p-2">
                                    <label class="controllabel">Business</label>
                                    <div class="text-sm">{{ dbBussiness.find(option => option.value === riskThreatData.business)?.key || "-" }}</div>
                                    <!-- <select class="inputboxstyling" v-model="riskThreatData.business">
                                        <option value="" selected hidden>Please select one</option>
                                        <option v-for="option in dbBussiness" :key="option" :value="option.value">
                                            {{ option.key }}
                                        </option>
                                    </select> -->
                                </div>
                                <div class="w-6/12 p-2">
                                    <label class="controllabel">Organization</label>
                                    <div class="text-sm">{{ dbOrganization.find(option => option.value === riskThreatData.organization)?.key || "-" }}</div>
                                    <!-- <select class="inputboxstyling" v-model="riskThreatData.organization">
                                        <option value="" selected hidden>Please select one</option>
                                        <option v-for="option in dbOrganization" :key="option" :value="option.value">
                                            {{ option.key }}
                                        </option>
                                    </select> -->
                                </div>
                            </div>
                            <div class="row flex flex-row w-full">
                                <div class="w-6/12 p-2">
                                    <label class="controllabel">Operations</label>
                                    <div class="text-sm">{{ dbOperations.find(option => option.value === riskThreatData.operations)?.key || "-" }}</div>
                                    <!-- <select class="inputboxstyling" v-model="riskThreatData.operations">
                                        <option value="" selected hidden>Please select one</option>
                                        <option v-for="option in dbOperations" :key="option" :value="option.value">
                                            {{ option.key }}
                                        </option>
                                    </select> -->
                                </div>
                                <div class="w-6/12 p-2">
                                    <label class="controllabel">Legal</label>
                                    <div class="text-sm">{{ dbLegal.find(option => option.value === riskThreatData.legal)?.key || "-" }}</div>
                                    <!-- <select class="inputboxstyling" v-model="riskThreatData.legal">
                                        <option value="" selected hidden>Please select one</option>
                                        <option v-for="option in dbLegal" :key="option" :value="option.value">
                                            {{ option.key }}
                                        </option>
                                    </select> -->
                                </div>
                            </div>
                            <div class="row flex flex-row w-full">
                                <div class="w-6/12 p-2">
                                    <label class="controllabel">Financial</label>
                                    <div class="text-sm">{{ dbFinancial.find(option => option.value === riskThreatData.financial)?.key || "-" }}</div>
                                    <!-- <select class="inputboxstyling" v-model="riskThreatData.financial">
                                        <option value="" selected hidden>Please select one</option>
                                        <option v-for="option in dbFinancial" :key="option" :value="option.value">
                                            {{ option.key }}
                                        </option>
                                    </select> -->
                                </div>
                                <div class="w-6/12 p-2">
                                    <label class="controllabel">Technical</label>
                                    <div class="text-sm">{{ dbTechnical.find(option => option.value === riskThreatData.technical)?.key || "-" }}</div>
                                    <!-- <select class="inputboxstyling" v-model="riskThreatData.technical">
                                        <option value="" selected hidden>Please select one</option>
                                        <option v-for="option in dbTechnical" :key="option" :value="option.value">
                                            {{ option.key }}
                                        </option>
                                    </select> -->
                                </div>
                            </div>
                            <div class="border-b mt-4 mb-3 border-solid border-lightgrey"></div>
                            <div class="row flex flex-row w-full">
                                <div class="w-full p-2">
                                    <label class="controllabel">Likelihood</label>
                                    <div class="text-sm">{{ dbLikelihood.find(option => option.value === riskThreatData.likelihood)?.key || "-" }}</div>
                                    <!-- <select class="inputboxstyling" v-model="riskThreatData.likelihood">
                                        <option value="" selected hidden>Please select one</option>
                                        <option v-for="option in dbLikelihood" :key="option" :value="option.value">
                                            {{ option.key }}
                                        </option>
                                    </select> -->
                                </div>
                            </div>
                            <!-- <div class="flex mt-2 justify-center">
                        <button class="btnprimary mr-2 gap-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelRiskData()">Cancel</button>
                        <button class="btnprimary mr-1.5" v-if="addingRisk" @click="addOrUpdaterisk(riskThreatData)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Add</button>
                        <button class="btnprimary mr-1.5" v-else @click="addOrUpdaterisk(riskThreatData)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                    </div> -->
                        </div>
                    </div>
                </fieldset>
            </popup>
        </div>
    </div>
</template>
<style scoped>
.tip {
    display: inline;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import newTable from '@/components/newTable.vue';
import { emitter, toast } from '@/main';
import popup from '@/components/popup.vue';
import moment from 'moment';

import { mapGetters } from 'vuex';

export default defineComponent({
    components: {
        newTable,
        popup,
        // Multiselect
    },
    data(): any {
        return {
            dataRow: {},
            threatCategoryList: [],
            threatSourceList: [],
            riskCategoryList: [],
            optionsUserArray: [],
            openPopup: false,
            riskList: [],
            systems: [],
            // likelihoodMapping: [],
            showFilters: false,
            filterDummy: {
                statusCode: ''
            },
            // businessProcessList: [],
            entitiesSelect: [],
            dummyList: [],
            openList: null,
            tasksData: [],
            tableRow: [],
            riskHeaders: ['severity', 'tier', 'name', 'risk', 'first Identified', 'last Reviewed', 'status'],
            riskThreatData: {
                risk: '',
                threatCategory: '',
                threatSource: '',
                threatEvent: '',
                vulnerability: '',
                organization: '',
                business: '',
                operations: '',
                technical: '',
                legal: '',
                financial: '',
                likelihood: '',
                riskCategory: '',
                // overallImpact: '',
                // severity: '',
                riskOwner: ''
            },
            risksValue: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10403,
                    value: 'Accepted'
                },
                {
                    id: 10404,
                    value: 'Deleted'
                },
                {
                    id: 10405,
                    value: 'Mitigated'
                },
                {
                    id: 10406,
                    value: 'Partial'
                }
            ],
            risksSeverityValue: [
                {
                    id: 1,
                    value: 'Very Low'
                },
                {
                    id: 2,
                    value: 'Low'
                },
                {
                    id: 3,
                    value: 'Moderate'
                },
                {
                    id: 4,
                    value: 'High'
                },
                {
                    id: 5,
                    value: 'Very High'
                }
            ]
        };
    },
    computed:{
        ...mapGetters({dbSeverity:'dbSeverity',dbLikelihood:'dbLikelihood',dbBussiness:'dbBussiness',
            dbOrganization:'dbOrganization',dbOperations:'dbOperations',dbLegal:'dbLegal',
            dbFinancial:'dbFinancial',dbTechnical:'dbTechnical'
        }),
    },
    methods: {
        onCrossbtn() {
            this.openPopup = false;
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.riskList;
            } else {
                this.riskList = this.dummyList;
                for (const key in this.filterDummy) {
                    this.filterDummy[key] = '';
                }
            }
            emitter.emit('visible', this.showFilters);
        },
        average(arr: any[]) {
            return arr.reduce((p, c) => p + c, 0) / arr.length;
        },
        riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        navigateWorkLog(presentSystemRiskId: any, mappedRisk: any, systemId: any) {
            console.log('mappedRisk', mappedRisk);
            this.$router.push({ name: 'worklogFromRisk', params: { riskId: presentSystemRiskId, mappedRisk: mappedRisk, systemId: systemId } });
        },
        getDateFormat(date: any) {
            if (date == '') {
                return 'None';
            } else {
                let now = moment(date);
                return now.format('DD-MM-YYYY');
            }
        },
        async getRootRiskList() {
            this.rootRiskList = [];
            this.riskCategoryList = [];
            this.threatCategoryList = [];
            this.threatSourceList = [];

            const result = await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risks/getall`);
            result.data.map((risk: any) => {
                !this.riskCategoryList.includes(risk.riskCategory) ? this.riskCategoryList.push(risk.riskCategory) : '';

                !this.threatCategoryList.includes(risk.threatCategory) ? this.threatCategoryList.push(risk.threatCategory) : '';

                !this.threatSourceList.includes(risk.threatSource) ? this.threatSourceList.push(risk.threatSource) : '';

                if (risk.organization) {
                    let averageImpact = Math.round(this.average([risk.organization, risk.business, risk.operations, risk.technical, risk.legal, risk.financial]));
                    risk.overallImpact = averageImpact;
                    risk.severity = this.riskSeverity(risk.overallImpact * risk.likelihood);
                    // this.rootRisksList.push(risk);
                    this.riskList.push(risk);
                }
            });
        },
        async getRisksList() {
            this.tableRow = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risks/getall?active=false`).then((result: any) => {
                this.riskList = result.data;

                for (let i = 0; i < this.riskList.length; i++) {
                    this.openList = this.riskList[i];
                    let condition1 = Object.keys(this.riskList[i]).includes('residual') && this.riskList[i]['residual'].length > 0;
                    let averageImpact;
                    let impact;
                    let severity;

                    if (condition1 == true) {
                        let lengthOfArray = this.riskList[i]['residual'].length;
                        let indexObj = this.riskList[i]['residual'][lengthOfArray - 1];

                        if (indexObj.isActive == true) {
                            averageImpact = this.average([indexObj['organization'], indexObj['business'], indexObj['operations'], indexObj['technical'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);
                            let arr = ['organization', 'business', 'operations', 'technical', 'legal', 'financial', 'likelihood'];
                            for (let key of arr) {
                                this.riskList[i][key] = indexObj[key];
                            }
                        } else {
                            averageImpact = this.average([this.openList['organization'], this.openList['business'], this.openList['operations'], this.openList['technical'], this.openList['legal'], this.openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * this.openList['likelihood']);
                        }
                    } else if (condition1 == false) {
                        averageImpact = this.average([this.openList['organization'], this.openList['business'], this.openList['operations'], this.openList['technical'], this.openList['legal'], this.openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * this.openList['likelihood']);
                    }
                    this.riskList[i].impact = impact;
                    this.riskList[i].severity = severity;
                    // this.riskList[i].systemName = (this.systems.find((sys:any)=>{return sys._id == this.openList['systemId']})).systemName;
                    let systemsObj: any = false;
                    systemsObj = Object.keys(this.riskList[i]).includes('systemId');
                    let processObj: any = false;
                    processObj = Object.keys(this.riskList[i]).includes('processId');
                    let organizationObj: any = false;
                    organizationObj = Object.keys(this.riskList[i]).includes('orgId');

                    let row = {
                        id: this.riskList[i]._id,
                        severity: this.riskList[i].severity,
                        risk: this.riskList[i].risk,
                        name: '',
                        tier: '',
                        'first Identified': this.getDateFormat(this.riskList[i].createdAt),
                        'last Reviewed': this.getDateFormat(this.riskList[i].updatedAt),
                        status: this.riskList[i].statusCode
                    };
                    if (systemsObj == true) {
                        this.riskList[i].systemName = this.systems.find((obj: any) => this.riskList[i].systemId == obj._id).systemName;
                        (row.name = this.riskList[i].systemName), (row.tier = this.riskList[i].tier);
                    } else if (processObj == true) {
                        this.riskList[i].processName = this.businessProcessList.find((obj: any) => this.riskList[i].processId == obj._id).businessProcessName;
                        (row.name = this.riskList[i].processName), (row.tier = this.riskList[i].tier);
                    } else if (organizationObj == true) {
                        this.riskList[i].organizationName = this.entitiesSelect.find((obj: any) => this.riskList[i].orgId == obj.value).label;
                        (row.name = this.riskList[i].organizationName), (row.tier = this.riskList[i].tier);
                    }
                    this.tableRow.push(row);
                }
            });
            this.dummyList = [...this.riskList];
        },
        async getBusinessEntities() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                result.data.map((org: any) => {
                    this.entitiesSelect.push({ value: org._id, label: org.entityName });
                });
            });
        },
        async getBusinessProcess() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                this.businessProcessList = result.data;
            });
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = result.data;
            });
        },
        async getAllTasks() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tasks/getall`).then((result: any) => {
                this.tasksData = result.data;
            });
        },
        async getallUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.orgUsersList = res.data;
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }
            });
        },
        riskInfo(values: any) {
            console.log('values:', values);
            this.openPopup = true;
            this.riskThreatData = { ...values };
            // Object.assign(this.riskThreatData,{...values})
            console.log('riskthreatdata:', this.riskThreatData);
        }
    },
    async mounted() {
        emitter.on('rowData', (data: any) => {
            console.log('data is ::', data);
            this.dataRow = data;
            let getRiskData = this.riskList.find((obj: any) => {
                return obj._id == this.dataRow.id;
            });
            console.log('get:', getRiskData);
            this.riskInfo(getRiskData);
        });
        await this.getAllTasks();
        await this.getSystems();
        await this.getBusinessProcess();
        await this.getBusinessEntities();
        await this.getRisksList();
        await this.getRootRiskList();
    },
    created() {
        this.getallUsers();
    }
});
</script>

