<template>
    <div class="flexcontainer pt-3 pl-5 pr-5 bg-#f9f9fb">
        <div class="headerDiv mb-2">
            <breadCrumb :routesArray="routesArray" :navIndexNums="navIndexNums"></breadCrumb>
            <div class="row w-full flex justify-between">
                <h1 class="pt-2">Information Classification</h1>
                <div class="flex">
                    <button :class="areArraysIdsEqual ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="mapInformations">Apply</button>

                    <div class="pl-3">
                        <button class="filterButton flex" @click="filterOpen">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-3 text-xs">
                    Data organization is the practice of categorizing and classifying data to make it more usable. Similar to a filie folder, where we keep important documents, you'll need to arrange your data in the most logical and orderly fashion, so you and anyone else who accesses it can easily
                    find what they're looking for. Data organization is the practice of categorizing and classifying data to make mone usable. Similar to a file folder, where we keep important documents, you'll need to arrange your data in the most logical and orderly fashion.
            </div>
        </div>
        
            <div class="bg-white tableCornerRadius">
                <div class="overflow-x-auto">
                <table>
                    <thead>
                        <th class="header cursor-pointer w-8">
                            <!-- <div class="flex items-center">
                                <input type="checkbox" class="checkbox cursor-pointer ml-4" :disabled="sortedData.length == 0" />
                            </div> -->
                        </th>
                        <th class="header cursor-pointer w-36" @click="sort('informationCategory')">
                            <div class="flex items-center">
                                <div>Information Category</div>
                                <div class="sort cursor-pointer" :class="className == 'informationCategory' ? classSort : 'opacity-50'"></div>
                            </div>
                        </th>
                        <th class="header cursor-pointer w-36" @click="sort('information')">
                            <div class="flex items-center">
                                <div>Information</div>
                                <div class="sort cursor-pointer" :class="className == 'information' ? classSort : 'opacity-50'"></div>
                            </div>
                        </th>
                        <th class="header cursor-pointer w-36" @click="sort('securityCategory')">
                            <div class="flex items-center">
                                <div>Security Category</div>
                                <div class="sort cursor-pointer" :class="className == 'securityCategory' ? classSort : 'opacity-50'"></div>
                            </div>
                        </th>
                        <th class="header cursor-pointer w-36" @click="sort('classification')">
                            <div class="flex items-center">
                                <div>Classification</div>
                                <div class="sort cursor-pointer" :class="className == 'classification' ? classSort : 'opacity-50'"></div>
                            </div>
                        </th>
                        <tr v-if="showFilters">
                            <td></td>
                            <td class="w-36">
                                <input type="text" class="inputboxStylingForFilter filter-font-size" placeholder="Search" v-model="filterDummy.informationCategory" />
                            </td>
                            <td class="searchRow">
                                <input type="text" class="inputboxStylingForFilter filter-font-size" placeholder="Search" v-model="filterDummy.information" />
                            </td>
                            <td class="searchRow">
                                <select class="inputboxstyling resize" v-model="filterDummy.securityCategory">
                                    <option value="" selectedInformationIds disabled hidden>Select</option>
                                    <option v-for="pair in dbSecurityCategory" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                </select>
                            </td>
                            <td>
                                <select class="inputboxstyling resize" v-model="filterDummy.classification">
                                    <option value="" selectedInformationIds disabled hidden>Select</option>
                                    <option v-for="pair in dbInformationClassification" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                </select>
                            </td>
                        </tr>
                    </thead>
                    <tbody v-if="sortedData.length == 0">
                        <tr class="cursor-pointer rowHeight">
                            <td :colspan="4">
                                <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else class="nonEmptyTable">
                        <tr v-for="obj in sortedData" :key="obj">
                            <td>
                                <input type="checkbox" v-model="selectedInformationIds" :value="obj._id" :disabled="dummySelectedIds.includes(obj._id)" class="checkbox cursor-pointer ml-4" />
                            </td>
                            <td>{{ obj.informationCategory }}</td>
                            <td>{{ obj.information }}</td>
                            <td>
                                <div class="flex items-center justify-center relative rounded w-28 px-2.5 h-5 regular" :class="colorPriorityText(obj.securityCategory)">
                                    <div class="w-full h-full absolute opacity-10 rounded" :class="colorPriorityBg(obj.securityCategory)"></div>
                                    <div class="flex rounded w-32 items-center justify-center py-1 regular text-xs">
                                        <div class="text-xs">
                                            {{
                                                dbSecurityCategory.find((l) => {
                                                    return l.value == obj.securityCategory;
                                                }).key
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="flex items-center justify-center relative rounded w-28 px-2.5 h-5 regular" :class="colorPriorityText(obj.classification)">
                                    <div class="w-full h-full absolute opacity-10 rounded" :class="colorPriorityBg(obj.classification)"></div>
                                    <div class="flex rounded w-32 items-center justify-center py-1 regular text-xs">
                                        <div class="text-xs">
                                            {{ getClassificationLabel(obj.classification) }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <div v-if="sortedData.length > 0">
                    <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
                </div>
            </div>
        
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter, toast } from '@/main';
import breadCrumb from '@/components/breadCrumb.vue';
import pagination from '@/components/pagination.vue';
import store from '@/store';
import { mapGetters } from 'vuex';

export default defineComponent({
    data(): any {
        return {
            showFilters: false,
            routesArray: [],
            navIndexNums: { name: '', index: '' },
            classificationtype: [{ displayName: 'public', names: 'public', id: 1 }],
            buttonsEnabled: false,
            infoProperties: {
                label: '',
                description: ''
            },
            visibility: false,
            infoPropertiesDummy: {},
            pageClassificationType: '',
            allInformationsJson: [],
            filteredInformations: [],
            selectedInformationIds: [],
            dummySelectedIds: [],
            currentSort: '',
            currentSortDir: 'asc',
            className: '',
            classSort: '',
            currentPage: '1',
            pageSize: '20',
            dynamicPagination: '',
            filterDummy: {
                informationCategory: '',
                information: '',
                securityCategory: '',
                classification: ''
            },
            filters: {},
            propertiesObj: {}
        };
    },
    components: {
        breadCrumb,
        pagination
    },
    computed: {
        ...mapGetters({ userInfo: 'userInfo',dbInformationClassification:'dbInformationClassification',dbSecurityCategory:'dbSecurityCategory'}),

        areArraysIdsEqual: function():any {
            return JSON.stringify(this.selectedInformationIds) === JSON.stringify(this.dummySelectedIds);
        },
        sortedData: function (): any {
            return [...this.allInformationsJson]
                .filter((row: any) => {
                    for (const key in this.filterDummy) {
                        const inputValue = this.filterDummy[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
    },
    async created() {
        let findObj:any = this.dbInformationClassification.find((obj:any)=>{return obj.key == this.$route.params.classificationType});
        this.pageClassificationType = findObj.value;
        this.propertiesObj = {...findObj}

        this.infoProperties.label = this.propertiesObj.label;
        this.infoProperties.description = this.propertiesObj.description;

        await this.getAllInformations();

        this.routesArray = [
            { name: 'Information Classification', routeName: 'infoClassifications' },
            { name: this.$route.params.classificationType, routeName: 'classificationType', processId: `${this.$route.params.classificationType}` }
        ];
        this.navIndexNums = { index: 1, name: 'Information' };

        this.infoPropertiesDummy = { ...this.infoProperties };
        // this.selectedInformationIds = this.filteredInformations.map((obj: any) => obj._id);
    },
    methods: {
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.allInformationsJson;
            } else {
                this.allInformationsJson = this.dummyList;
                for (const key in this.filterDummy) {
                    this.filterDummy[key] = '';
                }
            }
            emitter.emit('visible', this.showFilters);
        },
        async mapInformations() {
            const missingIds = this.selectedInformationIds.filter((_id:any) => !this.dummySelectedIds.includes(_id));
            // console.log('IDs in selectedInformationIds:', missingIds);
            let payload:any = {
                informations:missingIds
            }
            try {
                await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/org/informationclassfication/${this.pageClassificationType}/updatemapping`, payload).then(async (result: any) => {
                    toast.info('Updated', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                    // await this.getAllInformations();
                    this.$router.push({ name: 'infoClassificationProperties', params: { classificationType: this.$route.params.classificationType } });
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async getAllInformations() {
            this.allInformationsJson = [];
            this.selectedInformationIds = [];
            this.dummySelectedIds = []
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall?org=true`).then(async (result: any) => {
                this.allInformationsJson = result.data;
                this.filteredInformations = this.allInformationsJson.filter((result: any) => {
                    return result.classification == this.pageClassificationType;
                });
                console.log('filter', this.allInformationsJson);
                console.log('filter', this.filteredInformations);
                this.filteredInformations.map((obj: any) => {
                    this.selectedInformationIds.push(obj._id);
                    this.dummySelectedIds.push(obj._id);
                });
            });
        },
        getClassificationLabel(classificationId: any) {
            const classification = this.dbInformationClassification.find((item: any) => item.value === classificationId);

            return classification ? classification.key : '';
        },
        sort(tableHead: any) {
            this.className = tableHead;
            if (tableHead === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = tableHead;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        paginationUpdations(currentPage: any, pageSize: any) {
            console.log('Pagination processing... ', currentPage, pageSize);
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        colorPriorityText(value: any) {
            if (value == 5) {
                return ['text-darkred', 'border-solid border border-darkred font-semibold'];
            } else if (value == 4) {
                return ['text-error', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-error'];
            } else if (value == 3) {
                return ['text-yellow', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-yellow'];
            } else if (value == 2) {
                return ['text-green', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-green'];
            } else if (value == 1) {
                return ['text-lightgreen', 'border-solid border border-lightgreen font-semibold'];
            }
        },
        colorPriorityBg(value: any) {
            if (value == 5) {
                return ['bg-darkred'];
            } else if (value == 4) {
                return ['bg-error', 'opacity-10'];
            } else if (value == 3) {
                return ['bg-yellow', 'opacity-10'];
            } else if (value == 2) {
                return ['bg-green', 'opacity-10'];
            } else if (value == 1) {
                return ['bg-lightgreen'];
            }
        }
    }
});
</script>
<style scoped>
.text-gray {
    color: rgba(77, 77, 77, var(--tw-text-opacity));
}
.progressbarB {
    border: 2px solid #e9e9e9;
    background: #e9e9e9;
    z-index: 10;
    top: -8px;
}
.adjust {
    margin-right: 10%;
}
.popup-business {
    overflow-y: scroll;
}
::-webkit-scrollbar {
    width: 0px;
}
.colorLabelWidth {
    width: 48%;
}
.e9BorderStyling {
    border: 1px solid #e9e9e9;
}
.inputBoxInDiv {
    margin-bottom: 3%;
}
.progress {
    z-index: 11;
}
</style>


