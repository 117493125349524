<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Threat</h1>
            <div class="cardbody">
                <div class="flex flex-row-reverse items-center">
                    <div class="flex flex-row-reverse items-center">
                        <button class="flex ml-3" @click="filterOpen" :class="threatList.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                        <button class="btnprimary ml-3" @click="showPopup()">Add</button>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
        <div class="mt-3 text-xs">
            <div>
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
            </div>
        </div>
        <newTable :tableRowData="threatList" :headersData="headers" :openPopup="handlePopUp"></newTable>
        <div>
            <popup v-if="isShow">
                <template v-slot:header>
                    <div class="flex items-center justify-between popupheadingcontainer rounded">
                        <h1>Threat</h1>
                        <button class="float-right text-3xl" @click="cancelBtn('close')">
                            <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                        </button>
                    </div>
                </template>
                <div class="statuscontainer popupbodycontainer">
                    <div class="formcontainer flex flex-col">
                        <div class="row flex flex-row w-full upperspacing">
                            <div class="w-full">
                                <label class="controllabel">Threat ID</label>
                                <input
                                    v-model="selectedThreat.threatId"
                                    placeholder="Threat Id"
                                    id="id"
                                    class="inputboxstyling"
                                    :class="{
                                        'is-invalid': v$.selectedThreat.threatId.$error
                                    }"
                                />
                                <div v-if="v$.selectedThreat.threatId.$error" class="text-red-500">
                                    <div class="error-text" v-if="v$.selectedThreat.threatId.required.$invalid">Required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="formcontainer flex flex-col">
                        <div class="w-full">
                                <label class="controllabel">Threat</label>
                                <!-- <dropdown :dataprops="selectedCategory" :inputValue="selectedThreat.threatEvent" :placeholderValue="'Threat'" @name="getName" :error="v$.selectedThreat.threatEvent.$error"></dropdown> -->
                                <textarea class="inputboxstyling" placeholder="Threat" v-model="selectedThreat.threatEvent" :error="v$.selectedThreat.threatEvent.$error" />
                                <div v-if="v$.selectedThreat.threatEvent.$error" class="text-red-500">
                                    <div class="error-text" v-if="v$.selectedThreat.threatEvent.required.$invalid">Required</div>
                                </div>
                            </div>
                    </div>
                    <div class="formcontainer flex flex-col">
                        <div class="row flex flex-row w-full upperspacing">
                            <div class="w-3/6">
                                <label class="controllabel">Threat Source</label>
                                <dropdown :dataprops="selectedCategory" :inputValue="selectedThreat.threatSource" :placeholderValue="'Threat Source'" @name="getName"></dropdown>
                                <!-- <dropdown :dataprops="selectedCategory" :inputValue="selectedThreat.threatSource" :placeholderValue="'Threat Source'" @name="getName" :error="v$.selectedThreat.threatSource.$error"></dropdown> -->
                                <!-- <div v-if="v$.selectedThreat.threatSource.$error" class="text-red-500">
                                    <div class="error-text" v-if="v$.selectedThreat.threatSource.required.$invalid">Required</div>
                                </div> -->
                            </div>

                            <div class="w-3/6 leftspacing">
                                <div>
                                    <label class="controllabel">Threat Category</label>
                                    <dropdown :dataprops="selectedCategory" :inputValue="selectedThreat.threatCategory" :placeholderValue="'Threat Category'" @name="getName"></dropdown>
                                    <!-- <dropdown :dataprops="selectedCategory" :inputValue="selectedThreat.threatCategory" :placeholderValue="'Threat Category'" @name="getName" :error="v$.selectedThreat.threatCategory.$error"></dropdown> -->
                                    <!-- <div v-if="v$.selectedThreat.threatCategory.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.selectedThreat.threatCategory.required.$invalid">Required</div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-center items-center mt-2">
                        <button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelBtn(selectedThreat._id)">Cancel</button>
                        <button @click="addOrUpdatethreat(selectedThreat)" v-if="!selectedThreat._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                        <button class="btnprimary mr-1.5" v-else @click="addOrUpdatethreat()" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                    </div>
                </div>
            </popup>
        </div>
    </div>
</template>
<style scoped>
.positiontooltip,
.lasttooltip {
    font-size: 12px;
}
.tip {
    display: inline;
}
.z-index {
    z-index: 1000;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import popup from '@/components/popup.vue';
import { emitter, toast } from '@/main';
import Loader from '@/components/loader.vue';
import dropdown from '@/components/dropdown.vue';
import newTable from '@/components/newTable.vue';
export default defineComponent({
    data(): any {
        return {
            suggestionSwitch: '',
            filter: {},
            pop: false,
            xPos: '',
            yPos: '',
            threatcopy: '',
            b: false,
            v$: useVuelidate(),
            threatList: [],
            disabled: 1,
            updatedThreatArray: [],
            showFilters: false,
            currentSort: 'name',
            currentSortDir: 'asc',
            classSort: '',
            selectedCategory: [],
            c: false,
            className: '',
            selected: '',
            riskList: [],
            isShow: false,
            dummyList: [],
            headers: ['threatId', 'threatSource', 'threatCategory', 'threatEvent', '_id'],
            mandatoryKeys: ['ThreatCategory', 'ThreatSource', 'Threatevent', 'threatId'],
            fullKeys: ['ThreatCategory', 'ThreatSource', 'Threatevent', 'ThreatId'],
            dummydata: [],
            selectedThreat: { threatId: '', threatCategory: '', threatSource: '', threatEvent: '' },
            result: false,
            objCopy: {},
            autoSuggestion: false,
            handlePopUp: true
        };
    },
    validations() {
        return {
            selectedThreat: {
                threatCategory: { required },
                threatSource: { required },
                threatEvent: { required },
                threatId: { required }
            }
        };
    },
    components: {
        popup,
        dropdown,
        newTable
    },
    async mounted() {
        //this.$store.dispatch('updateDotsLoading', true);
        if (this.$route.path.includes('root')) {
            await this.getRootThreatList();
        } else if (this.$route.path.includes('org')) {
            await this.getOrgThreatList();
        }
        console.log("this.$route.path.includes('root')", this.$route.path.includes('root'));
        this.handleActionHeaders();
        emitter.off('showPopupData');
        emitter.off('showData123');
        emitter.on('showData123', (isData: any) => {
            this.showEditPopUp(isData.entityData);
        });
        emitter.off('deleteData');
        emitter.on('deleteData', (isData: any) => {
            this.showDeletePopUp(isData.entityData);
        });
        emitter.on('showPopupData', (isData: any) => {
            console.table(isData);
            this.showEditPopUp(isData.entityData);
        });
    },
    computed: {
        disableStatus: function (): any {
            let disableStatusCondition;
            if (this.b) {
                let objCopy = Object.assign({}, this.selectedThreat);
                if (JSON.stringify(objCopy) == JSON.stringify(this.obj)) {
                    disableStatusCondition = false;
                } else {
                    disableStatusCondition = true;
                }
            } else {
                let val: any = [];
                Object.values(this.selectedThreat).forEach((value: any) => {
                    val.push(value);
                });
                for (let n of val) {
                    if (n != '') {
                        disableStatusCondition = true;
                    }
                }
            }
            return disableStatusCondition;
        },
        e: function (): any {
            return false;
        }
    },
    async created() {
        window.addEventListener('click', (e) => {
            this.autoSuggestion = false;
        });
    },
    methods: {
        showEditPopUp(threat: any, index: any) {
            this.isShow = true;
            this.obj = threat;
            this.b = threat;
            this.selectedThreat = { ...threat };
            this.objCopy = this.selectedThreat;
        },
        handleActionHeaders() {
            if (this.$route.name == 'orgthreat') {
                this.headers.push('tableDataActions');
                console.log("tableDataActionstableDataActions",this.tableDataActions)
                this.handlePopUp = false;
                console.log('check routes .......', this.headers);
            } else {
                if (this.headers['tableDataActions']) {
                    this.headers.pop('tableDataActions');
                    this.handlePopUp = true;
                }
            }
        },
        filteredCategories(filterColumn: any, status: any) {
            this.selectedCategory = [
                ...new Set(
                    Object.values(this.threatList).map((x: any) => {
                        x[status];
                        return x[status];
                    })
                )
            ].filter((category: any) => {
                return category.toLowerCase().includes(filterColumn.toLowerCase());
            });
        },
        getName(options: any, status: any) {
            let s = status.charAt(0).toLowerCase() + status.replace(/\s+/g, '').substring(1);
            this.filteredCategories(options, s);
            this.setState(options, s);
        },
        setState(options: any, status: any) {
            this.selectedThreat[status] = options;
            this.autoSuggestion = false;
        },
        async getRootThreatList() {
            this.threatList = [];
            this.dummyList = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risks/getall`).then((result: any) => {
                result.data.forEach((threat: any) => {
                    if (threat.threatSource) {
                        this.threatList.push({ threatId: threat.threatId, threatSource: threat.threatSource, threatCategory: threat.threatCategory, threatEvent: threat.threatEvent, _id: threat._id });
                        this.dummyList.push({ threatId: threat.threatId, threatSource: threat.threatSource, threatCategory: threat.threatCategory, threatEvent: threat.threatEvent, _id: threat._id });
                    }
                });
            });
            // this.$store.dispatch('updateDotsLoading', false);
        },
        async getOrgThreatList() {
            this.threatList = [];
            this.dummyList = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/org/threats/getall`).then((result: any) => {
                result.data.forEach((threat: any) => {
                    if (threat.threatSource) {
                        this.threatList.push({ threatId: threat.threatId, threatSource: threat.threatSource, threatCategory: threat.threatCategory, threatEvent: threat.threatEvent, _id: threat._id });
                        this.dummyList.push({ threatId: threat.threatId, threatSource: threat.threatSource, threatCategory: threat.threatCategory, threatEvent: threat.threatEvent, _id: threat._id });
                    }
                });
            });
            // this.$store.dispatch('updateDotsLoading', false);
        },
        async showDeletePopUp(data: any) {
            let payloadThreatId = data._id;
            await this.$http.delete(`${process.env.VUE_APP_RISK_API_URL}/org/threat/${payloadThreatId}/delete`).then((result: any) => {
                toast.info(`Deleted succesfully`, {
                    closeOnClick: true,
                    closeButton: 'button'
                });
            });
            await this.getOrgThreatList();
        },
        toolTip(type: any, e: any) {
            if (type == 'text') {
                if (e.offsetX > 1 && e.offsetX < e.target.offsetWidth && e.offsetY > 1 && e.offsetY < e.target.offsetHeight) {
                    this.pop = true;
                    this.xPos = e.offsetX + 38 + 'px';
                    this.yPos = -15 + 'px';
                }
            }
            if (type == 'button') {
                if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
                    this.pop = true;
                    this.xPos = e.offsetX + e.target.offsetLeft + 13 + 'px';
                    this.yPos = -15 + 'px';
                }
            }
        },
        sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            for (const key in this.filterDummy) {
                this.filterDummy[key] = '';
            }
            if (this.showFilters == true) {
                this.threatList = this.dummyList;
            } else {
                this.threatList = this.dummyList;
            }
            emitter.emit('visible', this.showFilters);
        },
        showPopup() {
            this.b = false;
            this.isShow = true;
            this.v$.$reset();
            Object.keys(this.selectedThreat).forEach((prop: any) => {
                this.selectedThreat[prop] = '';
            });
            this.objCopy = this.selectedThreat;
        },
        showPopupThreat(threat: any, index: any) {
            this.isShow = true;
            this.obj = threat;
            this.b = threat;
            this.selectedThreat = { ...threat };
            this.objCopy = this.selectedThreat;
        },
        cancelBtn(status: any) {
            if (status === 'close') this.isShow = false;
            else if (status) {
                this.selectedThreat = { ...this.obj };
                this.objCopy = this.selectedThreat;
            } else if (!undefined) {
                this.showPopup();
            }
        },
        async addOrUpdatethreat(selectThreat: any) {
            console.log('selectThreat', selectThreat);
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.threatList.push(this.selectedThreat);
                if (this.selectedThreat._id) {
                    await this.updateThreatList();
                } else {
                    await this.addThreatList();
                }
                this.threatList = [];
                if (this.$route.path.includes('root')) {
                    //this.$store.dispatch('updateDotsLoading', true);
                    await this.getRootThreatList();
                } else if (this.$route.path.includes('org')) {
                    //this.$store.dispatch('updateDotsLoading', true);
                    await this.getOrgThreatList();
                }
                this.isShow = false;
                this.v$.$reset();
            } else {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async addThreatList() {
            try {
                console.log('selectedThreat', this.selectedThreat);
                if (this.$route.path.includes('root')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/create`, [this.selectedThreat]).then((result: any) => {
                        toast.info(`Created succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    await this.getRootThreatList();
                } else if (this.$route.path.includes('org')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/org/threat/create`, [this.selectedThreat]).then((result: any) => {
                        toast.info(`Created succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    await this.getOrgThreatList();
                }
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async updateThreatList() {
            try {
                let payloadThreatId = this.selectedThreat._id;
                delete this.selectedThreat._id;
                if (this.$route.path.includes('root')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/${payloadThreatId}/update`, this.selectedThreat).then((result: any) => {
                        toast.info(`Updated succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    await this.getRootThreatList();
                } else if (this.$route.path.includes('org')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/org/threat/${payloadThreatId}/update`, this.selectedThreat).then((result: any) => {
                        toast.info(`Updated succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                    await this.getOrgThreatList();
                }
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        }
    }
});
</script>