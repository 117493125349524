<template>
    <div>
        <div class="border-0 rounded right-0 top-0 overflow-y-scroll overflow-x-hidden z-index fixed flex flex-col w-full max-w-xl bg-white h-full">
            <slot name="header"> </slot>
            <div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
            <slot></slot>
        </div>
    </div>
    <div class="opacity-40 fixed inset-0 z-50 bg-black"></div>
</template>
<style scoped>
.z-index{
    z-index: 999;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
     props: ['popUp'],
    data(): any {
        return {
            v$: useVuelidate(),
            pop: false
        };
    },
    created() {
        this.pop = this.popUp;
    },
    beforeUnmount(){
         this.pop = !this.popUp;
           if (this.pop == false) {
                console.log("false popUp")
                document.body.style.overflow = '';
           }
    },
    watch: {
        popUp(newVal,oldVal){
            this.pop = newVal;
            console.log("this.pop",this.pop)
        },
        pop(newVal, oldVal) {
             if (newVal == true) {
                console.log("true popUp")
                document.body.style.overflow = 'hidden';
            }     
        }
    }
});
</script>
