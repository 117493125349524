<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="row w-full mb-3 justify-between flex items-center">
            <h1 class="title">Risk Report</h1>
            <div class="flex items-center">
                <button class="btnprimary ml-2 flex items-center" @click="reportGenerate()">Generate</button>
                <button class="ml-2 flex" @click="filterOpen" :class="reportsList.length == 0?'btndisabled pointer-events-none':'filterButton'">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-3 text-sm">
            The Global Risks Report 2022, 17th Edition, is published by the World Economic Forum. The information in this report, or on which this report is based, has been obtained from sources that the authors believe to be reliable and accurate. However, it has not been independently verified and no representation or warranty, express or implied, is made as to the accuracy or completeness of any information obtained from third parties.
        </div>
        <div>
            <newTable :tableRowData="reportsList" :headersData="headers" :openPopup="true"></newTable>
        </div>
    </div>
         
</template>
<style scoped>
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { emitter } from '@/main';
import { mapGetters } from 'vuex';
import newTable from '@/components/newTable.vue'

export default defineComponent({
    data(): any {
        return {
            reportsList: [],
            dummyReportsList:[],
            showFilters: false,
            // permissions:[],
            headers:['_id','reportName','createdAt','generatedBy','reportAction','reportDelete'],
            loadercondition:false,
            orgUsersList:[],
            optionsUserArray:[],
        };
    },
    components: {
        newTable
    },

    computed: {
        ...mapGetters({ userInfo: 'userInfo' }),
    },
    methods: {
        reportGenerate(){
            this.$router.push({ name: 'reportCenter'});
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyReportsList = this.reportsList;
            } else {
                this.reportsList = this.dummyReportsList;
                for (const key in this.filterDummy) {
                    this.filterDummy[key] = '';
                }
            }
            emitter.emit('visible', this.showFilters);
        },
        async getallUsers() {
            console.log('qwerty');
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.orgUsersList = res.data;
                console.log('this.orgUsersList', this.orgUsersList);
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                    console.log('this.optionsUserArray', this.optionsUserArray);
                }
            });
        },
        async getReports(){
            this.reportsList = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/reports/getall`).then((res: any) => {
                this.reportsList = res.data;
                this.reportsList.map((obj:any)=>{
                    let usernameObj = this.optionsUserArray.find((user:any)=>{return obj.generatedBy == user.value});
                    if(usernameObj != undefined){
                        obj.generatedBy = usernameObj.label;
                    }else{
                        obj.generatedBy = '';
                    }
                })
            });
            // this.$store.dispatch('updateDotsLoading', false);
        },
    },
    async mounted() {
        //this.$store.dispatch('updateDotsLoading', true);
        await this.getallUsers();
        await this.getReports();
        // emitter.off('showData123');
        // emitter.on('showData123', (isData: any) => {
        //     let comingId = isData.entityData._id;
        //     let showObj = this.reportsList.find((obj:any)=>{
        //         return (obj._id === comingId)
        //     });
        //     this.showPopByEdit(showObj);
        // });
    },  
    async created(){
        await this.getReports();

        // this.permissions=this.userInfo.permissions[0].resources.filter((userPer:any)=>{
        //     return userPer.id==20008
        // })
        // this.permissions= this.permissions[0].permissions
    }
});
</script>

