<template>
    <div v-if="loadercondition">
        <div class="flex justify-center items-center loader">
            <div style="border-top-color: transparent" class="w-20 h-20 border-4 border-blue-400 border-solid rounded-full animate-spin"></div>
            <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
    </div>
    <div class="pt-3 pl-5 pr-5" v-else>
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>

        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Risk Report</h1>
        </div>
        <div class="mt-3 text-xs">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </div>
        <div class="p-5 bg-white w-full flex flex-wrap mt-5">
            <div v-for="obj in descriptionObject" :key="obj" :value='obj.id' @click="openRelatedPopup(obj.id,obj.name)" class="descriptionBox border border-lightgrey rounded flex justify-center items-center m-2 p-5">
                <div>
                    <h1 class="font-semibold pb-4">{{obj.name}}</h1>
                    <p class="font-normal leading-5 text-xs">{{obj.description}}</p>
                </div>
            </div>
        </div>

        <popup v-if="openPopup" :popUp="openPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Generate Report</h1>
                    <button class="float-right text-3xl" @click="onCancelbtn()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset >
             <div>
                <div>
                    <div class="statuscontainer popupbodycontainer">
                        <div class="formcontainer flex flex-col">
                            <div class="row flex flex-row w-full">
                                <div class="w-full p-2">
                                    <label class="controllabel">Name of the Report</label>
                                    <input
                                        type="text"
                                        disabled
                                        class="disabledinputboxstyling"
                                        v-model="reportData.reportName"
                                        placeholder="Display Name"
                                    />
                                </div>
                            </div>
                            <div class="row flex flex-row w-full pt-2">
                                <div class="w-full p-2" v-if="popupType == 1 || popupType==2">
                                    <label class="controllabel">System(s)</label>
                              
                                    <multiselect
                                        v-model="selectedSystemIds"
                                        :options="totalSystems"
                                        open-direction="bottom"
                                        :multiple="true"
                                        :close-on-select="false"
                                        :hide-selected="true"
                                        :clear-on-select="true"
                                        label="label"
                                        track-by="value"
                                        :searchable="true"
                                        :hideArrow="false"
                                        placeholder="Systems"
                                        class="custom-multiselect"
                                    >
                                    </multiselect>
                                </div>
                                <div class="w-full p-2" v-if="popupType == 3 || popupType == 4">
                                    <label class="controllabel">Department</label>
                                    
                                    <multiselect
                                        v-model="selectedDepartmentId"
                                        :options="departmentList"
                                        open-direction="bottom"
                                        :multiple="false"
                                        :close-on-select="false"
                                        :hide-selected="true"
                                        :clear-on-select="true"
                                        label="label"
                                        track-by="value"
                                        :searchable="true"
                                        :hideArrow="false"
                                        placeholder="Department"
                                        class="custom-multiselect"
                                    >
                                    </multiselect>
                                </div>
                                <div class="w-full p-2" v-if="popupType == 6">
                                    <label class="controllabel">Risk</label>
                                    
                                    <multiselect
                                        v-model="selectedRiskId"
                                        :options="simplifiedRiskList"
                                        open-direction="bottom"
                                        :multiple="false"
                                        :close-on-select="false"
                                        :hide-selected="true"
                                        :clear-on-select="true"
                                        label="label"
                                        track-by="value"
                                        :searchable="true"
                                        :hideArrow="false"
                                        placeholder="Risk"
                                        class="custom-multiselect"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                    <button @click="resetData" class="mr-1.5" :class="selectedSystemIds.length > 0 || selectedDepartmentId != '' || selectedRiskId!='' || popupType == 5 ? 'btnprimary' :'btndisabled pointer-events-none'" >Reset</button>
                    <button @click="GenerateReports" :class="selectedSystemIds.length > 0 || selectedDepartmentId != '' || selectedRiskId!='' || popupType == 5 ? 'btnprimary' :'btndisabled pointer-events-none'" >Generate</button>
                </div>
            </div>
           </fieldset>
        </popup>
    </div>
         
</template>
<style scoped>
.descriptionBox{
    cursor: pointer;
    width:320px;
}
.loader{
	position: fixed;
    top: 50%;
    left: 50%;
}
.multiselect {
    margin-top: 4px !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter, toast } from '@/main';

import { mapGetters } from 'vuex';
import moment from 'moment';
import popup from '@/components/popup.vue';
import breadCrumb from '@/components/breadCrumb.vue';
import Multiselect from 'vue-multiselect';

export default defineComponent({
    data(): any {
        return {
            systems:[],
            totalSystems:[],
            orgUsersList:[],
            optionsUserArray:[],
            departmentList:[],
            tenants:[],
            riskList:[],
            simplifiedRiskList:[],
            routesArray:[],
            descriptionObject:[
                {
                    id:1,
                    name:'System based Detailed Risk Report',
                    description:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader.It is a long established fact that a reader will be it is a long established fact that a reader.'
                },
                {
                    id:3,
                    name:'Team based Detailed Risk Report',
                    description:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader.It is a long established fact that a reader will be it is a long established fact that a reader.'
                },
                {
                    id:5,
                    name:'Risk based Summary Report',
                    description:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader.It is a long established fact that a reader will be it is a long established fact that a reader.'
                },
                {
                    id:2,
                    name:'System based Summary Risk Report',
                    description:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader.It is a long established fact that a reader will be it is a long established fact that a reader.'
                },
                {
                    id:4,
                    name:'Team based Summary Report',
                    description:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader.It is a long established fact that a reader will be it is a long established fact that a reader.'
                },
                {
                    id:6,
                    name:'Risk based Detailed Summary Report',
                    description:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader.It is a long established fact that a reader will be it is a long established fact that a reader.'
                }
            ],
            selectedSystemIds:[],
            selectedDepartmentId:'',
            selectedRiskId:'',
            popupType:'',
            openPopup:false,
            reportData:{
                reportName:'',
                type:'',
            },
            loadercondition:false,
        };
    },
    components: {
        popup,
        Multiselect,
        breadCrumb
    },

     computed: {
        ...mapGetters({ userInfo: 'userInfo' }),
    },
    methods: {
        openRelatedPopup(num:any,comingName:any){
            let now = moment(new Date);
            let formattedTime = now.format('DDMMYYYYHHmmss');

            this.reportData.reportName = `${comingName} - ${formattedTime}`;
            this.reportData.type = num;

            this.popupType = num;
            this.openPopup = true;
            this.selectedSystemIds = [];
            this.selectedDepartmentId = '';
            this.selectedRiskId = '';
        },
        onCancelbtn(){
            this.popupType = '';
            this.openPopup = false;
            this.reportData = {
                reportName:'',
                type:'',
            };
            this.selectedSystemIds = [];
            this.selectedDepartmentId = '';
            this.selectedRiskId = '';
        },
        resetData(){
            this.selectedSystemIds = [];
            this.selectedDepartmentId = '';
            this.selectedRiskId = '';
        },
        async getSystems() {
            let entityId='20005'
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = [...result.data];
                this.totalSystems = result.data.map((sys: any) => {
                    return { value: sys._id, label: sys.systemName };
                });
            });
       
		},
        async getallUsers() {
            console.log('qwerty');
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.orgUsersList = res.data;
                console.log('this.orgUsersList', this.orgUsersList);
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                    console.log('this.optionsUserArray', this.optionsUserArray);
                }
            });
            console.log('this.orgUsersList', this.orgUsersList);
        },
        async getAllTeams() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
			for (var i = 0; i < res.data.length; i++) {
				this.departmentList.push({ label: res.data[i].teamName, value: res.data[i]._id });
			}
			});
            console.log("this.departmentList",this.departmentList)
		},
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { value:org._id, label: org.organizationName }
                    this.tenants.push(obj);
                });
                console.log("hello this is tenants",this.tenants)
            });
        },
        average(arr: any) {
            return arr.reduce((p: any, c: any) => p + c, 0) / arr.length;
        },
        riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        async getRisksList() {
            this.simplifiedRiskList = [];
            this.riskList = [];

            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risks/getall`).then((result: any) => {     
            this.riskList = result.data;
			for (let i = 0; i < this.riskList.length; i++) {

                    // This is only upto systemId. But we need to change for Orgid and ProcessId also
 
                    if(this.riskList[i]['systemId']){
                        let simpleObj = { value: this.riskList[i]['mappedRisk'], label:this.riskList[i]['risk']};
                        this.simplifiedRiskList.push(simpleObj);
                    }

					this.openList = this.riskList[i];

					let condition1 = (Object.keys(this.riskList[i]).includes('residual') && this.riskList[i]['residual'].length>0)
                    let averageImpact;
                    let impact;
                    let severity;

					if(condition1 == true){
                        let lengthOfArray = this.riskList[i]['residual'].length
                        let indexObj = this.riskList[i]['residual'][lengthOfArray-1];
                        
                        if(indexObj.isActive == true){
                            averageImpact = this.average([indexObj['organization'], indexObj['business'], indexObj['operations'], indexObj['technical'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);
							let arr = ['organization','business','operations','technical','legal','financial','likelihood']
                            for(let key of arr){
                                this.riskList[i][key] = indexObj[key];
                            }
                        }else{
                            averageImpact = this.average([this.openList['organization'], this.openList['business'], this.openList['operations'], this.openList['technical'], this.openList['legal'], this.openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * this.openList['likelihood']);
                        }
                    }else if(condition1 == false){
                        averageImpact = this.average([this.openList['organization'], this.openList['business'], this.openList['operations'], this.openList['technical'], this.openList['legal'], this.openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * this.openList['likelihood']);
                    }
                    this.riskList[i].impact = impact;
                    this.riskList[i].severity = severity;
					
					console.log('this.risklist[i]', this.riskList[i]);
				}
            });
        },
        async GenerateReports(){
            if(this.popupType == 1 || this.popupType == 2){
                this.reportData['systems'] =  []
                this.selectedSystemIds.map((obj:any)=>{this.reportData['systems'].push(obj.value)})
            }else if(this.popupType == 3 || this.popupType == 4){
                this.reportData['departments'] =  [this.selectedDepartmentId.value];
            }else if(this.popupType == 6){
                this.reportData['mappedRisk'] = [this.selectedRiskId.value]
            }

            this.reportData.createdBy = this.userInfo._id
            let payload = {...this.reportData};

            try {
                this.loadercondition = true;
                const response = await this.$http.post(`${process.env.VUE_APP_FILE_API_URL}/api/org/risks/reports`,payload);
                if (response.status == 200) {
                    this.$router.push({ name: 'reports'});
                } else {
                    console.error('Failed to generate report');
                }

            } catch (e) {
                this.loadercondition = false;
                toast.error('Failed to generate report', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
    },
    async mounted() {
        await this.getSystems();
        await this.getallUsers();
        await this.getAllTenants();
        await this.getAllTeams();
        await this.getRisksList();
        this.routesArray = [
            { name: 'Reports', routeName: 'reports' },
            { name: 'Report-Center', routeName: 'reportCenter' }
        ];
    }, 
});
</script>

