<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="flex justify-between items-center">
            <h1 class="title">Business Process</h1>
            <div class="flex items-center">
                <!-- <div class="noteam text-center">
                    <button class="btnprimary mr-2" @click="showPopUp()">Add</button>
                </div> -->
                <div>
                    <div @click="filterOpen">
                        <button class="flex" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-3 text-xs">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </div>

        <newTable :tableRowData="tableRow" :headersData="headers" :openPopup="false" :navigate="true"></newTable>


    </div>
</template>
<style scoped>
.invalid-multiselect {
    border: 1px solid red !important;
}
.v-select-list li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { required, maxLength, minLength, email, helpers, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { emitter, toast } from '../../main';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
import popup from '@/components/popup.vue';

import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import newTable from '@/components/newTable.vue';

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            permissions: [],
            tableRow: [],
            showFilters: false,
            dummyObj: {},
            headers: ['_id', 'entityId', 'Business Process Name', 'department', 'Owner'],
            businessProcess: {
                criticality: '',
                businessProcessName: '',
                ownerType: '',
                name: '',
                department: '',
                role: '',
                emailAddress: '',
                phoneNumber: '',
                systems: [],
                description:'',
                // informations: []
            },
            businessProcessList: [],
            systems: [],
            users: [],
            optionsUserArray: [],
            tenantsSelect: [],
            tenants: [],
            savedInformationSets: [],
            teams: [],
            popUpOpen: false,
            ownerTypeValues: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Secondary'
                }
            ],
            departmentList: [],
            editpop: false
        };
    },
    components: {
        newTable,
        popup,
    },
    computed: {
        ...mapGetters({ dbProcessCriticality:'dbProcessCriticality' }),
        ...mapGetters({ userInfo: 'userInfo' }),
       
    },
    methods: {
        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
        getUserDetails(nameId:any){
            let mainUser = this.users.find((user:any)=>{return user._id==nameId})
            // this.businessProcess.emailAddress = mainUser.email;
            this.businessProcess.role = mainUser.jobTitle;
        },
        async getAllUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = result.data;
                this.dummySystems = result.data;
                this.systems = this.systems.map((sys: any) => {
                    return { value: sys._id, label: sys.systemName };
                });
            });
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id: org._id, name: org.organizationName };
                    this.tenants.push(obj);
                    const object = { value: org._id, label: org.organizationName };
                    this.tenantsSelect.push(object);
                });
            });
        },
        async getInformationSets() {
            this.savedInformationSets = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall`).then((result: any) => {
                this.savedInformationSets = result.data.map((info: any) => {
                    return { value: info._id, label: info.information };
                });
            });
        },
        async getAllTeams() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                for (var i = 0; i < res.data.length; i++) {
                    let a = {value:res.data[i]._id, label:res.data[i].teamName}
                    this.departmentList.push(a);
                }
            });
            console.log('this.departmentList', this.departmentList);
        },
        async getBusinessProcess() {
            this.businessProcessList = [];
            this.tableRow = [];

            try {
                const result = await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`);
                // this.$store.dispatch('updateDotsLoading',true);
                console.table(result.data);
                result.data.forEach((entity:any) => {
                    console.log("entity", entity)
                    const mainName = this.optionsUserArray.find((obj: any) => { 
                        return obj.value === entity.name
                    });
                    const mainDept = this.departmentList.find((obj: any) => {
                        console.log("obj", obj)
                        return obj.value === entity.department
                    });

                    const owner = mainName ? mainName.label : '-';
                    const department = mainDept ? mainDept.label : '-';

                    this.tableRow.push({
                        _id: entity._id,
                        entityId: 20006,
                        'Business Process Name': entity.businessProcessName,
                        Owner: owner,
                        department: department,
                        'Owner Type': entity.ownerType,
                        Email: entity.emailAddress,
                        Phone: entity.phoneNumber,
                        description:entity.description,
                    });
                    
                    if (entity.ownerType === 1) {
                        this.thirdPartyPrimaryCondition = true;
                    }
                });
                // this.$store.dispatch('updateDotsLoading',false);
            } catch (error) {
                // this.$store.dispatch('updateDotsLoading',false);
                console.error('Error fetching business processes:', error);
            }
            console.table(this.tableRow);
        },
    },
    async created() {
        await this.getAllUsers();
        await this.getAllTenants();
        await this.getSystems();
        await this.getInformationSets();
        await this.getAllTeams();
        await this.getBusinessProcess();
    }
});
</script>
