<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="row w-full justify-between flex">
            <div class="title flex items-center">
                <h1>Request Approvals</h1>
            </div>
            <div class="flex items-center">
                <button class="ml-3 flex" :class="tableRows.length == 0?'btndisabled pointer-events-none':'filterButton'" @click="filterOpen">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-3 text-xs">
            The Global Risks Report 2022, 17th Edition, is published by the World Economic Forum. The information in this report, or on which this report is based, has been obtained from sources that the authors believe to be reliable and accurate. However, it has not been independently verified and no representation or warranty, express or implied, is made as to the accuracy or completeness of any information obtained from third parties.
        </div>

        <newTable :tableRowData="tableRows" :headersData="headers" :openPopup="true"></newTable>

        <popup v-if="approvalStatusDetails" :popUp="approvalStatusDetails">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Request Approval</h1>
                    <button class="float-right text-3xl" @click="onCrossbtn()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <div class="popup-business">
                <div>
                    <div>
                        <div>
                            <div class="statuscontainer popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <fieldset :disabled=true>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class=" w-full">
                                            <label for="Risk" class="controllabel">Risk</label>
                                            <textarea class="inputboxstyling" placeholder="Risk" v-model="riskObjShow.risk" />
                                        </div>
                                    </div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-3/6 ">
                                            <div>
                                                <label class="controllabel">ID</label>
                                                <input class="inputboxstyling" :value="riskObjShow.taskId" />
                                            </div>
                                        </div>

                                        <div class="w-3/6 leftspacing">
                                            <div>
                                                <label class="controllabel">Sub-Category</label>
                                                <input class="inputboxstyling" :value="riskObjShow.subCategory" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-3/6 ">
                                            <div>
                                                <label class="controllabel">Tier</label>
                                                <input class="inputboxstyling" :value="riskObjShow.tier" />
                                            </div>
                                        </div>

                                        <div class="w-3/6 leftspacing">
                                            <div>
                                                <label class="controllabel">Target</label>
                                                <input class="inputboxstyling" :value="riskObjShow.target" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-3/6 ">
                                            <div>
                                                <label class="controllabel">Risk Severity</label>
                                                <select class="inputboxstyling" v-model="riskObjShow.severity">
                                                    <option value="" selected disabled hidden>Please select one</option>
                                                    <option v-for="response in dbSeverity" :key="response" :value="response.value">{{ response.key }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="w-3/6 leftspacing">
                                            <div>
                                                <label class="controllabel">Status</label>
                                                <select class="inputboxstyling" v-model="riskObjShow.status">
                                                    <option value="" selected disabled hidden>Please select one</option>
                                                    <option v-for="response in requestStatus" :key="response" :value="response.id">{{ response.value }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <div>
                                                <label class="controllabel">Risk Owner</label>
                                                <input class="inputboxstyling" :value="riskObjShow.riskOwnerName"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-3/6 ">
                                            <div>
                                                <label class="controllabel">Likelihood</label>
                                                <select class="inputboxstyling" v-model="riskObjShow.likelihood">
                                                    <option value="" selected disabled hidden>Please select one</option>
                                                    <option v-for="response in dbSeverity" :key="response" :value="response.value">{{ response.key }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="w-3/6 leftspacing">
                                            <div>
                                                <label class="controllabel">Impact</label>
                                                <select class="inputboxstyling" v-model="riskObjShow.impact">
                                                    <option value="" selected disabled hidden>Please select one</option>
                                                    <option v-for="response in dbSeverity" :key="response" :value="response.value">{{ response.key }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Justification</label>

                                            <textarea class="inputboxstyling h-24" placeholder="Justification" v-model="riskObjShow.comments" />
                                        </div>
                                    </div>
                                    </fieldset>
                                    <div class="row flex flex-row w-full" v-if="riskObjShow.status == 10408 || ((riskObjShow.status == 10403 || riskObjShow.status == 10410) && riskObjShow.approverComments!='')">
                                        <div class="w-full">
                                            <label for="Risk" class="controllabel">Comments</label>
                                            <textarea :disabled="presentTableRow.status == 10403 || presentTableRow.status == 10410" class=" h-24" placeholder="Comments" v-model="riskObjShow.approverComments" :class="presentTableRow.status == 10403 || presentTableRow.status == 10410 ? 'disabledtextarea':'inputboxstyling'" />
                                        </div>
                                    </div>
                                    
                                        </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttonposition flex justify-center items-center my-4 mx-0">
                        <button :class="presentTableRow.status == 10403 || presentTableRow.status == 10410 ? 'btndisabled pointer-events-none':'bg-darkred rounded font-opensans font-bold text-sm px-2.5 py-2.5 leading-3 cursor-pointer text-white '" class="mr-3"  @click="approveStatus('rejected',riskObjShow)">Reject</button>
                        <button :class="presentTableRow.status == 10403 || presentTableRow.status == 10410 ? 'btndisabled pointer-events-none':'bg-lightgreen rounded font-opensans font-bold text-sm px-2.5 py-2.5 leading-3 cursor-pointer text-white'" @click="approveStatus('approved',riskObjShow)">Approve</button>
                    </div>
                </div>
            </div>
        </popup>
    </div>
</template>
<style scoped>
.widthSize{
    width:128px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { required, maxLength } from '@vuelidate/validators';
import tableData from '@/components/tableData.vue';
import newTable from '@/components/newTable.vue'
import { toast } from '../../main';
import dropdown from '@/components/dropdown.vue';
import useVuelidate from '@vuelidate/core';
import pagination from '@/components/pagination.vue';
import popup from '@/components/popup.vue';
import { emitter } from '../../main';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default defineComponent({
    data(): any {
        return {
            filterDummy: {
            },
            v$: useVuelidate(),
            showFilters: false,
            dummyList: [],
            systems: [],
            tableRows: [],

            riskObj: [],
            riskObjShow: {
                riskName:'',
                severity:'',
                status:'',
                riskOwner:'',
                likelihood:'',
                impact:'',
                systemName:[],
                comments:'',
                approverComments:'',
                taskId:'',
                subCategory:'',
                tier:'',
                target:'',
            },
            tenants: [],
            mainDescription: '',
            orgUsersList: [],
            optionsUserArray: [],
            riskList: [],
            risksSeverityValue: [
                {
                    id: 1,
                    value: 'Very Low'
                },
                {
                    id: 2,
                    value: 'Low'
                },
                {
                    id: 3,
                    value: 'Moderate'
                },
                {
                    id: 4,
                    value: 'High'
                },
                {
                    id: 5,
                    value: 'Very High'
                }
            ],
            subCategory: [
                {
                    id: 10403,
                    value: 'Acceptance'
                },
                {
                    id: 10405,
                    value: 'Mitigate'
                },
                {
                    id: 10407,
                    value: 'Reset'
                },
                {
                    id: 10404,
                    value: 'Delete'
                }
            ],
            mappedSystems:[],
            requestStatus: [
                {
                    id: 10401,
                    value: 'Open'
                },
                {
                    id: 10402,
                    value: 'In Progress'
                },
                {
                    id: 10403,
                    value: 'Approved'
                },
                {
                    id:10408,
                    value:'Pending'
                },
                {
                    id:10409,
                    value:'On Hold'
                },
                {
                    id:10410,
                    value:'Rejected'
                },
            ],
            task: [],
            approvalStatusDetails:false,
            approverComments:'',
            filterArray:[],
            presentTableRow:{},
            headers:['taskId','risk','subCategory','tier','target','createdAt','status'],
            businessProcessList:[],
            businessEntitiesList:[],
            tierObject: [
                {
                    id: 1,
                    value: 'Organization',
                },
                {
                    id: 2,
                    value: 'Process',
                },
                {
                    id: 3,
                    value: 'System',
                },
            ],
        };
    },
    components: {
        popup,
        newTable
    },
    validations(){
        return{
            riskObjShow:{
                approverComments:{required}
            }        
        }
    },
    computed: {
        ...mapGetters({ userInfo: 'userInfo',dbSeverity:'dbSeverity',dbOperations:'dbOperations'}),
        
    },
    async mounted() {
        // //this.$store.dispatch('updateDotsLoading', true);
        emitter.off('approvaldata');
        emitter.on('approvaldata', (data: any) => {
            this.showPopUp(data);
        });
        await this.getAllTenants();
        await this.getallUsers();
        await this.getSystems();
        await this.getBusinessProcess();
        await this.getBusinessEntities();

        await this.getAllTasks();
        
        await this.getRisksList();
    },
    methods: {
        showPopUp(data:any){
            this.presentTableRow = data;
            this.riskObjShow = this.riskList.find((item:any)=>{return item._id == data.riskId});
            this.riskObjShow.status = data.status;
            let riskOwner = this.optionsUserArray.find((user: any) => {
                return user.value == this.riskObjShow.riskOwner;
            })
            let presentTask = this.filterArray.find((obj:any)=>{return obj._id == data._id})
            this.riskObjShow.riskOwnerName = riskOwner != undefined ? riskOwner.label:'';
            this.riskObjShow.taskAction = data.subCategory;
            this.riskObjShow.tier = this.getTierValue(data.tier);
            this.riskObjShow.taskId = data.taskId;
            this.riskObjShow.subCategory = this.getSubCategory(data.subCategory);
            this.riskObjShow.target = data.target;
            if(Object.keys(presentTask).includes('approvalcomments')){
                this.riskObjShow.approverComments = presentTask.comments;
            }else{
                this.riskObjShow.approverComments = '';
            }
            this.approvalStatusDetails = true;
        },
        getTierValue(num:any){
            let a = this.tierObject.find((obj:any)=>{return obj.id == num});
            return a.value;
        },
        getSubCategory(num:any){
            let a = this.subCategory.find((obj:any)=>{return obj.id == num});
            return a.value;
        },
        onCrossbtn(){
            this.v$.$reset();
            this.approverComments = '';
            this.approvalStatusDetails = false;
            this.presentTableRow = {}
        },
        colorPropBgSeverity(scaleScore: any) {
            if (scaleScore == 5) {
                return ['bg-darkred'];
            } else if (scaleScore == 4) {
                return ['bg-error'];
            } else if (scaleScore == 3) {
                return ['bg-yellow'];
            } else if (scaleScore == 2) {
                return ['bg-green'];
            } else if (scaleScore == 1) {
                return ['bg-lightgreen'];
            }
        },
        colorPropText(stat: any) {
            if (stat == 10410) {
                return ['text-darkred', 'border-solid border border-darkred font-semibold'];
            } else if (stat == 10408) {
                return ['text-yellow', 'border-solid border border-yellow font-semibold'];
            } else if (stat == 10403) {
                return ['text-lightgreen', 'border-solid border border-lightgreen font-semibold'];
            }else if (stat == 10409) {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            }else if (stat == 10402) {
                return ['text-primary', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-primary'];
            }else if (stat == 10401) {
                return ['text-green', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-green'];
            }
        },
        colorPropBg(code: any) {
            if (code == 10410) {
                return ['bg-darkred'];
            }else if (code == 10403) {
                return ['bg-lightgreen'];
            }else if (code == 10401) {
                return ['bg-lightgreen', 'opacity-10'];
            }else if (code == 10408) {
                return ['bg-yellow', 'opacity-10'];
            }else if (code == 10402) {
                return ['bg-primary', 'opacity-10'];
            }else if (code == 10409) {
                return ['bg-darkred', 'opacity-10'];
            }
        },
        colorRiskSeverity(status: any) {
            if (status == 1) {
                return ['font-semibold text-lightgreen'];
            } else if (status == 2) {
                return ['font-semibold text-green'];
            } else if (status == 3) {
                return ['font-semibold text-yellow'];
            } else if (status == 4) {
                return ['font-semibold text-error'];
            } else if (status == 5) {
                return ['font-semibold text-darkred'];
            }
        },
        getDateFormat(date:any){
            let now = moment(date);
			return now.format('DD-MM-YYYY');
        },
        async getAllTasks() {
            this.task = [];
            this.filterArray= [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/tasks/getall`).then((result: any) => {
                this.task = result.data;
                this.filterArray = result.data;
            });
        },
        average(arr: any[]) {
            return arr.reduce((p, c) => p + c, 0) / arr.length;
        },
        riskSeverity(value: number) {
            if (value <= 3) {
                return 1;
            } else if (value > 3 && value <= 8) {
                return 2;
            } else if (value > 8 && value <= 12) {
                return 3;
            } else if (value > 12 && value <= 16) {
                return 4;
            } else if (value > 16 && value <= 25) {
                return 5;
            }
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id: org._id, name: org.organizationName };
                    this.tenants.push(obj);
                });
            });
        },
        async getallUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.orgUsersList = res.data;
                this.orgUsersList = this.orgUsersList.filter((user: any) => {
                    return user.isActive == true;
                });
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }
            });
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = result.data;
                this.mappedSystems = result.data.map((sys: any) => {
                    return { value: sys._id, label: sys.systemName };
                });
            });
        },
        async getRisksList() {
            this.riskList= [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/risks/getall`).then((result: any) => {
                this.riskList = result.data;

                for (let i = 0; i < this.riskList.length; i++) {
                    this.openList = this.riskList[i];

                    let condition1 = (Object.keys(this.riskList[i]).includes('residual') && this.riskList[i]['residual'].length>0)
                    let averageImpact;
                    let impact;
                    let severity;

                    if(condition1 == true){
                        let lengthOfArray = this.riskList[i]['residual'].length
                        let indexObj = this.riskList[i]['residual'][lengthOfArray-1];
                        
                        if(indexObj.isActive == true){
                            averageImpact = this.average([indexObj['organization'], indexObj['business'], indexObj['operations'], indexObj['technical'], indexObj['legal'], indexObj['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * indexObj['likelihood']);
                            let arr = ['organization','business','operations','technical','legal','financial','likelihood']
                            for(let key of arr){
                                this.riskList[i][key] = indexObj[key];
                            }
                        }else{
                            averageImpact = this.average([this.openList['organization'], this.openList['business'], this.openList['operations'], this.openList['technical'], this.openList['legal'], this.openList['financial']]);
                            impact = Math.round(averageImpact);
                            severity = this.riskSeverity(impact * this.openList['likelihood']);
                        }
                    }else if(condition1 == false){
                        averageImpact = this.average([this.openList['organization'], this.openList['business'], this.openList['operations'], this.openList['technical'], this.openList['legal'], this.openList['financial']]);
                        impact = Math.round(averageImpact);
                        severity = this.riskSeverity(impact * this.openList['likelihood']);
                    }
                    this.riskList[i].impact = impact;
                    this.riskList[i].severity = severity;
                }
            });
            
            this.filterArray.forEach((item: any) => {
               
                let taskComment
                if(Object.keys(item).includes('comments')){
                    taskComment = item.comments
                }else{
                    taskComment = ''
                }

                let targetName;
                let idKey;
                if(Object.keys(item).includes('systemId') && item.tier == 3){
                    let findObj = this.systems.find((obj:any)=>{return obj._id == item.systemId;})
                    if(findObj!=undefined){
                        targetName = findObj.systemName;
                        idKey = 'systemId';
                    }

                }else if(Object.keys(item).includes('processId') && item.tier == 2){
                    let findObj = this.businessProcessList.find((obj:any)=>{return obj._id == item.processId;})
                    if(findObj!=undefined){
                        targetName = findObj.businessProcessName;
                        idKey = 'processId';
                    }

                }else if(Object.keys(item).includes('orgId') && item.tier == 1){

                    let findObj = this.businessEntitiesList.find((obj:any)=>{return obj._id == item.orgId;});

                    if(findObj!=undefined){
                        targetName = findObj.entityName;
                        idKey = 'orgId';
                    }

                }

                let mainRisk = this.riskList.find((risk: any) => {
                    return item.mappedRisk == risk.mappedRisk && item.idKey == risk.idKey;
                })
                
                
                let row:any;
                console.log("row",row)
                if(item.action == 10405 && Object.keys(item).includes('taskStatus')){
                    row = { _id:item._id, taskId:item.taskId, risk: mainRisk.risk, subCategory: item.action, tier:item.tier, target:targetName , createdAt:item.createdAt, status:item.statusCode, riskId: mainRisk._id };

                }else{
                    row = { _id:item._id, taskId:item.taskId, risk: mainRisk.risk, subCategory: item.action, tier:item.tier, target:targetName , createdAt:item.createdAt, status:item.statusCode, riskId: mainRisk._id };
                    
                }
                
                if(Object.keys(item).includes('createdAt')){
                    row.createdAt = item.createdAt;
                }

                if(((row.subCategory == 10403 || row.subCategory == 10407 || row.subCategory == 10404) && this.userInfo.currentRole == 10207)){
                    this.tableRows.push(row);
                }else if((row.subCategory == 10403 || row.subCategory == 10407 || row.subCategory == 10404 ) && (item.createdBy == this.userInfo._id || item.riskOwner == this.userInfo._id)){
                    this.tableRows.push(row);
                }
                
                if(Object.keys(item).includes('mappedTaskId')){
                    if((row.subCategory==10405) && (item.createdBy == this.userInfo._id || item.riskOwner == this.userInfo._id || item.approvalFor == this.userInfo._id)){ 
                        this.tableRows.push(row);
                    }
                }
               
            });
            // this.$store.dispatch('updateDotsLoading', false);
        },
        async approveStatus(status:any, riskObject:any){
            this.v$.$touch();
            if(!this.v$.riskObjShow.$invalid){
                let payload:any = {};
                let mappedapprovalLength:any
                let mappedTask:any
                let presentTask:any

                if(this.presentTableRow.subCategory == 10405){
                    presentTask = this.filterArray.find((task:any)=>{return task._id == this.presentTableRow._id;});             

                    mappedTask  = this.filterArray.find((task:any)=>{return task._id == presentTask.mappedTaskId})
                    mappedapprovalLength = mappedTask.approval.length;
                }
                
                

                if(this.presentTableRow.subCategory==10405 && status == 'approved'){
                    payload = {
                        statusCode: 10403,
                        comments: this.approverComments,
                        createdAt:new Date(),
                        createdBy:this.userInfo._id,
                        taskStatus : 10403,
                        approval: [mappedTask.approval[mappedapprovalLength-1].approval],
                        mappedTaskId: presentTask.mappedTaskId
                    }
                }else if(this.presentTableRow.subCategory==10405 && status == 'rejected'){
                    payload = {
                        statusCode: 10410,
                        comments: this.approverComments,
                        createdAt:new Date(),
                        createdBy:this.userInfo._id,
                        taskStatus : 10410,
                        approval: [mappedTask.approval[mappedapprovalLength-1].approval],
                        mappedTaskId: presentTask.mappedTaskId
                    }
                }else if(this.presentTableRow.subCategory!=10405 && status == 'approved'){
                    console.log('hi3')
                    payload = {
                        statusCode: 10403,
                        comments: this.approverComments,
                        createdAt:new Date(),
                        createdBy:this.userInfo._id,
                        taskStatus : 10403
                    }

                }else if(this.presentTableRow.subCategory!=10405 && status == 'rejected'){
                    payload = {
                        statusCode: 10410,
                        comments: this.approverComments,
                        createdAt:new Date(),
                        createdBy:this.userInfo._id,
                        taskStatus : 10410
                    }
                }

                await this.$http
				.post(`${process.env.VUE_APP_RISK_API_URL}/task/${this.presentTableRow._id}/check`, [payload])
				.then((response: any) => {
					if (response.status == 200) {
						toast.info(`Saved`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
                    this.approvalStatusDetails = false;
                    this.tableRows = [];
                    this.riskList = [];
                    this.v$.$reset();
				})
				.catch((error: any) => {
					toast.error(`Save failed with ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
                await this.getAllTasks();
                await this.getRisksList();
            }
            else{
                toast.error(`validation error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            this.filterDummy = {};
            if (this.showFilters == true) {
                this.dummyList = this.risk;
            } else {
                this.risk = this.dummyList;
            }
            emitter.emit('visible', this.showFilters);
        },
        async getBusinessProcess(){
            this.businessProcessList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
                this.businessProcessList = result.data;
            })
        },
        async getBusinessEntities(){
            this.businessEntitiesList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                this.businessEntitiesList = result.data;
            })
        },
    }
});
</script>
