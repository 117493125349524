<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Systems</h1>
            <div class="cardbody">
                <div class="flex flex-row-reverse items-center">
                    <div class="flex flex-row-reverse items-center">
                        <button class="flex" @click="filterOpen" :class="systemData.length == 0?'btndisabled pointer-events-none':'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                    <!-- <div class="text-center">
                      <button class="btnprimary mr-2" @click="showPopUp()">Add</button>
                   </div> -->
                </div>
            </div>
        </div>
        <div class="mt-3 text-xs">
            <div>
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
            </div>
        </div>
        
            <newTable :tableRowData="systemData" :headersData="headers1" :openPopup="false" :navigate="true"></newTable>
        

        <popup class="system" v-if="popUp==true" :popUp="popUp">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>System</h1>
                    <button class="float-right text-3xl" @click="onCancelbtn('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <fieldset>
                <!-- :disabled="!permissions[1]" -->
                <div>
                <div>
                    <div class="statuscontainer popupbodycontainer">
                        <div class="formcontainer flex flex-col">
                            <div class="row flex flex-row w-full upperspacing">
                                <div class="col-md-6 w-6/12">
                                    <label class="controllabel">System Name</label>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model="system.systemName"
                                        placeholder="System Name"
                                        :class="{
                                            'is-invalid': v$.system.systemName.$error
                                        }"
                                    />
                                    <div v-if="v$.system.systemName.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.systemName.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="col-md-6 w-6/12 leftspacing">
                                    <label class="controllabel">Owned Entity</label>
                                    <!-- <dropdown :dataprops="selectedCategory" :inputValue="getOrgName(system.ownedBy)" :placeholderValue="'Owned By'" @name="getCategories" :error="v$.system.ownedBy.$error"></dropdown>
                                    <div v-if="v$.system.ownedBy.$error" class="text-red-500">
                                        <div class="error-text pl-2" v-if="v$.system.ownedBy.required.$invalid">Required</div>
                                    </div> -->
                                    <div>
                                        <!-- <Multiselect
                                            v-model="system.ownedBy"
                                            :options="businessEntitiesList"
                                            :searchable="true"
                                            :minChars="1"
                                            :create-option="true"
                                            :filterResults="true"
                                            :visible="!!searchedInput"
                                            class="multiselect-custom"
                                            :classes="{singleLabelText:'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full'}"
                                            :canClear='false'
                                            :caret='false'
                                            placeholder="Owned By"
                                            :class="{
                                                'is-invalid borderRed': v$.system.ownedBy.$error,
                                            }"
                                        ></Multiselect>
                                        <div v-if="v$.system.ownedBy.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.system.ownedBy.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row flex flex-row w-full upperspacing">
                                <div class="col-md-6 w-6/12">
                                    <label class="controllabel">Description</label>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model="system.description"
                                        placeholder="Description"
                                        :class="{
                                            'is-invalid borderRed': v$.system.description.$error,
                                        }"
                                    />
                                    <div v-if="v$.system.description.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.description.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="col-md-6 w-6/12 leftspacing">
                                    <label class="controllabel">Managed By</label>
                                    <div>
                                        <!-- <Multiselect
                                            v-model="system.managedBy"
                                            :options="managedByOptions"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{singleLabelText:'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full'}"
                                            :canClear='false'
                                            :caret='false'
                                            placeholder="Managed By"
                                            :class="{
                                                'is-invalid borderRed': v$.system.managedBy.$error,
                                            }"
                                        ></Multiselect>
                                        <div v-if="v$.system.managedBy.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.system.managedBy.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row grid grid-cols-2">
                                <div class="col-md-6 upperspacing">
                                    <label class="controllabel">Owner</label>
                                    <div>
                                        <!-- <Multiselect
                                            v-model="system.owner"
                                            :options="optionsUserArray"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            class="multiselect-custom"
                                            :classes="{singleLabelText:'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full'}"
                                            :canClear='false'
                                            :caret='false'
                                            placeholder="Owner"
                                            :class="{
                                                'is-invalid borderRed': v$.system.owner.$error,
                                            }"
                                        ></Multiselect>
                                        <div v-if="v$.system.owner.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.system.owner.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="w-full upperspacing leftspacing">
                                    <label class="controllabel">Public Facing?</label>
                                    <select
                                        class="inputboxstyling"
                                        v-model="system.publicFacing"
                                        :class="{
                                            'is-invalid': v$.system.publicFacing.$error
                                        }"
                                    >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option v-for="response in yesNo" :key="response" :value="response.id">{{response.value}}</option>
                                    </select>
                                    <div v-if="v$.system.publicFacing.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.publicFacing.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing" v-if="system.publicFacing == '1'">
                                    <label class="controllabel">Add <span class="text-xs">(URL / IP Address / Domain Name)</span></label>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model="system.add"
                                        placeholder="Add"
                                        :class="{
                                            'is-invalid': v$.system.add.$error
                                        }"
                                    />
                                    <div v-if="v$.system.add.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.add.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing" :class="{'leftspacing':system.publicFacing == '1'}">
                                    <label class="controllabel">Hosting type</label>
                                    <select
                                        class="inputboxstyling"
                                        v-model="system.hostingType"
                                        :class="{
                                            'is-invalid': v$.system.hostingType.$error
                                        }"
                                    >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option value="on-premise">On-premise</option>
                                        <option value="data-center">Data center</option>
                                        <option value="cloud">Cloud</option>
                                    </select>
                                    <div v-if="v$.system.hostingType.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.hostingType.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="col-md-6 w-full upperspacing" :class="{'leftspacing':system.publicFacing != '1'}">
                                    <label class="controllabel">Provider</label>
                                    <dropdown :dataprops="selectedCategory" :inputValue="system.hostingProvider" :placeholderValue="'Provider'" @name="getCategories" :error="v$.system.hostingProvider.$error"></dropdown>
                                    <div v-if="v$.system.hostingProvider.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.hostingProvider.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="col-md-6 w-full upperspacing" :class="{'leftspacing':system.publicFacing == '1'}">
                                    <label class="controllabel">Country</label>
                                    <dropdown :dataprops="selectedCategory" :inputValue="system.country" :placeholderValue="'Country'" @name="getCategories" :error="v$.system.country.$error"></dropdown>
                                    <div v-if="v$.system.country.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.country.required.$invalid">Required</div>
                                        <div v-else-if="v$.system.country.required && v$.system.country.alphabet.$invalid" class="invalid-feedback">Country is Invalid</div>
                                    </div>
                                </div>
                                <div class="col-md-6 w-full upperspacing" :class="{'leftspacing':system.publicFacing != '1'}">
                                    <label class="controllabel">State</label>
                                    <dropdown :dataprops="selectedCategory" :inputValue="system.state" :placeholderValue="'State'" @name="getCategories" :error="v$.system.state.$error"></dropdown>
                                    <div v-if="v$.system.state.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.state.required.$invalid">Required</div>
                                        <div v-else-if="v$.system.state.required && v$.system.state.alphabet.$invalid" class="invalid-feedback">State is Invalid</div>
                                    </div>
                                </div>
                                <div class="col-md-6 w-full upperspacing" :class="{'leftspacing':system.publicFacing == '1'}">
                                    <label class="controllabel">City</label>
                                    <dropdown :dataprops="selectedCategory" :inputValue="system.city" :placeholderValue="'City'" @name="getCategories" :error="v$.system.city.$error"></dropdown>
                                    <div v-if="v$.system.city.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.city.required.$invalid">Required</div>
                                        <div v-else-if="v$.system.city.required && v$.system.city.alphabet.$invalid" class="invalid-feedback">City is Invalid</div>
                                    </div>
                                </div>
                                <!-- <div class="row flex flex-row w-full">
                                    <div class="w-full p-2">
                                        <label class="controllabel">Information</label>
                                        <Multiselect
                                            mode="tags"
                                            :close-on-select="false"
                                            :create-option="false"
                                            v-model="system.informations"
                                            :options="savedInformationSets"
                                            :searchable="true"
                                            :minChars="1"
                                            :filterResults="true"
                                            :classes="{ singleLabelText: 'text-sm',
                                            container: 'relative h-fit mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-lightgrey rounded bg-white text-base leading-snug outline-none' }"
                                            :canClear="false"
                                            :caret="false"
                                            placeholder="Information"
                                        />
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttonposition flex justify-center items-center mt-2 mb-4 mx-0">
                    <!-- v-if="permissions[2]" -->
                    <button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn(system._id)">Cancel</button>
                    <button class="btnprimary" v-if="!system._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdate(system, 20005)">Save</button>
                    <button class="btnprimary" v-else :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdate(system, 20005)">Update</button>
                </div>
            </div>
            </fieldset>
        </popup>
    </div>
    
</template>
<style scoped>
.borderRed{
    border: 1px solid red !important;
}
.ellipsis-container{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { required,helpers,requiredIf } from '@vuelidate/validators';
import popup from '@/components/popup.vue';
import useVuelidate from '@vuelidate/core';
import dropdown from '@/components/dropdown.vue';
import { toast } from '../../main';

const alphabet = helpers.regex(/^[a-zA-Z]+$/);
import newTable from '@/components/newTable.vue';
import { emitter } from '@/main';

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            dummydata: [],
            savedInformationSets:[],

            dummyList:[],
            filter: {},
            systemData: [],
            showFilters:false,
            systems: [],
            headers1:['systemName','description','publicFacing','hostingType','securityCategory','_id'],

            systemValue: [
                {id:0,value: 'None'},
                {id: 1,value: 'Low'},
                {id: 2,value: 'Moderate'},
                {id: 3,value: 'High'} 
            ],
            systemsInformations: [],

            mandatoryKeys: ['system', '(Max of CIA)'],
            fullKeys: ['system', '(Max of CIA)'],
           

            popUp:false,
            selectedCategory:[],
            autoSuggestion:false,
            objToBeModified:{},
            users:[],
            tenants:[],
            tenantsSelect: [],
            optionsUserArray:[],
            // permissions:[],

             system: {
                systemName: '',
                ownedBy: '',
                description: '',
                managedBy: '',
                owner:'',
                hostingType: '',
                hostingProvider: '',
                publicFacing: '',
                add: '',
                country: '',
                state: '',
                city: '',
                // informations:[]
            },
            countriesStatesCities: [
                {
                    CountryName: 'India',
                    States: [
                        {
                            StateName: 'Maharashtra',
                            Cities: ['Pune', 'Nagpur', 'Mumbai']
                        },
                        {
                            StateName: 'Kerala',
                            Cities: ['Kochi', 'Munnar']
                        },
                        {
                            StateName: 'Telangana',
                            Cities: ['Hyderabad', 'Nizamabad','Warangal']
                        },
                        {
                            StateName: 'Andhra Pradesh',
                            Cities: ['Vijayawada', 'Vizag','Rajamundry']
                        }
                    ]
                },
                {
                    CountryName: 'Australia',
                    States: [
                        {
                            StateName: 'Aukland',
                            Cities: ['GlenField', 'Henderson', 'MilFord']
                        },
                        {
                            StateName: 'Melbourne',
                            Cities: ['Melbourne', 'South Oakleigh']
                        }
                    ]
                }
            ],
             cloud: ['AWS', 'Microsoft Azure', 'Google Cloud', 'DigitalOcean', 'IBM', 'Rackspace', 'Oracle', 'Red Hat', 'Alibaba'],
             hostingTypeObj:[
                {
                    id: 1,
                    value: 'On-premise'
                },
                {
                    id: 2,
                    value: 'Data center'
                },
                {
                    id: 3,
                    value: 'Cloud'
                },
            ],
            managedByOptions:[],
            businessEntitiesList:[],
            yesNo: [
                {
                    id: 1,
                    value: 'Yes'
                },
                {
                    id: 2,
                    value: 'No'
                },
               
            ],
        };
    },
    components: {
        popup,
        dropdown,
        // Multiselect,
        newTable
    },
    validations(){
        return {
        system: {
                systemName: { required },
                ownedBy: { required },
                managedBy: { required },
                hostingType: { required },
                owner: { required },
                publicFacing: { required },
                description:{required},
                add: {
                    required: requiredIf(() => {
                        return this.system.publicFacing == '1';
                    })
                },
                city: { required,alphabet },
                state: { required,alphabet },
                country: { required,alphabet },
                hostingProvider: { required },
            }
        }
    },
    async mounted() {
        //this.$store.dispatch('updateDotsLoading', true);
        await this.getAllUsers();
        await this.getAllTenants();
        await this.getInformationSets()
        await this.getThirdParties();
        await this.getBusinessEntities();
        await this.getSystems();
        await this.getSystemsInformation();
    },
  
    computed: {
        disableStatus: function (): any {
            let disableStatusCondition;
            if (this.objToBeModified._id) {
                if (JSON.stringify(this.objToBeModified) == JSON.stringify(this.dummySystems)) {
                    disableStatusCondition = false;
                } else {
                    disableStatusCondition = true;
                }
            } else {
                let val: any = [];
                Object.values(this.objToBeModified).forEach((value: any) => {
                    val.push(value);
                });
                for (let num of val) {
                    if (num != '') {
                        disableStatusCondition = true;
                    }
                }
            }
            return disableStatusCondition;
        }
    },
    methods: {
        showPopUp() {
            this.v$.$reset();
            Object.keys(this.system).forEach((prop) => {
               Array.isArray(this.system[prop])? this.system[prop]=[]:this.system[prop] = '';
            });
            this.objToBeModified = this.system;
            this.popUp = true;
        },
        getOrgName(companyId:any){
            if(this.popUp == true){
                let details = this.tenants.find((obj:any)=>{return obj.id == companyId})
                if(details == undefined){
                    return companyId;
                }else{
                    return details.name;
                }
            }        
        },
        onCancelbtn(status: any) {
            if (status === 'close') {
                this.popUp = false;
                this.v$.$reset();           
            } else if (status) {
                this.system = {...this.dummySystems}
                this.objToBeModified = this.system;
            } else if (!undefined) this.showPopUp();
        },
        getCategories(options: any, status: any) {
            status = status.charAt(0).toLowerCase() + status.replace(/\s+/g, '').substring(1);
            this.filteredCategories(options, status);
        },
        filteredCategoriesAddress(options: any, status: any){
            if (status == 'country') {
                this.selectedCategory = this.countriesStatesCities
                    .map((country: any) => {
                        return country.CountryName;
                    })
                    .filter((category: any) => {
                        return category.toLowerCase().includes(options.toLowerCase());
                    });
                this.objToBeModified[status] = options;
            }
            if (status == 'state') {
                this.selectedCategory = this.countriesStatesCities
                    .filter((item: any) => item.CountryName == this.objToBeModified.country)
                    .map((state: any) =>
                        state.States.map((statename: any) => {
                            return statename.StateName;
                        })
                    )
                    .flat(1)
                    .filter((category: any) => {
                        return category.toLowerCase().includes(options.toLowerCase());
                    });
                this.objToBeModified[status] = options;
            }
            if (status == 'city') {
                this.selectedCategory = this.countriesStatesCities
                    .filter((item: any) => item.CountryName == this.objToBeModified.country)
                    .map((state: any) =>
                        state.States.map((statename: any) => {
                            if (statename.StateName == this.objToBeModified.state) {
                                return statename.Cities;
                            } else {
                                return [];
                            }
                        })
                    )
                    .flat(2)
                    .filter((category: any) => {
                        return category.toLowerCase().includes(options.toLowerCase());
                    });
                this.objToBeModified[status] = options;
            }
        },
        filteredCategories(options: any, status: any) {
            this.selectedCategory = [];
            this.autoSuggestion = true;
            if(status == 'country'|| status == 'state' || status == 'city'){
               this.filteredCategoriesAddress(options, status)
            }
            else{
                switch(status){
                    case 'managedBy':  
                        console.log("optionsoptions",options)
                        console.log("statusstatus",status)
                        break;
                    case 'provider':
                        if (this.system.hostingType == 'data-center' || this.system.hostingType == 'cloud') {
                        this.selectedCategory = this.cloud.filter((dept: any) => {
                            return dept.toLowerCase().includes(options.toLowerCase());
                        });
                    }
                        this.objToBeModified.hostingProvider = options;   
                        break;  
                }
            }      
        },
        async systemInformation(systemId: any) {
            this.$router.push({ name: 'edit-system', params: { id: systemId } });
        },
        async getInformationSets() {
            this.savedInformationSets = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall`).then((result: any) => {
                this.savedInformationSets = result.data.map((info: any) => {
                    return { value: info._id, label: info.information };
                });
            });
        },
        async addOrUpdate(obj: any, entityId: number) {
            this.v$.system.$touch();
            let comingObjId = obj._id;
            if (!this.v$.system.$invalid) {
                if (comingObjId) {
                    delete obj._id;
                    obj.updatedAt=new Date();
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/update/${comingObjId}/${entityId}`, obj).then((result: any) => {
                        toast.info('updated', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    });
                } else {
                    obj.createdAt=new Date();
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/create/${entityId}`, obj).then((result: any) => {
                       toast.info('created', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    });
                }
                this.popUp = false;
                this.systemData=[];
                this.getSystems();
                this.getSystemsInformation()
            } else {
                toast.error(`cannot save`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
           
        },
        async getSystems() {
             let entityId='20005'
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = result.data;
				this.dummySystems = result.data
            });
       
        },
        async getSystemsInformation() {
            this.systemData = []
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/allsystems/cia`).then((result: any) => {
                this.systemsInformations = result.data;
                this.dummyList=result.data;
                for (let j = 0; j < this.systems.length; j++) {
                    let findobj = this.systemsInformations.find((obj:any)=>{return obj.id == this.systems[j]._id});
                    this.systemData.push({ 'entityId':20009,'systemName': this.systems[j].systemName, 'description': this.systems[j].description, 'publicFacing':this.systems[j].publicFacing, 'hostingType':this.systems[j].hostingType ,'securityCategory': findobj.cia ? findobj.cia : '', _id: this.systems[j]._id });
                }
                // this.$store.dispatch('updateDotsLoading', false);
                for(let item of this.systemData){
                    if(item.cia == null){
                        item.cia = 0
                    }
                }
            });
           
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id:org._id, name: org.organizationName }
                    this.tenants.push(obj);
                    const object = { value:org._id, label: org.organizationName }
                    this.tenantsSelect.push(object);
                });
            });
        },
         async getAllUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
            
        },
        async getBusinessEntities(){
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result:any)=>{
                result.data.map((obj:any)=>{
                    let name = this.tenantsSelect.find((tenant:any)=>{return obj.entityName == tenant.value});
                    let final;
                    if(name == undefined){
                        final = obj.entityName
                    }else{
                        final = name.label
                    }
                    if((!this.managedByOptions.includes(final))){
                        this.managedByOptions.push(final)
                        this.businessEntitiesList.push(final)
                    }
                })
            })
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.systemData;
            } else {
                this.systemData = this.dummyList;
            }
            emitter.emit('visible',this.showFilters)
        },
        // getPermisssions(){
        //            this.permissions=this.userInfo.permissions[0].resources.filter((userPer:any)=>{
        //     return userPer.id==this.selectedIndex
        // })
        //  this.permissions= this.permissions[0].permissions
        //   console.log("kjeshdekjdssfe",this.permissions)
        // },
        async getThirdParties(){
            let thirdPartyId = 20003;
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${thirdPartyId}`).then((result: any) => {
                result.data.map((obj:any)=>{
                    if((!this.managedByOptions.includes(obj.name))){
                        this.managedByOptions.push(obj.name)
                    }
                });

            })
        }
    }
});
</script>



