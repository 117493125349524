<template>
    <div class="flexcontainer pt-3 pl-5 pr-5 bg-#f9f9fb">
        <div class="headerDiv">
            <div class="mb-2">
                <breadCrumb :routesArray="routesArray" :navIndexNums="navIndexNums"></breadCrumb>
            </div>
            <div class="row w-full flex justify-between">
                <h1 class="pt-1">Information Classification</h1>
                <div class="flex items-center" v-if="selectedTabIndex === 1">
                    <button class="btnprimary" @click="navigateMapScreen">Map</button>
                    <button class="ml-3 flex" :class="filteredInformations.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'" @click="filterOpen">
                        Filters
                        <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                    </button>
                </div>
            </div>
            <div class="mt-3 text-xs">
                <div>
                    Data organization is the practice of categorizing and classifying data to make it more usable. Similar to a filie folder, where we keep important documents, you'll need to arrange your data in the most logical and orderly fashion, so you and anyone else who accesses it can easily
                    find what they're looking for. Data organization is the practice of categorizing and classifying data to make mone usable. Similar to a file folder, where we keep important documents, you'll need to arrange your data in the most logical and orderly fashion.
                </div>
            </div>
        </div>
        <div class="mainContainer pb-5 w-full h-full bg-white">
            <div>
                <div class="mt-5 mx-5 flex flex-row borderBottom overflow-hidden cursor-pointer font-semibold tab-container">
            
                    <div class="pb-1 mt-3 adjust" v-for="(tab, tabIndex) in carouselHead" :key="tabIndex" @click="toggleTabs(tab.id)">
                        <!-- <div :class="{ 'text-blue': selectedTabIndex === tab.id, 'text-gray': selectedTabIndex !== tab.id }" ref="actualIndex" class="tab-name">{{ tab.displayName }}</div> -->
                        <div ref="actualIndex" class="tab-name">{{ tab.displayName }}</div>
                        <div :class="{ progress: selectedTabIndex == tab.id }" class="h-1 p-0.75"></div>
                    </div>
                </div>
                <div class="progressbarB rounded-sm ml-5 mr-5 relative h-1 mx-2.5"></div>
            </div>
            <div class="selectedTabs px-5">
                <div v-if="selectedTabIndex == 1" class="w-full h-full">
                    <div class="tableDataStyling">
                        <newTable :tableRowData="filteredInformations" :headersData="headersInfo" :openPopup="true"></newTable>
                    </div>
                </div>
                <div v-if="selectedTabIndex == 2">
                    <div class="inputBoxWithSaveIcon">

                        <div class="inputBoxInDiv">
                            <div class="flex justify-between mb-6">
                                <div class="colorLabelWidth">
                                    <p class="text-xs font-semibold mb-1">Label</p>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model="infoProperties.label"
                                        placeholder="Label for the Classification"
                                        :class="{
                                            'is-invalid': v$.infoProperties.label.$error
                                        }"
                                    />
                                    <div v-if="v$.infoProperties.label.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.infoProperties.label.required.$invalid">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="descriptionStyle">
                                <div>
                                    <p class="text-xs font-semibold mb-1">Description</p>
                                    <textarea
                                        class="w-full e9BorderStyling resize-none p-2 text-sm rounded"
                                        v-model="infoProperties.description"
                                        placeholder="Input field to describe about Classification of Information "
                                        :class="{
                                            'is-invalid': v$.infoProperties.description.$error
                                        }"
                                    ></textarea>
                                    <div v-if="v$.infoProperties.description.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.infoProperties.description.required.$invalid">Required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="flex items-center justify-center">
                                <div class="flex justify-center align-center">
                                    <button @click="resetData" class="mr-2" :class="JSON.stringify(infoPropertiesDummy) == JSON.stringify(infoProperties) ? 'btndisabled pointer-events-none' : 'btnprimary'">Reset</button>
                                    <button @click="updateClassification" class="ml-2" :class="JSON.stringify(infoPropertiesDummy) == JSON.stringify(infoProperties) ? 'btndisabled pointer-events-none' : 'btnprimary'">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter, toast } from '@/main';
import newTable from '@/components/newTable.vue';
import breadCrumb from '@/components/breadCrumb.vue';
import { useVuelidate } from '@vuelidate/core';
import { mapGetters } from 'vuex';
import store from '@/store';
import { required } from '@vuelidate/validators';

export default defineComponent({
    data(): any {
        return {
            infoTable: [],
            headersInfo: ['informationCategory', 'information', 'securityCategory'],
            showFilters: false,
            routesArray: [],
            navIndexNums: { name: '', index: '' },
            carouselHead: [
                { displayName: 'Information', name: 'Information', id: 1 },
                { displayName: 'Properties', name: 'Properties', id: 2 }
            ],
            selectedTabIndex: 1,
            selectedTabObj: [{ displayName: 'Information', name: 'Information', id: 1, content: 'content of Tab 1' }],
            classificationtype: [{ displayName: 'public', names: 'public', id: 1 }],
            tabHead: [
                { displayName: 'Information', name: 'Information', id: 1, content: 'content of Tab 1' },

                { displayName: 'Properties', name: 'Properties', id: 2, content: 'content of Tab 2' }
            ],
            buttonsEnabled: false,
            v$: useVuelidate(),
            infoProperties: {
                label: '',
                description: ''
            },
            infoPropertiesDummy: {},
            pageClassificationType: '',
            allInformationsJson: [],
            filteredInformations: [],
            propertiesObj: {}
        };
    },
    validations() {
        return {
            infoProperties: {
                label: { required },
                description: { required }
            }
        };
    },
    components: {
        breadCrumb,
        newTable
    },
    computed:{
        ...mapGetters({ dbInformationClassification:'dbInformationClassification'})
    },

    async created() {
        let findObj:any = this.dbInformationClassification.find((obj:any)=>{return obj.key == this.$route.params.classificationType});
        this.pageClassificationType = findObj.value;
        this.propertiesObj = {...findObj}
        this.infoProperties.label = this.propertiesObj.key;
        this.infoProperties.description = this.propertiesObj.description;

        await this.getInformationsOfClassification();

        this.routesArray = [
            { name: 'Information Classification', routeName: 'infoClassifications' },
            { name: this.$route.params.classificationType, routeName: 'classificationType', processId: `${this.$route.params.classificationType}` }
        ];
        this.navIndexNums = { index: 1, name: 'Information' };

        this.infoPropertiesDummy = { ...this.infoProperties };
    },

    methods: {
        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
        navigateMapScreen(){
            this.$router.push({ name: 'infoClassificationMap', routeName: 'infoClassifications'})
        },
        async getInformationsOfClassification() {
            this.allInformationsJson = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/informationclassfication/${this.pageClassificationType}/get`).then(async (result: any) => {
                this.allInformationsJson = result.data;
                this.filteredInformations = [...this.allInformationsJson]
            });
        },

        async toggleTabs(tabId: any) {
            this.selectedTabIndex = tabId;
            if (tabId == 1) {
                this.navIndexNums.index = 1;
                this.navIndexNums.name = 'Information';
            } else if (tabId == 2) {
                this.navIndexNums.index = 2;
                this.navIndexNums.name = 'Properties';
            }
        },
        async updateClassification() {
            this.v$.infoProperties.$touch();
            if (!this.v$.infoProperties.$invalid) {
                let payload:any = {
                    _id:this.propertiesObj._id,
                    key:this.infoProperties.label,
                    description:this.infoProperties.description,
                }
                try {
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/label/${20414}/update`, payload).then(async (result: any) => {
                        toast.info('Updated', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                        await store.dispatch('getLabelsInfo');
                        this.$router.push({ name: 'infoClassifications'});
                    });
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            }
        },
        resetData() {
            this.v$.$reset();
            console.log('hey');
            this.infoProperties.label = this.propertiesObj.key;
            this.infoProperties.description = this.propertiesObj.Description;
        }
    }
});
</script>
<style scoped>
.text-gray {
    color: rgba(77, 77, 77, var(--tw-text-opacity));
}
.progressbarB {
    border: 2px solid #e9e9e9;
    background: #e9e9e9;
    z-index: 10;
    top: -8px;
}
.adjust {
    margin-right: 10%;
}
.popup-business {
    overflow-y: scroll;
}
::-webkit-scrollbar {
    width: 0px;
}
.colorLabelWidth {
    width: 48%;
}
.e9BorderStyling {
    border: 1px solid #e9e9e9;
}
.inputBoxInDiv {
    margin-bottom: 3%;
}
.progress {
    z-index: 11;
}
</style>


