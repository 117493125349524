<template>
    <div class="pt-3 pl-5 pr-5">
        
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="row w-full justify-between flex">
            <div class="title flex items-center">
                <h1>Information Mapping</h1>
            </div>
        </div>
        <div class="mt-3 text-sm">
            <p>
                The Global Risks Report 2022, 17th Edition, is published by the World Economic Forum. The information in this report, or on which this report is based, has been obtained from sources that the authors believe to be reliable and accurate. However, it has not been independently verified
                and no representation or warranty, express or implied, is made as to the accuracy or completeness of any information obtained from third parties.
            </p>
        </div>
        <div class="flex flex-col mt-4 bg-white rounded containerDiv">
            <div class="oneSideBorder">
                <div class="justify-between flex px-5 h-8 m-2">
                    <div class="flex my-3">
                        <h2 class="header cursor-pointer">Information Mapping</h2>
                    </div>
                    <div class="flex justify-end">
                        <button :class="checkedInformationIds.length>0 ? 'btnprimary' :'btndisabled pointer-events-none'" @click="addSelectedInformation()">Add</button>
                        <button class="filterButton ml-3 flex" @click="clickOnFilters()">Filters<img src="@/assets/filter.svg" class="h-3 ml-2.5" /></button>
                    </div>
                </div>
                <div class="rounded flex text-xs pt-1 p-5 m-2">
                    <div class="w-2/5">
                        <div class="mt-3 border border-lightgrey option mr-1 displayContainer overflow-y-scroll rounded">
                            <div v-if="showFilters" class="p-2">
                                <input type="text" class="inputboxstyling" v-model="categoryFilters.informationCategory" placeholder="Search Category" />
                            </div>
                            <div v-for="(category, index) in categoryList" :key="index" class="rounded">
                                <div class="p-3 pl-5 cursor-pointer" @click="selectedCategory = category" :class="selectedCategory == category ? 'bg-primary hover:bg-primary text-white' : 'hover:bg-lightgrey'">
                                    <p class="text-xs">{{ category }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-3/5">
                        <div class="w-full pt-0">
                            <div class="mt-3 displayContainer border flex-col text-sm border-lightgrey overflow-y-scroll ml-1 rounded">
                                <div v-if="showFilters" class="p-2">
                                    <input type="text" class="inputboxstyling" v-model="infoFilters.information" placeholder="Search Information" />
                                </div>
                                <div class="h-7 flex justify-normal items-center cursor-pointer p-5 hover:bg-lightgrey" v-for="(infoObj, index) in categoryWiseInfoList" :key="infoObj._id">
                                    <div class="flex items-center whitespace-nowrap">
                                        <input v-model="checkedInformationIds" :value="infoObj._id" :id="index" type="checkbox" class="checkbox" @change="handleCheckboxChange(infoObj._id)" />
                                        <label :for="index" class="pl-3 cursor-pointer">{{ infoObj.information }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selectedInformation.length > 0" class="p-5 m-2">
                <!-- <newTable :tableRowData="selectedInformation" :headersData="headers"></newTable> -->
               <div class="tableCornerRadius bg-white">
                <table>
                    <thead>
                    <th scope="col" v-for="presentColumn in headers" :key="presentColumn" :class="getHeaderwidth(presentColumn)">
                        <div>{{ getColumnLabel(presentColumn) }}</div>
                    </th>
                    </thead>
                    <tbody v-if="selectedInformation.length == 0">
                        <tr class="cursor-pointer empty rowHeight">
                            <td class="relative h-10" :colspan="columnNames.size">
                                <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                            </td>
                        </tr>
                </tbody>
                <tbody class="nonEmptyTable" v-else>
                    <tr v-for="row,index in selectedInformation" :key="index" class="cursor-pointer rowHeight">
                    <td v-for="presentColumn in headers" :key="presentColumn"  class="cursor-pointer relative">
                        <div class="rounded h-5 w-26" v-if="row[presentColumn] == ''">{{ '-' }}</div>
                        <div v-else-if="presentColumn=='informationCategory'">
                                    <div class="flex rounded w-32 items-center justify-center py-1 regular text-xs">
                                        <div class="text-xs">
                                            {{ getInfocategorydetails(row) }}
                                        </div>
                                    </div>
                        </div>
                         <div v-else-if="presentColumn=='information'">
                                    <div class="w-full h-full absolute opacity-10 rounded"></div>
                                    <div class="flex rounded w-32  py-1 regular text-xs">
                                        <div class="text-xs">
                                            {{ getInformationdetails(row) }}
                                        </div>
                                    </div>
                        </div>

                        <div v-else-if="presentColumn=='confidentiality'">
                                <div :class="colorRiskBg(row[presentColumn])" class="flex items-center justify-center relative rounded w-28 px-2.5 h-5 regular">
                                    <div class="w-full h-full absolute opacity-10 rounded" ></div>
                                    <div class="flex rounded w-32 items-center justify-center py-1 regular text-xs" :class="colorRiskText(row[presentColumn])">
                                        
                                        {{
                                            dbConfidentiality.find((l) => {
                                                return l.value == row[presentColumn];
                                            }).key
                                        }}
                                    </div>
                                </div>
                        </div>

                         <div v-else-if="presentColumn=='integrity'">
                                <div :class="colorRiskBg(row[presentColumn])" class="flex items-center justify-center relative rounded w-28 px-2.5 h-5 regular">
                                    <div class="w-full h-full absolute opacity-10 rounded"></div>
                                    <div class="flex rounded w-32 items-center justify-center py-1 regular text-xs" :class="colorRiskText(row[presentColumn])">
                                        {{
                                            dbIntegrity.find((l) => {
                                                return l.value == row[presentColumn];
                                            }).key
                                        }}
                                    </div>
                                </div>
                        </div>

                         <div v-else-if="presentColumn=='availability'">
                                <div :class="colorRiskBg(row[presentColumn])" class="flex items-center justify-center relative rounded w-28 px-2.5 h-5 regular">
                                    <div class="w-full h-full absolute opacity-10 rounded"></div>
                                    <div class="flex rounded w-32 items-center justify-center py-1 regular text-xs" :class="colorRiskText(row[presentColumn])">
                                        {{
                                            dbAvailability.find((l) => {
                                                return l.value == row[presentColumn];
                                            }).key
                                        }}
                                    </div>
                                </div>
                        </div>
                        <div v-else-if="presentColumn == 'deleteinfo'">
                            <div class="flex justify-center h-5 w-5" @click="deleteInfoRow(row)">
                                <img src="@/assets/deleteIcon.svg" class="h-5 cursor-pointer
                                 w-5 mx-2.5" />
                            </div>
                        </div>
                        
                        </td>
                    </tr>
                </tbody>
                </table>
               </div>
            </div>     
        </div>
    </div>
    <div class="fixed bottom-7 bg-white h-16 flex justify-end items-center" :class="{ 'w-40': isSidebarToggle, 'w-10': !isSidebarToggle }" v-if="selectedInformation.length > 0">
        <div class="buttonposition flex justify-end items-center px-5 mx-5">
            <button class="btnprimary mr-1.5" :class="JSON.stringify(tableIds) == JSON.stringify(existingInfoIds) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="onCancel()">Cancel</button>
            <button class="btnprimary" :class="JSON.stringify(tableIds) == JSON.stringify(existingInfoIds) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="saveMappedInformation()">Save</button>
        </div>
    </div>
</template>
<style scoped>
.displayContainer {
    height: 276px !important;
}
.oneSideBorder {
    border-bottom-style: 1px solid #e9e9e9;
}
.CancelButton {
    border: 1px solid black;
}
.containerDiv {
    padding-bottom: 2px;
}
::-webkit-scrollbar {
    width: 2px;
}

.w-40 {
    width: calc(100% - 100px);
}
.w-10 {
    width: calc(100% - 11%);
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import newTable from '@/components/newTable.vue';
import { emitter, toast } from '@/main';
import breadCrumb from '@/components/breadCrumb.vue';
import { isDate } from 'lodash';
import { concat } from 'lodash';
import { mapGetters } from 'vuex';

export default defineComponent({
    data(): any {
        return {
            headers: ['informationCategory', 'information', 'confidentiality', 'integrity', 'availability','deleteinfo'],
            informationJson: [],
            dummyInformationJson: [],
            informationObject: {},
            selectedCategory: '',
            checkedInformationIds: [],
            showFilters: false,
            categoryFilters: {
                informationCategory: ''
            },
            infoFilters: {
                information: ''
            },
            tableArray: [],
            selectedInformation: [],
            objectVariable: [],
            tableArrayOfObj:[],
            tableIds:[],
            existingInfoIds:[],
            existingInfoArray:[],
            routesArray:[],
            allRootInformationIds:[],
            columns: [
                {key:'information',value:'Information'},
                { key: 'informationCategory', value: 'Category' },
                {key:'confidentiality',value:'Confidentiality'},
                {key:'availability',value:'Availability'},
                {key:'integrity',value:'Integrity'},
            ],
        };
    },
    components: {
        newTable,
        breadCrumb
    },
    computed: {
        ...mapGetters({ userInfo: 'userInfo' ,
        dbConfidentiality: 'dbConfidentiality',
        dbIntegrity: 'dbIntegrity',
        dbAvailability: 'dbAvailability',
},

        ),
        categoryList: function (): any {
            return Object.keys(this.informationObject).filter((name: any) => {
                return name.toLowerCase().includes(this.categoryFilters.informationCategory);
            });
        },
        categoryWiseInfoList: function (): any {
            return this.informationJson.filter((obj: any) => {
                return obj.informationCategory == this.selectedCategory && obj.information.toLowerCase().includes(this.infoFilters.information) && (!(this.tableIds.includes(obj._id)));
            });
        },
    },
    async mounted() {
        emitter.off('deleteRow')
        emitter.on('deleteRow',(data:any)=>{
            if(Object.keys(data).includes('mappedInformationId')){
                this.onDelete(data.mappedInformationId)
            }else{
                this.onDelete(data._id)
            }         
        })
    },
    async created(){
        await this.getRootInformations();
        await this.getAllExistingInformations();

        this.selectedCategory = this.categoryList[0];
        this.routesArray = [{name:'Information',routeName:'orginformation'},{name:'Information-Mapping',routeName:'informationMapping'}]
    },
    methods: {
        groupBy(arr: any, keyName: any) {
            return arr.reduce((group: any, product: any) => {
                const keyValue = product[keyName];
                group[keyValue] = group[keyValue] ?? [];
                group[keyValue].push(product);
                return group;
            }, {});
        },
        getInfocategorydetails(row:any) {
            console.log("row dataa",row);
            return row.informationCategory;
        },
        getInformationdetails(row:any) {
            return row.information;
        },
        // getconfidentialitydetails(row:any){
        //     return row.confidentiality;
        // },
        // getintegritydetails(row:any){
        //     return row.integrity;
        // },
        // getavailabilitydetails(row:any){
        //     return row.availability;
        // },
        deleteInfoRow(data:any){
            if(Object.keys(data).includes('mappedInformationId')){
                this.onDelete(data.mappedInformationId)
            }else{
                this.onDelete(data._id)
            } 
        },
        getColumnLabel(columnName: any) {
            let labelKeys:any = ['confLabel','intLabel','avaiLabel','scLabel','likeLabel','opsLabel','orgLabel','businessLabel','techLabel','finLabel','legalLabel','sevLabel','prioLabel','infoClassLabel','proCLabel']
            let ValueKeys:any = ['confValue','intValue','avaiValue','scValue','likeValue','opsValue','orgValue','businessValue','techValue','finValue','legalValue','severitypreview','prioritypreview','infoClassValue','proCValue']

            if (columnName == 'actionButton' || columnName == 'deleteinfo' || columnName == 'infoAction' || columnName == 'deleteIcon' || columnName == 'reportDelete' || columnName == 'reportAction' || columnName == 'tableDataActions' || columnName == 'History') {
                return '';
            }else if(labelKeys.includes(columnName)){
                return 'Label';
            }else if(ValueKeys.includes(columnName)){
                return 'Value';
            }
            
            const column = this.columns.find((col: { key: any }) => col.key === columnName);
            return column ? column.value : columnName;
        },
        getHeaderwidth(column:any){
            if(column=='integrity'){
                console.log("rrrr");
                
            }            

        },

        colorRiskBg(value: any) {
            if (value == 1) {
                return ['bg-veryLowColor border-solid border border-lightgreen'];
            } else if (value == 2) {
                return ['bg-lowColor border-solid border border-green'];
            } else if (value == 3) {
                return ['bg-moderateColor border-solid border border-yellow'];
            } else if (value == 4) {
                return ['bg-highColor border-solid border border-error'];
            } else if (value == 5) {
                return ['bg-veryHighColor border-solid border border-darkred'];
            }
        },
        colorRiskText(value: any) {
            if (value == 1) {
                return ['text-lightgreen font-semibold'];
            } else if (value == 2) {
                return ['text-green font-semibold'];
            } else if (value == 3) {
                return ['text-yellow font-semibold'];
            } else if (value == 4) {
                return ['text-error font-semibold'];
            } else if (value == 5) {
                return ['text-darkred font-semibold'];
            }
        },
        clickOnFilters() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == false) {
                this.infoFilters.information = '';
                this.categoryFilters.informationCategory = '';
            }
        },
        addSelectedInformation() {
            this.checkedInformationIds.map((id: any) => {
                this.tableIds.push(id);
            });
            let selectedData = this.informationJson.filter((item: any) => {
                return this.checkedInformationIds.includes(item._id);
            });

            selectedData.map((item: any) => {
                this.selectedInformation.push(item);
            });

            this.informationJson = this.informationJson.filter((item: any) => {
                return !this.checkedInformationIds.includes(item._id);
            });

            console.log(this.selectedInformation);
            this.checkedInformationIds = []
        },
        handleCheckboxChange(selectedId: string) {
            this.objectVariable = selectedId
        },
        onDelete(incomingId:any){
            if(!(this.existingInfoIds.includes(incomingId))){
                this.selectedInformation = this.selectedInformation.filter((obj:any)=>{
                    return obj._id != incomingId 
                })
                this.tableIds = this.tableIds.filter((id:any)=>{
                    return id != incomingId 
                })

                let insertingObject:any = this.dummyInformationJson.find((obj:any)=>{
                    return obj._id == incomingId
                })

                this.informationJson.push(insertingObject)
            }else{
                toast.error(`Already Mapped`, {
                    closeOnClick: true,
                    closeButton: 'button'
                });
            }
        },
        async getRootInformations(){
            this.informationJson = [];
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall`).then((result: any) => {
                this.informationJson = result.data;
                this.dummyInformationJson = [...this.informationJson];
                this.dummyInformationJson.map((obj:any)=>{this.allRootInformationIds.push(obj._id)})
                this.informationObject = this.groupBy(this.informationJson, 'informationCategory');
            })
        },
        async getAllExistingInformations(){
            this.existingInfoIds= [];
            this.existingInfoArray = [];
            
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/getall?org=true`).then(async (result: any) => {
                let res = result.data;
              
                res.map((item:any)=>{
                    if(this.allRootInformationIds.includes(item.mappedInformationId)){
                        this.existingInfoIds.push(item.mappedInformationId);
                        this.existingInfoArray.push(item)
                        this.tableIds.push(item.mappedInformationId);
                        this.selectedInformation.push(item)
                    }  
                })
            })
        },  
        onCancel(){
            let filterOverallInfosFromExistingInfos = this.dummyInformationJson.filter((obj:any)=>{return !(this.existingInfoIds.includes(obj._id))})
            this.informationJson = filterOverallInfosFromExistingInfos;
            this.tableIds = [...this.existingInfoIds];
            this.selectedInformation = [...this.existingInfoArray]; 
        },
        async saveMappedInformation(){
            const headers = {'resourceId': 20007,};
            
            let filterInfos:any = this.selectedInformation.filter((item:any)=>{return !(Object.keys(item).includes('mappedInformationId'))})
            filterInfos.map((obj:any)=>{
                if(Object.keys(obj).includes('showData')){
                    delete obj.showData
                }
                obj.createdAt = new Date();
                obj.mappedInformationId = obj._id;
                delete obj._id;
                obj.informationOwner = this.userInfo._id;
            })

            await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/information/create?org==true`,filterInfos, {headers}).then((result: any) => {
                toast.info(`Saved successfully`, {
                    closeOnClick: true,
                    closeButton: 'button'
                });
                this.$router.push({name:'orginformation'})
            })
        },
       
    }
});
</script>