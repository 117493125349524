<template>
    <div class="flexcontainer pt-3 pl-5 pr-5">
        <div class="row w-full flex justify-between">
            <h1>Vulnerabilities</h1>
            <div class="flex">
                <button class="float-right btnprimary" @click="importDoc()">Import</button>
                <button class="float-right btnprimary ml-3" @click="showPopupVulnerability()">Add</button>
                <input type="file" class="hidden" accept=".xls,.xlsx" ref="file" @change="fileReader($event)" />
                <button class="ml-3 float-right flex items-center" :class="vulnerabilities.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'" style="height: 33px; width: 84px" @click="filterOpen">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-3 text-xs">
            <div>
            Vulnerability is a weak point, flaw, error, or gap in the security environment of an asset. The weakness is exploited by the possible threat to force its way into getting unauthorized access to the system.Threats will exist but if there are no vulnerabilities, there are limited or zero
            chances of risk. Hence, knowing about common vulnerabilities and regular tracking of those is critical to identifying potential risks to assets.
            </div>
        </div>
        <div class="row">
            <div>
                <newTable :tableRowData="vulnerabilities" :headersData="headers" :openPopup="handlePopUp"></newTable>
            </div>
        </div>
        <popup v-if="showPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Vulnerability</h1>
                    <button class="float-right text-3xl" @click="cancelBtn('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <div class="popup-business popupbodycontainer">
                <div class="row flex">
                    <div class="upperspacing w-full">
                        <label class="controllabel font-normal text-sm mb-1" for="vulnerability">ID</label> 
                        <input
                            v-model="newVulnerability.vulnerabilityId"
                            placeholder="ID"
                            id="id"
                            class="inputboxstyling"
                            :class="{
                                'is-invalid': v$.newVulnerability.vulnerabilityId.$error
                            }"
                        />
                        <div v-if="v$.newVulnerability.vulnerabilityId.$error" class="text-red-500">
                            <div class="error-text" v-if="v$.newVulnerability.vulnerabilityId.required.$invalid">ID is required</div>
                        </div>
                    </div>
                </div>

                <div class="row flex">
                    <div class="upperspacing w-full">
                        <label class="controllabel font-normal text-sm mb-1" for="vulnerability">Category</label> 
                        <input
                            v-model="newVulnerability.category"
                            placeholder="Category"
                            id="category"
                            class="inputboxstyling"
                            :class="{
                                'is-invalid': v$.newVulnerability.category.$error
                            }"
                        />
                        <div v-if="v$.newVulnerability.category.$error" class="text-red-500">
                            <div class="error-text" v-if="v$.newVulnerability.category.required.$invalid">Category is required</div>
                        </div>
                    </div>
                </div>

                <div class="row flex">
                    <div class="upperspacing w-full">
                        <label class="controllabel font-normal text-sm mb-1" for="vulnerability">Vulnerability</label> 
                        <textarea
                            v-model="newVulnerability.vulnerability"
                            placeholder="Vulnerability"
                            id="vulnerability"
                            class="inputboxstyling"
                            :class="{
                                'is-invalid': v$.newVulnerability.vulnerability.$error
                            }"
                        />
                        <div v-if="v$.newVulnerability.vulnerability.$error" class="text-red-500">
                            <div class="error-text" v-if="v$.newVulnerability.vulnerability.required.$invalid">Vulnerability is required</div>
                        </div>
                    </div>
                </div>

                <div class="flex justify-center items-center mt-2">
                    <button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelBtn(newVulnerability._id)">Cancel</button>
                    <button @click="addOrUpdatevulnerability()" v-if="!newVulnerability._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
                    <button class="btnprimary mr-1.5" v-else @click="addOrUpdatevulnerability()" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'">Update</button>
                </div>
            </div>
        </popup>
    </div>
</template>
<style scoped>
.popup-business {
    overflow-y: scroll;
}
::-webkit-scrollbar {
    width: 0px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, helpers, email, maxLength } from '@vuelidate/validators';
import popup from '@/components/popup.vue';
import { emitter, toast } from '@/main';
import newTable from '@/components/newTable.vue';
export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            mandatoryKeys: ['vulnerability'],
            fullKeys: ['vulnerability'],
            pop: false,
            vulnerabilities: [],
            dummyList: [],
            showFilters: false,
            showPopup: false,
            b: false,
            result: false,
            tableIndex: '',
            selected: '',
            searchList: [],
            notDisable: false,
            classSort: '',
            className: '',
            riskList: [],
            dupList: [],
            threatList: [],
            objCopy: {},
            disabled: 1,
            headers: ['vulnerabilityId', 'category', 'vulnerability', '_id'],
            currentSort: 'name',
            currentSortDir: 'asc',
            newVulnerability: {
                vulnerabilityId: '',
                category: '',
                vulnerability: ''
            },
            handlePopUp: true,
            showPriorityPopUp: false
        };
    },
    validations: {
        newVulnerability: {
            vulnerabilityId: { required },
            category: { required },
            vulnerability: { required }
        }
    },
    components: {
        popup,
        newTable
    },
    async mounted() {
        //this.$store.dispatch('updateDotsLoading', true);
        this.handleActionHeaders();
        emitter.off('showPopupData');
        emitter.off('showData123');
        emitter.on('showData123', (isData: any) => {
            this.showEditPopUp(isData.entityData);
        });
        emitter.off('deleteData');
        emitter.on('deleteData', (isData: any) => {
            this.showDeletePopUp(isData.entityData);
        });
        emitter.on('showPopupData', (isData: any) => {
            this.showEditPopUp(isData.entityData);
        });
        if (this.$route.path.includes('root/vulnerabilities')) {
            await this.getRootVulnerabilitiesList();
        } else if (this.$route.path.includes('org/vulnerabilities')) {
            await this.getOrgVulnerabilitiesList();
        }
    },
    computed: {
        disableStatus: function (): any {
            let disableStatusCondition;
            if (this.b) {
                let objCopy = Object.assign({}, this.newVulnerability);
                if (JSON.stringify(objCopy) == JSON.stringify(this.obj)) {
                    disableStatusCondition = false;
                } else {
                    disableStatusCondition = true;
                }
            } else {
                let val: any = [];
                Object.values(this.newVulnerability).forEach((value: any) => {
                    val.push(value);
                });
                for (let n of val) {
                    if (n != '') {
                        disableStatusCondition = true;
                    }
                }
            }
            return disableStatusCondition;
        },
        e: function (): any {
            return false;
        }
    },
    methods: {
        showEditPopUp(vulnerability: any, index: any) {
            this.showPopup = true;
            this.obj = vulnerability;
            console.log(vulnerability);
            this.b = vulnerability;
            this.newVulnerability = { ...vulnerability };
            this.objCopy = this.newVulnerability;
        },
        async addOrUpdatevulnerability() {
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.vulnerabilities.push(this.newVulnerability);
                if (this.newVulnerability._id) {
                    await this.updateVulnerabilitiesList();
                } else {
                    await this.addVulnerabilitiesList();
                }
                this.vulnerabilities = [];
                if (this.$route.path.includes('root/vulnerabilities')) {
                    await this.getRootVulnerabilitiesList();
                } else if (this.$route.path.includes('org/vulnerabilities')) {
                    await this.getOrgVulnerabilitiesList();
                }
                this.showPopup = false;
                this.v$.$reset();
            } else {
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async showDeletePopUp(data: any) {
            let payloadvunerabilitesId = data._id;
            await this.$http.delete(`${process.env.VUE_APP_RISK_API_URL}/org/vunerabilites/${payloadvunerabilitesId}/delete`).then((result: any) => {
                toast.info(`Deleted succesfully`, {
                    closeOnClick: true,
                    closeButton: 'button'
                });
            });
            await this.getOrgVulnerabilitiesList();
        },
        async addVulnerabilitiesList() {
            try {
                if (this.$route.path.includes('root')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/create`, [this.newVulnerability]).then((result: any) => {
                        toast.info(`Created succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                } else if (this.$route.path.includes('org')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/org/vunerabilites/create`, [this.newVulnerability]).then((result: any) => {
                        toast.info(`Created succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                }
            } catch (e) {
                console.log('add else');
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async updateVulnerabilitiesList() {
            try {
                let threatId = this.newVulnerability._id;
                delete this.newVulnerability._id;
                console.log('before sending data:', this.newVulnerability);
                if (this.$route.path.includes('root')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/api/root/risk/${threatId}/update`, this.newVulnerability).then((result: any) => {
                        toast.info(`Updated succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                } else if (this.$route.path.includes('org')) {
                    await this.$http.post(`${process.env.VUE_APP_RISK_API_URL}/org/vunerabilites/${threatId}/update`, this.newVulnerability).then((result: any) => {
                        toast.info(`Updated succesfully`, {
                            closeOnClick: true,
                            closeButton: 'button'
                        });
                    });
                }
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        filteredFunction(filterColumn: any, columnName: any) {
            this.vulnerabilities = this.dummyList.filter((itemOfArray: any) => {
                for (var property in itemOfArray) {
                    if (property == columnName) {
                        return itemOfArray[property].toLowerCase().includes(filterColumn.toLowerCase());
                    }
                }
            });
        },
        cancelBtn(status: any) {
            if (status === 'close') this.showPopup = false;
            else if (status) {
                this.newVulnerability = { ...this.obj };
                this.objCopy = this.newVulnerability;
            } else if (!undefined) {
                this.showPopupVulnerability();
            }
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.vulnerabilities;
            } else {
                this.vulnerabilities = this.dummyList;
            }
            emitter.emit('visible', this.showFilters);
        },
        showPopupVulnerability() {
            (this.b = false), (this.showPopup = true);
            this.v$.$reset();
            Object.keys(this.newVulnerability).forEach((prop: any) => {
                this.newVulnerability[prop] = '';
            });
            this.objCopy = this.newVulnerability;
        },
        fileReader(oEvent: any) {
            var oFile = oEvent.target.files[0];
            var reader = new FileReader();
            reader.onload = (e: any) => {
                var data = e.target.result;
                data = new Uint8Array(data);
                var workbook = XLSX.read(data, {
                    type: 'array'
                });
                var first_sheet_name = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[first_sheet_name];
                var jsonObj = XLSX.utils.sheet_to_json(worksheet, {
                    raw: true,
                    header: this.fullKeys,
                    range: 1
                });
                let total = jsonObj.length;
                jsonObj.forEach((element: any) => {
                    element.showData = false;
                    this.VulnerabilityList.push(element);
                });
            };
            reader.readAsArrayBuffer(oFile);
        },
        importDoc() {
            this.$refs.file.click();
        },
        sort: function (s: any) {
            this.notDisable = true;
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        async getRootVulnerabilitiesList() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/root/risks/getall`).then((result: any) => {
                this.vulnerabilities = [];
                result.data.forEach((threat: any) => {
                    if (threat.vulnerability) {
                        this.vulnerabilities.push({ vulnerabilityId: threat.vulnerabilityId, category: threat.category, vulnerability: threat.vulnerability, _id: threat._id });
                        this.dupList.push({ vulnerabilityId: threat.vulnerabilityId, category: threat.category, vulnerability: threat.vulnerability, _id: threat._id });
                    }
                });
                // this.$store.dispatch('updateDotsLoading', false);
                this.dummyList = this.vulnerabilities;
            });
        },
        async getOrgVulnerabilitiesList() {
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/org/vunerabilites/getall`).then((result: any) => {
                this.vulnerabilities = [];
                result.data.forEach((threat: any) => {
                    if (threat.vulnerability) {
                        this.vulnerabilities.push({ vulnerabilityId: threat.vulnerabilityId, category: threat.category, vulnerability: threat.vulnerability, _id: threat._id });
                        this.dupList.push({ vulnerabilityId: threat.vulnerabilityId, category: threat.category, vulnerability: threat.vulnerability, _id: threat._id });
                    }
                });
                // this.$store.dispatch('updateDotsLoading', false);
                this.dummyList = this.vulnerabilities;
            });
        },
        handleActionHeaders() {
            if (this.$route.name == 'orgvulnerabilities') {
                this.headers.push('tableDataActions');
                this.handlePopUp = false;
                console.log('check routes .......', this.headers);
            } else {
                if (this.headers['tableDataActions']) {
                    this.headers.pop('tableDataActions');
                    this.handlePopUp = true;
                }
            }
        }
    }
});
</script>
